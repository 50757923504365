import React, { useEffect, useState, useMemo } from "react";
import '../../../_metronic/_assets/css/Dashboard.scss';
import { VerticalTimeline, VerticalTimelineElement}  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import BulletIcon from '../Component/Marchent/BulletIcon';
import Completed from '../Component/Marchent/Completed';
import { FormattedMessage, injectIntl } from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import {connect,useSelector,useDispatch} from 'react-redux';
import * as dredux from "./_redux/dashboardRedux";
import {API_URL} from "../../Constants";

import { TimeStamp } from "../../utility/TimeStamp";
import openSocket from 'socket.io-client';
import clsx from 'clsx';
import {Modal} from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import BlockUI from '../Component/BlockUI';


/*******  Chart  *******/
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {useHtmlClassService} from "../../../_metronic/layout";

export function DashEngages(props) {

  //console.log(props)

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.primary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("dashengage");

    if (!element) {
      return;
    }

    const options = getChartOption1(layoutProps,props);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps,props]);

  return (
        <div
          id="dashengage"
          className="card-rounded-bottom"
          style={{ height: "120px" }}
        ></div>
    );
}

function getChartOption1(layoutProps,props) {

  const options = {
    series: [
      {
        name: "Engagements: ",
        data: props.values
      }
    ],
    chart: {
      type: "area",
      height: 120,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess]
    },
    xaxis: {
      categories: props.cat,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return "" + val + " ";
        }
      }
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      colors: [layoutProps.colorsThemeLightSuccess],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3
    }
  };
  return options;
}

export function DashSubscribe(props) {
  //console.log(props)
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("dashsubscribe");

    if (!element) {
      return;
    }

    const options = getChartOption2(layoutProps,props);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps,props]);

  return (
        <div
          id="dashsubscribe"
          className="card-rounded-bottom"
          style={{ height: "120px" }}
        ></div>
    );
}

function getChartOption2(layoutProps,props) {
  const options = {
    series: [
      {
        name: "New Subscribers: ",
        data: props.values
      }
    ],
    chart: {
      type: "area",
      height: 120,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess]
    },
    xaxis: {
      categories: props.cat,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return "" + val + " ";
        }
      }
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      colors: [layoutProps.colorsThemeLightSuccess],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3
    }
  };
  return options;
}

export function DashOffers(props) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.info"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.info"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("dashmension");

    if (!element) {
      return;
    }

    const options = getChartOption3(layoutProps,props);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps,props]);

  return (
        <div
          id="dashmension"
          className="card-rounded-bottom"
          style={{ height: "120px" }}
        ></div>
    );
}

function getChartOption3(layoutProps,props) {
  const options = {
    series: [
      {
        name: "Offers Availed: ",
        data: props.values
      }
    ],
    chart: {
      type: "area",
      height: 120,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess]
    },
    xaxis: {
      categories: props.cat,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return "" + val + " ";
        }
      }
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      colors: [layoutProps.colorsThemeLightSuccess],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3
    }
  };
  return options;
}

/******* / End Chart  *******/

const options = [
  'Ban Subscriber'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function DashboardPage(props) {

  const history = useHistory();
  const {user} = useSelector(state => state.auth);
  const user_email = user.email;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  function handleClick(event) {
      setAnchorEl(event.currentTarget);
  }
  function handleClose(event) {
      setAnchorEl(null);
      console.log(event.target.dataset.value)
  }


  //document.body.classList.add('body-dashboard');
  const classes =useStyles();
  const [values, setValues] = React.useState({
    dashBranch: '',
    dashBusiness: '',
    dashTime:'',
    community: "",
    business:"",
    detail:"",
    profile_avatar:""
  });

  const [state, setState] = useState({
    time: "Oct 8",
    business: "Khaadi",
    branch:"All Branches",
    modelShow: false,
    userImg: 'wasif.jpg',
    userName: 'Wasif Ahmed',
    userLocation: 'Gullberg III, Lahore',
    community: "",
    business:"",
    cm_id: 1,
    disabled: "disabled",
    selectedFile:"",
    loader:false,
    communityText: "Community",
    spinner: "",
    communityIcon: "fa-globe-americas",
    bslide: "",
    modelTitle:"Create post"
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  function timeChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
    console.log('Time Change: '+event.target.value);
  }
  function businessChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
    console.log('Business Change: '+event.target.value);
  }
  function branchChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
    console.log('Branch Change: '+event.target.value);
  }

  function modelClose(event) {
      setState({ ...state, modelShow: false });
  }
  const sendMail =()=> {
		toast.success("Email send successfully!", {position: "top-right",autoClose: 3000});
    const timeout = setTimeout(() => {
      setState({ ...state, modelShow: false });
    }, 3000);
  }

  var moment = require('moment');

  const globalState  = useSelector(state=>state.dashboard);
  const [allposts, setdata] = useState([]);
  const [allsubs, setSubs] = useState([]);
  const [allengages, setEngages] = useState([]);
  const [alleGraphs, setEGraphs] = useState([]);
  const [allbusinesses, setBusiness] = useState([]);

	useEffect( ()=>{
    //console.log(allbusinesses)
    setdata(globalState.posts)
    setSubs(globalState.subscribers)
    setEngages(globalState.engages)
    setEGraphs(globalState.egraph)
    setBusiness(globalState.all_businesses)
	});
	useEffect( ()=>{
    props.getBusinesses(user.id)
    props.getEngageGraph(user.id)
    props.getPosts(user.id);
    props.getSubscriptions(user.id);
    props.getEngagements(user.id)
  } , []);

  function socketConnection(client){
  
      client.on('newSubscriber', (data) => {
        if(user.id == data.merchant_id) { 
            props.updateSubscribers(data.data)
            props.updatePostsCount("1")
        }  
         
      });
      client.on('newPost', (data) => { 
         if(user.id == data.data.merchant_id) {
            props.updatePosts(data.data)
            props.updatePostsCount("2")
         }
      });
  }
  

  useEffect(() => {
      const client = openSocket(API_URL)
      socketConnection(client)
      return () => {
        // removing the listener when props.x changes

        client.disconnect()
    }
   }, []);
  
  const exngageVlaues = alleGraphs.posts ? alleGraphs.posts : [0];
  const exchangeCat = alleGraphs.posts_times ? alleGraphs.posts_times : ["No Data"];
  const subscribeVlaues = alleGraphs.subscriptions ? alleGraphs.subscriptions : [0];
  const subscribeCat = alleGraphs.subscriptions_time ? alleGraphs.subscriptions_time : ["No Data"];
  const offersVlaues = alleGraphs.offers ? alleGraphs.offers : [];
  const offersCat = alleGraphs.offers_time ? alleGraphs.offers_time : [] ;

  const viewSubscribers =()=> {
    history.push('/subscribers');
  }

  const addPost =()=> {
    setShow(true)
  }

  const [show, setShow] = React.useState(false);
  const popupClose = () => setShow(false);
  const [postBusiness, setPostBusiness] = React.useState("hide");

  function handleChange(event) {
    const handleChange = name => event => {
	    setState({ ...state, [name]: event.target.value, });
      setValues({ ...values, [name]: event.target.value });
    };
  }

  const showBusDD = (e) => {
    var cc_value = e.target.value;
    if(cc_value == 2) {
      setPostBusiness("show")
    } else {
      setPostBusiness("hide")
    }
    setValues({...values, community: cc_value})
    setState({...state, community: cc_value})
    console.log(cc_value)
  }

  const submitPost =()=> {
    if(values.detail == "") {
      toast.error("Please add description!", {position: "top-right",autoClose: 2000});
      return false;
    }
    setState({...state,loader:true});  
    setTimeout(function(){
      console.log(`
        Community: ${values.community == 1 ? "AllCommunity" : "Selected Business"}.
        Business: ${values.business == 1 ? "All Business" : "Selected Business"}.
        Detail: ${values.detail}
      `)
      setShow(false)
			setState({...state,loader:false, detail: "", selectedFile:"", disabled:"disabled"});
			setValues({...values, detail: ""});
      toast.success("Post added successfully!", {position: "top-right",autoClose: 2000});
		},1500);
  }

  const selectCommunity =(e)=> {
    var id = e.target.dataset.id;
    console.log(id)
    setState({...state, spinner: "spinners"})
    setTimeout(function() {
      if(id == 1) {
        setState({...state, spinner: "", communityText: "Follower", communityIcon: "fa-user", cm_id: 2})
      } else {
        setState({...state, spinner: "", communityText: "Community", communityIcon: "fa-globe-americas", cm_id: 1})
      }
    }, 1500);
      
  }

  const detailEnter =(e)=> {
    var dValue = e.target.value;
    setValues({...values, detail: dValue})
    if(dValue.length > 0) {
      setState({...state, disabled: ""});
    } else {
      setState({...state, disabled: "disabled"});
    }
  }

  const setAvatar = event => {
    console.log(event.target.files[0])
    setState({...state, selectedFile: event.target.files[0]})
    console.log(state.selectedFile)
  }

  const changeBS =(e)=> {
    var bid = e.target.dataset.bid;
    setState({...state, bslide: "bslide", modelTitle: "Select Business"});
  }

  const selectedBs =(e)=> {
    var bsid = e.target.dataset.id;
    console.log(bsid)
    setState({...state, bslide: "", modelTitle: "Create post"});
  }

  return <>
    <div className="row dashCount">
      
      <div className="col-md-4">
        <div className="card engages">
          <i className="bg-icon fa fa-layer-group"></i>
          <div className="card-body hour row">
            <div className="col-md-6">
              <div className="count">{allengages.posts}</div>
              <span className="dashTitle"><FormattedMessage id="ENGAGEMENTS" /></span>
            </div>
            <div className="col-md-6">
              <DashEngages values={exngageVlaues} cat={exchangeCat} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card subscribes">
          <i className="bg-icon fa fa-user"></i>
          <div className="card-body day row">
            <div className="col-md-6">
              <div className="count">{allengages.subscriptions}</div>
              <span className="dashTitle"><FormattedMessage id="NEW_SUBSCRIBERS" /></span>
            </div>
            <div className="col-md-6">
              <DashSubscribe values={subscribeVlaues} cat={subscribeCat} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card mensions">
          <i className="bg-icon fa fa-hashtag"></i>
          <div className="card-body week row">
            <div className="col-md-6">
              <div className="count">{allengages.offer_claimed}</div>
              <span className="dashTitle"><FormattedMessage id="OFFER_AVAILED" /></span>
            </div>
            <div className="col-md-6">
              <DashOffers values={offersVlaues} cat={offersCat} />
            </div>
          </div>
        </div>
      </div>
      
      {
				state.loader === true?
				<BlockUI />
				: <div> </div>
			} 
      
      <div className="col-md-6 leftBlk">

        <Modal className="addPostModal" show={show} onHide={popupClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body id="postBlkBody">
            <i className="fa fa-times" onClick={popupClose}></i>
            <div className="headerBlk">
              <h3>{state.modelTitle}</h3>
            </div>

            <div className={`addPostForm `+state.bslide}>
              <div className="bSelectBlk">
                <div className="bAvatar">
                  <img src="https://cdn-qrbuzz.mumara.com/uploads/merchants/businesses/449/thumbnail-images%20%2869%29.jpeg" />
                </div>
                <div className="bData">
                  <div className="bTitleData">
                    <div className="bTitle">
                      Sam Real Estate
                    </div>
                    <div className="editTitle text-link" onClick={changeBS} data-bid="62"><i className="fa fa-pencil-alt text-dark"  onClick={changeBS} data-bid="453"></i></div>
                  </div>
                  <div className={`badge badge-secondary selectCommunity `+state.spinner} data-id={state.cm_id} onClick={selectCommunity}>
                      <i className={`fa `+state.communityIcon}  data-id={state.cm_id} onClick={selectCommunity}></i>
                      <span className="cum_name"  data-id={state.cm_id} onClick={selectCommunity}>{state.communityText}</span>
                      {/* <span className="spinner1"></span> */}
                      <div id="dots4"><span></span><span></span><span></span><span></span></div>
                  </div>
                </div>
              </div>
              <div className="postTextarea">
                  <TextField
										id="detail"
										label="What's on your mind, Sam Real Estate?"
										rows="4"
										multiline
										name="detail"
										variant="outlined"
										className={clsx(classes.textField, classes.dense)}
                    onChange={detailEnter}
										value={values.detail}
									/>
              </div>
              <div className="selectImgBlk" id="sImgBlk">
                  <div className="avatarLabel">Add to your post</div>
                  <label className="text-link" data-action="change" data-toggle="tooltip" title="Change avatar" data-original-title="Change avatar">
                      <i className="fa fa-image"></i>
                      <input type="file" name="profile_avatar" value={values.profile_avatar} onChange={setAvatar} accept=".png, .jpg, .jpeg" />
                  </label>
              </div>
              <div className="postActionBlk">
                <div className={`btn-post `+state.disabled} onClick={submitPost}>Post</div>
              </div>
              <div className="businessBlk">
                <div className="businessList">
                  {allbusinesses.map(option => (
                    <div className="bSelectBlk" key={option.id} >
                      <div className="bAvatar">
                        <img src={option.image} data-id={option.id} onClick={selectedBs} />
                      </div>
                      <div className="bData" data-id={option.id} onClick={selectedBs}>
                        {option.operating_as}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer />
        
        <h3 className="dash-time-title"><FormattedMessage id="RECENT_ENGAGEMENTS" /></h3>
        <VerticalTimeline layout="1-column" className="vertical-timeline--one-column-left">
          {
            allbusinesses !== undefined ? 
            <div className="mrchntPostBlk">
              <div className="merchntImg">
                <img src="https://cdn-qrbuzz.mumara.com/uploads/merchants/businesses/449/thumbnail-images%20%2869%29.jpeg" width="40px" height="40px" />
              </div>
              <div className="openPostPopup" onClick={addPost}>
                What's on your mind, Sam Real Estate?
              </div>
            </div>
            : <></>
          }
          { !allposts.length?
            <VerticalTimelineElement
              className="vertical-timeline-element--work empty-el-row"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<BulletIcon />}
            >
              <div className="emptyBlk">
              <FormattedMessage id="DATA_NOT_FOUND" />
              </div>
            </VerticalTimelineElement>
            :<>{allposts.map((option, index) => (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={ TimeStamp(new Date(option.updated_at).getTime()) }
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<BulletIcon />}
                key={index}
              >
                <div className="userBlk">
                  <span className="userImg"><img className="dashUserImg" src={option.user_image == null ? "/media/users/blank.png" : option.user_image} /></span>
                  <span className="msgBlk">
                    <span className="user-name">{option.first_name} {option.last_name}</span> <FormattedMessage id="AVAILED" /> {option.promo_title} @<span className="scanPlace">{option.branch_name}</span>
                    <span className="scanTime">{moment(option.updated_at).format("LT"+" - "+"ll")}</span>
                  </span>
                </div>
                <div className="userPost">
                  <div className="userPostMsg">{option.body}</div>
                  {option.media_post == 1 ?
                    <div className="dashPhtoBlk">
                      <img src={option.media_url.split(',')[0]} />
                    </div>
                    : <></>
                  }
                </div>
              </VerticalTimelineElement>
              ))}
              <VerticalTimelineElement
                className="no-shadow"
                iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                icon={<Completed />}
                position="right"
              />
            </>
          }
        </VerticalTimeline>
      </div>
     
      <div className="col-md-6 rightBlk">
        <h3 className="dash-time-title2"><FormattedMessage id="RECENT_SUBSCRIPTIONS" /> <span className="text-link text-primary" onClick={viewSubscribers}><FormattedMessage id="VIEW_ALL" /></span></h3>
        <VerticalTimeline layout="1-column" className="vertical-timeline--one-column-right">
          { !allsubs.length ?
            <>
              <VerticalTimelineElement
                className="vertical-timeline-element--work empty-el-row emptyrows"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<BulletIcon />}
              >
                <div className="emptyBlk">
                  <FormattedMessage id="DATA_NOT_FOUND" />
                </div>
              </VerticalTimelineElement>
            </>
            : 
            <>{ allsubs.map((option,index) => (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                date={ TimeStamp(new Date(option.created_at).getTime()) }
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<BulletIcon />}
              >
                <div className="userBlk">
                  <span className="userImg"><img className="dashUserImg" src={option.image == null ? "/media/users/blank.png" : option.image} /></span>
                  <span className="msgBlk">
                    <span className="user-name">{option.first_name} {option.last_name}</span> <FormattedMessage id="SUBSCRIBED" /> {option.promo_title} @<span className="scanPlace">{option.branch_name}</span>
                    <span className="scanTime">{moment(option.created_at).format("LT"+" - "+"ll")}</span>
                  </span>
                </div>
              </VerticalTimelineElement>
              ))}
              <VerticalTimelineElement
                className="no-shadow"
                iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                icon={<Completed />}
                position="right"
              />
            </> 
          }
        </VerticalTimeline>
      </div>
        
    </div>
  </>;
}

export default connect(null, dredux.actions)(DashboardPage);