import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage} from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import BlockUI from '../Component/BlockUI';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import swal from 'sweetalert';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

const initialValues = {   
};


function createData(id, name, price, action) {
    return { id, name, price, action };
  }
  
  const rows = [
    createData(1,'Pumpkin Burrito', 200),
    createData(2,'Pancake Plate', 400),
    createData(3,'Sweet Cake', 600),
    createData(4,'Ceaser Salad', 900),
    createData(5,'Pasta Salad', 1200),
    createData(6,'Spring Roles', 320),
    createData(7,'Samosas', 670),
    createData(8,'Chicken Roast',550),
    createData(8,'Bacon Roll',320),
    createData(8,'Satte',225),
    createData(8,'Mocha',450),
    createData(8,'Black Ice',330),
  ];
  
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Addon Name' },
    { id: 'price', numeric: false, disablePadding: true, label: 'Addon Price' },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              //padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

export function AddAddons() {

	const history = useHistory();

	function goBack() {
		history.push("/menu/all")
	}

  function cancelAdd() {
    history.push("/menu/all")
  }

    const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
        const newSelecteds = rows.map(n => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleChangeDense(event) {
        setDense(event.target.checked);
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = React.useState({
        name: "",
        price:""
    });

    const [state, setState] = React.useState({
        name: "",
        price:"",
        loader:false,
        valid_name: "",
        nameMsg:"hide",
        valid_price:"",
        priceMsg:"hide",
        catSave:1
    });

    const handleChange = name => event => {
        //console.log(event.target.value)
	    setState({ ...state, [name]: event.target.value, valid_name:"", nameMsg:"hide" });
        setValues({ ...values, [name]: event.target.value });
	};

    const getInputClasses = (fieldname) => {
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};

    const CategorySchema = Yup.object().shape({
    	// name: Yup.string()
	    //   	.required("Enter a broadcast name"),
	});


    const formik = useFormik({
    	initialValues,
    	validationSchema: CategorySchema,
    	onSubmit: (values, { setSubmitting, setStatus }) => {
        console.log(state.name, state.price)
        if(state.name === "") {
            setState({...state, valid_name:"is-invalid", nameMsg: "show"});
            return false;
        } 
        else if(state.price === "") {
          setState({...state, valid_price:"is-invalid", priceMsg: "show"});
          return false;
        } 
        else
        {
            setState({...state, valid_name:"valid", nameMsg: "hide", valid_price:"valid", priceMsg: "hide"});
            if(state.catSave == 1) {
                setTimeout(() => {
                    swal({
                        title: "Category Added",
                        text: state.name+" saved successfully!",
                        icon: "success",
                        button: null,
                        timer: 1500
                  });
                  setState({...state, name:"", loading:false, valid_name:"valid", nameMsg: "hide", catSave:1, valid_price:"valid", priceMsg: "hide"});
                  setValues({...values, name: "", price:""});
                }, 1000);
            } else {
                setTimeout(() => {
                    swal({
                        title: "Category Update",
                        text: state.name+" updated successfully!",
                        icon: "success",
                        button: null,
                        timer: 1500
                  });
                  setState({...state, name:"", loading:false, valid_name:"valid", nameMsg: "hide", catSave:1, valid_price:"valid", priceMsg: "hide"});
                  setValues({...values, name: "", price:""});
                }, 1000);
            }
            
        }
			  setState({...state, loading:true, valid_name:"valid", nameMsg: "hide", valid_price:"valid", priceMsg: "hide"});
	    },
	});



    const editCat =(e)=> {
        var id = e.target.dataset.id;
        var addon_name = e.target.dataset.name;
        var addon_price = e.target.dataset.price;
        setState({...state, loader:true})
        setTimeout(function() {
            setState({...state, loader:false, name: addon_name, catSave:2, price: addon_price})
            setValues({...values, name:addon_name, price: addon_price});
        }, 1000);
        //console.log(id, cat_name)
    }

    const removeCat = (e) => {
      var id = e.target.dataset.id;
      var name = e.target.dataset.name;
        setState({...state, loader:true})
        setTimeout(function() {
            setState({...state, loader:false})
            toast.success(name+" deleted successfully", {position: "top-right",autoClose: 2000});
        }, 1000);
    }

	return (<>
		<div className="row">

			{
				state.loader == true?
				<BlockUI />
				: <div></div>
			}
			<div className="col-md-12">
				<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b page-desc">
					<div className="alert-text">
						<span className="svg-icon menu-icon">
						<SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-left-panel-2.svg")}/>
						</span> 
						<span>
							<FormattedMessage id="PAGE.ADADDONS.DESC" />
						</span>
						<span className="svg-icon menu-icon goBack" onClick={goBack}>
							<i className="fa fa-long-arrow-alt-left"></i>
						</span>
					</div>
				</div>
			</div>
		</div>

        <div className="row">
            <div className="col-md-6">
                <div className="card card-custom gutter-b innerBlks">
                  <div className="card-header">
                    <div className="card-title">
                      <h3 className="card-label"><FormattedMessage id="PAGE.ADDMENUADDON.WIDGET.TITLE" /></h3>
                    </div>
                  </div>
                      <div className="card-body">

                        <form 
                            autoComplete="off"
                            onSubmit={formik.handleSubmit}
                            className="ffield form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            {formik.status ? (
                            <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>
                            ) : (
                            <div>
                            </div>
                            )}

                            <div className={`fieldBlk `+ state.valid_name}>
                                <TextField
                                    name="name"
                                    type="text"
                                    label="Addon Name"
                                    variant="outlined"
                                    className={`form-control h-auto py-0 px-0  ${getInputClasses(
                                        "name"
                                    )}`}
                                    value={values.name}
                                    onChange={handleChange("name")}
                                />
                                <span className={`fv-help-block `+ state.nameMsg}>Addon Name Required</span>
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained mb-0">
                                    <FormattedMessage id="PAGE.ADDMENUADDON.NAME.HELP" />
                                </p>
                            </div>

							              <div className={`fieldBlk `+ state.valid_price}>
                                <TextField
                                    name="price"
                                    type="number"
                                    label="Addon Price"
                                    variant="outlined"
                                    className={`form-control h-auto py-0 px-0  ${getInputClasses(
                                        "price"
                                    )}`}
                                    value={values.price}
                                    onChange={handleChange("price")}
                                />
                                <span className={`fv-help-block `+ state.priceMsg}>Addon Price Required</span>
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained mb-0">
                                    <FormattedMessage id="PAGE.ADDMENUADDON.PRICE.HELP" />
                                </p>
                            </div>

                            <div className="fieldBlk mt-0 class-submit">
                                <Button 
                                    type="submit"
                                    //disabled={formik.isSubmitting}
                                    variant="contained" 
                                    color="primary" 
                                    className={classes.button}
                                >
                                    <FormattedMessage id="SAVE" />
                                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                                </Button>
                                <Button variant="contained" className={classes.button} onClick={cancelAdd}>
                                    <FormattedMessage id="CANCEL" />
                                </Button>
                            </div>
                        </form>

                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card card-custom gutter-b innerBlks">
                      <div className="card-header">
                        <div className="card-title">
                          <h3 className="card-label"><FormattedMessage id="PAGE.ALLMENUADDON.WIDGET.TITLE" /></h3>
                        </div>
                      </div>
                      <div className="card-body">
                          <Paper className={classes.paper}>
                              <div className={classes.tableWrapper}>
                              <Table
                                  className={`menu-cat-table `+ classes.table}
                                  aria-labelledby="tableTitle"
                              >
                                  <EnhancedTableHead
                                      //numSelected={selected.length}
                                      order={order}
                                      orderBy={orderBy}
                                      //onSelectAllClick={handleSelectAllClick}
                                      onRequestSort={handleRequestSort}
                                      //rowCount={rows.length}
                                  />
                                  <TableBody>
                                  {stableSort(rows, getSorting(order, orderBy))
                                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                      .map((row, index) => {
                                      const isItemSelected = isSelected(row.name);
                                      const labelId = `enhanced-table-checkbox-${index}`;

                                      return (
                                          <TableRow
                                              hover
                                              onClick={event => handleClick(event, row.name)}
                                              role="checkbox"
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              key={row.name}
                                              selected={isItemSelected}
                                          >
                                          <TableCell component="th" id={labelId} scope="row">
                                              {row.name}
                                          </TableCell>
										                      <TableCell>
                                              {row.price}
                                          </TableCell>
                                          <TableCell align="right">
                                              <button 
                                                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                  data-id={row.id}
                                                  data-name={row.name}
                                                  data-price={row.price}
                                                  onClick={editCat}
                                              >
                                                  <i className="fa fa-edit text-primary" 
                                                      data-id={row.id}
                                                      data-name={row.name}
                                                      data-price={row.price}
                                                  ></i>
                                              </button>
                                              <button className="btn btn-icon btn-light btn-hover-danger btn-sm" 
                                                  data-id={row.id} 
                                                  data-name={row.name}
                                                  onClick={removeCat}
                                              >
                                                  <i className="fa fa-trash text-danger" 
                                                      data-id={row.id} 
                                                      data-name={row.name}
                                                  ></i>
                                              </button>
                                          </TableCell>
                                          </TableRow>
                                      );
                                      })}
                                  {emptyRows > 0 && (
                                      <TableRow style={{ height: 49 * emptyRows }}>
                                      <TableCell colSpan={3} />
                                      </TableRow>
                                  )}
                                  </TableBody>
                              </Table>
                              </div>
                              <TablePagination
                              rowsPerPageOptions={[5, 10, 25]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              backIconButtonProps={{
                                  'aria-label': 'Previous Page',
                              }}
                              nextIconButtonProps={{
                                  'aria-label': 'Next Page',
                              }}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                          </Paper>
                </div>
              </div>
            </div>
          </div>
        <ToastContainer />
	</>);

}