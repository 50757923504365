import React, {useState, useEffect} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { useHistory } from "react-router-dom";
import BlockUI from '../Component/BlockUI';
import {API_URL} from "../../Constants";

export function CreateBroadcast() {

	const history = useHistory();

	function goBack() {
		history.push("/campaigns/all")
    }

	const  useThis = (e) => {
		var id = e.target.dataset.user;
	    history.push({
		  	pathname: '/campaigns/editor/',
		  	state: {
		  		id: id,
		  	}
		});
		console.log(id);
	}

	const [state, setState] = useState({
		className: 'hide',
		popimg: '1.jpg',
		loader:true,
		items:[]
	});

	useEffect( ()=>{
		let mounted = true;
		fetch(API_URL + 'api/templates' , {
			method: "GET",
		}).then(r => r.json().then(data => {
			if(mounted) {
				setState({...state, items: data.templates, loader:false})
			}
		} ))
		.catch(e => e);
		return () =>  mounted = false;
	} , []);

	const showImage = (e) => {
		var img = API_URL + "templates/templates/" + e.target.dataset.image + '/thumbnail.png';

		setState({...state, popimg: img, loader:true});
		setTimeout(function() {
			setState({...state, popimg: img, className: 'show', loader:false});
		}, 1000);
		
	}

	const blankEditor =(e)=> {
		var id = e.target.dataset.user;
	    history.push({
		  	pathname: '/campaigns/html/',
		  	state: {
		  		id: id,
		  	}
		});
		console.log(id);
	}

	const closePopup = () => {
		setState({...state,className: 'hide'});
	}

	return (<>
		<div className="row">

			<div className="col-md-12">
				<div className="">
					<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
						<div className="alert-text">
							<span className="svg-icon menu-icon">
		                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
		                  	</span> 
							<span>
								<FormattedMessage id="PAGE.CREATEBROADCAST.DESC" />
							</span>
					        <span className="svg-icon menu-icon goBack" onClick={goBack}>
								<i className="fa fa-long-arrow-alt-left"></i>
					        </span>
						</div>
					</div>
				</div>
			</div>

			{
				state.loader == true?
				<BlockUI />
				: <div> </div>
			} 
			
			<div className={`popupImgBlk `+ state.popimg + ' ' + state.className}>
				<div className="flaticon2-cross text-link" onClick={closePopup}></div>
				<div className="pic">
					<img src={state.popimg}  />
				</div>
			</div>
		    		

			<div className="col-md-3" key="kl_">
				<div className="cbBlk blank">
					<div className="blankImage"><i className="flaticon2-browser-2"></i></div>
					<div className="cbMenuBlk">
						<button className="btn btn-primary" data-user="All" onClick={useThis}><FormattedMessage id="PAGE.CREATEBROADCAST.BLANKTEMPLATE" /></button>
					</div>
					<div className="template-data">
						<div className="tmp-name">Blank Template</div>
						<span className="badge badge-info">Blank</span>
					</div>
				</div>
			</div>	

			{state.items.map((item, index) => {
                return (
					<>
						<div className="col-md-3" key={index} id={`kl_`+index}>
							<div className="cbBlk" key={index}>
								<img src={API_URL + "templates/templates/" + item.name + "/thumbnail.png"} className="" />
								<div className="viewImage" data-image={item.name} onClick={showImage}><i className="fas fa-search" data-image={item.name} onClick={showImage}></i></div>
								<div className="cbMenuBlk">
									<button className="btn btn-primary" data-user={item.name} onClick={useThis}><FormattedMessage id="PAGE.CREATEBROADCAST.USETHIS" /></button>
								</div>
								<div className="template-data">
									<div className="tmp-name">{item.name} Template</div>
									<span className="badge badge-success">{item.name}</span>
								</div>
							</div>
						</div>
					</>
				);
			})}			

		</div>
	</>);

}