import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from "react-router-dom";
import swal from 'sweetalert';
import BlockUI from '../Component/BlockUI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect,useSelector} from 'react-redux';
import * as bredux from './_redux/businessRedux';
import Tooltip from '@material-ui/core/Tooltip';

function ViewBusiness(props) {

	var moment = require('moment');

	const history = useHistory();
	const location = useLocation();
	var bid = location.state.bid;
	var bs_opras = location.state.bs_opras;
	function goBack() {
		history.push("/business/all")
	}

	const verifyBusiness =()=> {
		history.push({
			pathname: '/business/verify',
			state: {
				id:bid,
				operating: bs_opras, 
			}
		});
	}

	const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

	const classes = useStyles();
	const [values, setValues] = React.useState({
		addresses: '33 N, Gulberg III, Lahore 54000, PK',
	    address: '',
	});

  	const [state, setState] = React.useState({
		  show: true,
		  loader:true
	});

	const globalState  = useSelector(state=>state.business)
    const [business, setdata] = useState([]);

	useEffect( ()=>{
		setdata(globalState.current_business)
	});

	useEffect( ()=>{
		props.getBusiness(bid)
		setTimeout(function(){
			setState({...state,loader:false});      
		  },1000);
	} , []);

  	function deleteBusiness(e) {
		var id = e.target.dataset.id;
		swal({
		  title: "Permanently Close Business",
		  text: "Business has attached many branches and subscribers. First switch branches then delete business.",
		  icon: "warning",
		  button: "Cancel",
		});
		swal({
			title: "Permanently Close Business?",
			text: "Business has attached many branches and subscribers. First switch branches then delete business.",
			icon: "error",
			buttons: true,
			dangerMode: true,
		  })
		  .then((willDelete) => {
			if (willDelete) {
			  props.deleteBusiness(Number(id))
			  swal("Business has been deleted!", {
				icon: "success",
			  });
			  setTimeout(() => {
				history.push({
					pathname: '/business/all'
				});
			  }, 1500);
		  } 
		  setTimeout(() => {
			swal.close();
		}, 2000);
		});
	}

	function saveData() {
		setState({...state, show:true});
		var adrs = business.office_address;
		var dv = values.address;
		//console.log("old: "+adrs)
		//console.log("new: "+dv)
		business.office_address = dv;
		setValues({addresses: dv});
		toast.success("Address successfully changed", {position: "top-right",autoClose: 3000}); 
		props.updateBusinessAddr(Number(bid), dv); 
	}

	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  	</span> 
					<span>
						<FormattedMessage id="PAGE.BUSINESSDETAILS.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

			{
				state.loader === true?
				<BlockUI />
				: <div> </div>
			} 

			<div className="card card-custom gutter-b col-md-12 buss-details">
				<div className="card-header">
					<div className="card-title"><h3 className="card-label"><FormattedMessage id="PAGE.VIEWBUSINESS.WISGET_TITLE" /> - <span>{bs_opras}</span></h3></div>
					{
			          state.show?
			          <Button variant="contained" className="Business-edit"  onClick={()=>(setState({...state, show:false}))} > <FormattedMessage id="EDIT" /> </Button>
			          :
			          <div>
			          	<Button variant="contained" className="Business-edit" color="secondary"  onClick={saveData} > <FormattedMessage id="SAVE" /> </Button>
			          	<Button variant="contained" className="Business-edit" onClick={()=>(setState({...state, show:true}))} > <FormattedMessage id="CANCEL" /> </Button>
			          </div>
			        }
					
				</div>
		        <div className="card-body">

		        	<div className="row">
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.BUSINESS.NAME" /></label>
		        			<div className="detail">{business.name} {business.verified_on ? <img src="/media/misc/v.png" width="20px" alt={business.name} />:''} </div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.BUSINESS.VERIFICATION" /></label>
							<div className="detail">
								{
									business.verified_on == null && business.verification_document == null ? <div>Unverified - <span className="text-primary text-link" onClick={verifyBusiness}>Verify Business</span></div> 
									: business.verified_on == null && business.verification_document != null ? "Under Review"
									: "Verified"
								} 
							</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.OPERATION_AS" /></label>
		        			<div className="detail">{business.operating_as}</div>
		        		</div>
						<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.USERNAME" /></label>
		        			<div className="detail">
								{
									business.username ? 
									<span className="badge badge-success">{business.username}</span>  
									:<Tooltip placement="top" title="No Username Added" aria-label="No Username Added" arrow>
										<span className="badge badge-warning">No Username</span>
									</Tooltip>
								}
							</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.REGISTRATION_TYPE" /></label>
							<div className="detail">{business.type}</div>
		        		</div>
						{business.type === "Corporate" ? 
						<div className="col-md-6">
							<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.REGISTRATION_NUMBER" /></label>
							<div className="detail">{business.registration_number }</div>
						</div> :
						<></>
						}
		        		
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.STATUS" /></label>
							<div className="detail">{business.status === 1 ? "Active" : "Inactive"}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.REGISTRATION_ON" /></label>
							<div className="detail">{moment(business.register_on).format("LL")}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.NUMBER_BRANCHES" /></label>
							<div className="detail">
								{
									business.total_branches === 0 ? "No Branch"
									:  business.total_branches === 1 ? business.total_branches+" Branch"
									: business.total_branches+" Branches"
								}
							</div>
		        		</div>
						<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.PHONE" /></label>
		        			<div className="detail">{business.phone_number}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.ADDRESS" /></label>
		        			{
					          state.show?
					          <div className="detail">{business.office_address}</div>
					          :<div className="addrBlock">
			        				<TextField
				                      required
				                        id="address"
				                        name="address"
				                        type="address"
				                        defaultValue={business.office_address}
				                        className={classes.textField}
				                        onChange={e => setValues({address: e.target.value})}
				                        placeholder="Branch Address"
				                        margin="normal"
				                        variant="outlined"
	        							//helperText="Change Branch Address Help Text"
				                    />
		        				</div>
					        }   			
		        		</div>
						<div className="col-md-6">
		        			<label htmlFor="name">Business Area</label>
		        			<div className="detail">{business.area}</div>
		        		</div>
						<div className="col-md-6">
		        			<label htmlFor="name">Business City</label>
		        			<div className="detail">{business.city}</div>
		        		</div>
						<div className="col-md-6">
		        			<label htmlFor="name">Country</label>
		        			<div className="detail">{business.country_id}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.VIEWBUSINESS.CREATED_ON" /></label>
		        			<div className="detail">{moment(business.created_at).format("LL")}</div>
		        		</div>
		        		{
				          state.show?
				          null
				          :<div className="col-md-12">
			        			<div className="detail text-right">
			        				<span className="text-danger text-link" data-id={business.id} onClick={deleteBusiness}>- <FormattedMessage id="PAGE.VIEWBUSINESS.CLOSE.BUSINESS" /></span>
			        			</div>
			        		</div>
				        }	
		        	</div>
		        	<ToastContainer />
		        </div>
		    </div>  

		</div>
	</>);

}

export default connect(null, bredux.actions)(ViewBusiness);