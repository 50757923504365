import React from "react";

const BulletIcon = () => (
  <>
    <span className="dashboardBullet">
        <i className="fas fa-user-alt"></i>
    </span>
  </>
);
export default BulletIcon;


