import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

export function SelectEditor() {

	const history = useHistory();
	const location = useLocation();

	function goBack() {
		history.push("/campaigns/new")
    }

    const  useHtml = (e) => {
    	var id = location.state.id;
	    history.push({
		  	pathname: '/campaigns/html/',
		  	state: {
		  		id: id
		  	}
		});
	}

    const  useBuilder = (e) => {
    	var id = location.state.id;
	    history.push({
		  	pathname: '/campaigns/builder/',
		  	state: {
		  		id: id
		  	}
		});
	}

	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
                  	</span> 
					<span>
						<FormattedMessage id="PAGE.CREATEBROADCAST.DESC" />
					</span>
			        <span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			        </span>
				</div>
			</div>

			<div className="card col-md-12">
				<span className="line-vertical"></span>
				<div className="card-body">
					<div className="row">
						<div className="col-md-6">
							<div className="slEditorBlk first">
								<div className="edtImgBlk">
									<img src={toAbsoluteUrl("/media/images/editor.jpg")} alt="Select Editor" />
								</div>
								<div className="slEditorCont">
									<h2><FormattedMessage id="PAGE.SELECTEDITOR.EDITOR" /></h2>
									<h1><FormattedMessage id="PAGE.SELECTEDITOR.EDITOR.LABEL" /></h1>
									<button type="button" className="btn btn-primary" onClick={useHtml}><FormattedMessage id="PAGE.SELECTEDITOR.EDITOR.BUTTON" /></button>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="slEditorBlk last">
								<div className="edtImgBlk">
									<img src={toAbsoluteUrl("/media/images/builder.jpg")} alt="Select Editor" />
								</div>
								<div className="slEditorCont">
									<h2><FormattedMessage id="PAGE.SELECTEDITOR.BUILDER" /></h2>
									<h1><FormattedMessage id="PAGE.SELECTEDITOR.BUILDER.LABEL" /></h1>
									<button type="button" className="btn btn-success" onClick={useBuilder}><FormattedMessage id="PAGE.SELECTEDITOR.BUILDER.BUTTON" /></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>);

}