import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from "react-router-dom";
import swal from 'sweetalert';
import BlockUI from '../Component/BlockUI';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {connect,useSelector} from 'react-redux'
import * as bredux from './_redux/businessRedux'

function ViewBranch(props) {

	var moment = require('moment');

	const globalState  = useSelector(state=>state.business)
	const [allcountries, setCountryData] = useState([]);
	const {user} = useSelector(state => state.auth);
	const [branch, setdata] = useState([]);
	
	useEffect( ()=>{
		setdata(globalState.current_branch)
		setCountryData(globalState.all_countries)
	});

	useEffect( ()=>{
		props.getBranch(br_id)
		props.getCountries()
		setTimeout(function(){
			setState({...state,loader:false});      
		  },1000);
	} , []);

	const contryName = allcountries.find(post=>post.id===branch.country_id);

	const history = useHistory();
	const location = useLocation();
	var br_id = location.state.bid;
	var bs_opras = location.state.bs_opras;
	var bs_id = location.state.bs_id;
	var bs_name = location.state.bs_name;
	function goBack() {
		history.goBack();
	}

	const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

	const classes = useStyles();
	const [values, setValues] = React.useState({
		addresses: '',
	    address: '',
	});

  	const [state, setState] = React.useState({
		  show: true,
		  addr:'',
		  brid: br_id,
		  cnName:"",
		  loader: true
	});

		

	function saveData() {
		var adrs = branch.address1;
		var dv = state.address;
		// console.log("new: "+dv)
		// console.log("old: "+adrs)
		branch.address1 = dv;
		setState({...state, show:true});
		props.updateBranchAddr(Number(br_id), state.address)
	}

	function deleteBusiness(e) {
		var id = e.target.dataset.id;
		var user_id =user.id;
		swal({
			title: "Are you sure?",
			text: "Branch has many subscribers attached. All branch subscribers will be lost!",
			icon: "error",
			buttons: true,
			dangerMode: true,
		})
		.then((willDelete) => {
			props.deleteBranch(id, user_id)
			if (willDelete) {
				swal("Branch has been deleted!", {
					icon: "success",
				});
				setTimeout(() => {
					history.push({
						pathname: '/business/branches',
						state: {
							bs_id: bs_id,
							bs_opras:bs_opras,
							bs_name: bs_name
						}
					});
				}, 1500);
				setTimeout(() => { swal.close(); }, 2000);
			} 
		});
  	}

	return (<>
		<div className="row">
			{/* JSON.stringify(globalState.show_error) */}
    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  	</span> 
					<span>
						<FormattedMessage id="PAGE.BRANCHDETAILS.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

			{
				state.loader === true?
				<BlockUI />
				: <div> </div>
			} 

			<div className="card card-custom gutter-b col-md-12 buss-details">
				<div className="card-header">
					<div className="card-title"><h3 className="card-label">{location.state.br_name} - <span><FormattedMessage id="PAGE.BRANCHDETAIL.WISGET_TITLE" /></span></h3></div>
					{
			          state.show?
			          <Button variant="contained" className="Business-edit"  onClick={()=>(setState({...state, show:false}))} > <FormattedMessage id="EDIT" /> </Button>
			          :
			          <div>
			          	<Button variant="contained" className="Business-edit" color="secondary"  onClick={saveData} > <FormattedMessage id="SAVE" /> </Button>
			          	<Button variant="contained" className="Business-edit" onClick={()=>(setState({...state, show:true}))} > <FormattedMessage id="CANCEL" /> </Button>
			          </div>
			        }
					
				</div>
		        <div className="card-body">

		        	<div className="row">
		        		<div className="col-md-6">
		        			<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.NAME" /></label>
			        			<div className="detail">{branch.name}</div>
		        			</div>
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.CITY" /></label>
		        				<div className="detail">{branch.city}</div>
		        			</div>	
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.CREATED_ON" /></label>
		        				<div className="detail">{moment(branch.created_at).format("LLL")}</div>
		        			</div>	
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.ENGAGEMENTS" /></label>
		        				<div className="detail">0</div>
		        			</div>	
							<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.PHONE" /></label>
		        				<div className="detail">{branch.phone_number}</div>
		        			</div>
		        		</div>
		        		<div className="col-md-6">
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.ADDRESS" /></label>
				        			{
							          state.show?
							          <div className="detail">{branch.address1} {branch.address2}</div>
							          :<div className="addrBlock">
					        				<TextField
						                      required
						                        id="address"
						                        name="address"
						                        type="address"
						                        defaultValue={branch.address1}
						                        className={classes.textField}
						                        onChange={e => setState({...state, address: e.target.value})}
						                        placeholder="Branch Address"
						                        margin="normal"
						                        variant="outlined"
			        							//helperText="Change Branch Address Help Text"
						                    />
				        				</div>
							        }
		        			</div>	
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.AREA" /></label>
	        					<div className="detail">{branch.area}</div>
		        			</div>	
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.COUNTRY" /></label>
		        				<div className="detail">
									{contryName ? contryName.country_name : ""}
								</div>
		        			</div>	
			        		<div className="viewBlk">
		        				<label htmlFor="name"><FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.SUBSCRIBERS" /></label>
		        				<div className="detail">{branch.total_subscribers}</div>
		        			</div>	
			        		<div className="viewBlk">
		        				
		        			</div>	
		        		</div>
		        		{
				          state.show?
				          null
				          :<div className="col-md-12">
			        			<div className="detail text-right">
			        				<div className="text-danger text-link" data-id={branch.id} onClick={deleteBusiness}>- <FormattedMessage id="PAGE.BRANCHDETAIL.BRANCH.CLOSE" /></div>
			        			</div>
			        		</div>
				        }	
		        	</div>
		        	<ToastContainer />
		        </div>
		    </div>  

		</div>
	</>);

}

export default connect(null, bredux.actions)(ViewBranch);