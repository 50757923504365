import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import '../../../_metronic/_assets/css/menus.scss';
import {connect,useSelector} from 'react-redux';
import * as mredux from './_redux/menuRedux';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';

import { useFormik } from "formik";
import * as Yup from "yup";

import { FormattedMessage} from "react-intl";
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import BlockUI from '../Component/BlockUI';

const useStyles = makeStyles(theme => ({
	table: {
	  minWidth: 650,
	},
	fab: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));

const initialValues = {   
};

function Menu(props) {

	document.body.className = 'quick-panel-right demo-panel-right offcanvas-right header-static subheader-enabled aside-enabled aside-fixed aside-minimize-hoverable brand-dark menucats';

    const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const catalog_id = location.state.id;
	//console.log(location.state.id, location.state.name, location.state.desc)

	function goBack() {
		history.push("/menu/catalogs")
	}

	const catalogs = () => {
		history.push("/menu/catalogs");
	}

	const category = () => {
		history.push("/menu/category");
	}

	const addAddons = () => {
		history.push("/menu/addons");
	}

	const addProduct = () => {
		history.push("/menu/add");
	}

	const globalState  = useSelector(state=>state.menus)
	const {user} = useSelector(state => state.auth);
	const user_id = user.id; 
	const [allcats, setdata] = useState([]);

	const [loading, setLoading] = useState(false);

	const [values, setValues] = React.useState({
        name: ""
    });

	const [state, setState] = React.useState({
		catID: location.state.id,
		bsID: location.state.bs_id,
		name:"",
		assID: "",
        pageLoader: true,
		modalBG: "hide",
		modalBG2:"hide",
		valid_name: "",
		nameMsg: "hide",
		valid_name2: "",
		nameMsg2: "hide",
		catSave: 1,
		loader: false,
		remId: "",
        remName: "",
		modalBG4: "hide",
		remItemId: 0,
		itemDName: "",
		valid_name3: "",
		nameMsg3: "hide",
		itemname: ""
    });

	useEffect( ()=>{
		setdata(globalState.all_categories);
		//console.log(globalState.all_categories);
	});

	useEffect( ()=>{
		props.getAllCategories(location.state.id)
		setTimeout(function(){
			setState({...state,pageLoader:false});      
		},1000);
	} , []);

	const addFirstCat =()=> {
		console.log("111")
	}

	const addNewCat =()=> {
		setState({...state, modalBG: "show", name: ""});
		setValues({...values, name: ""});
	}

	const addItem =(e)=> {
		var id = e.target.dataset.id;
		var name = e.target.dataset.name;
		history.push({
			pathname: '/menu/item/add',
			state: {
			  id: id,
			  name: name,
			  catid: location.state.id
			}
		});
		console.log(id, name)
	}

	const editItem =(e)=> {
		var id = e.target.dataset.id;
		var name = e.target.dataset.name;
		var ctgid = e.target.dataset.ctgid;
		var ctgname = e.target.dataset.ctgname;
		var catalog_id = location.state.id;
		var catalog_name = location.state.name;
		var item = e.target.dataset.item;
		var adnset = e.target.dataset.adnset;
		var attrset = e.target.dataset.attrset;

		history.push({
			pathname: '/menu/item/update',
			state: {
			  id: id,
			  name: name,
			  ctgid: ctgid,
			  ctgname: ctgname,
			  catalog_id: catalog_id,
			  catalog_name: catalog_name,
			  item:item,
			  adnset: adnset == "" ? 0 : adnset,
			  attrset: attrset == "" ? 0 : attrset
			}
		});
		//console.log(id, name, ctgid, ctgname, catalog_id, catalog_name)
	}

	const handleChange = name => event => {
        //console.log(event.target.value)
	    setState({ ...state, [name]: event.target.value, valid_name:"", nameMsg:"hide" });
        setValues({ ...values, [name]: event.target.value });
	};

	const getInputClasses = (fieldname) => {
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};

	const MenuSchema = Yup.object().shape({
    	// name: Yup.string()
	    //   	.required("Enter a broadcast name"),
	});

	const formik = useFormik({
    	initialValues,
    	validationSchema: MenuSchema,
    	onSubmit: (values, { setSubmitting, setStatus }) => {
            //console.log( state.catID, state.name)
            if(state.name === "") {
                setState({...state, valid_name:"is-invalid", nameMsg: "show"});
                return false;
            } 
            else
            {
                setState({...state, valid_name:"valid", nameMsg: "hide"});
                if(state.catSave == 1) {
					props.addCategory(Number(state.catID), state.name)
                    setTimeout(() => {
                        swal({
                            title: "Category Added",
                            text: state.name+" saved successfully!",
                            icon: "success",
                            button: null,
                            timer: 1500
                      });
                      setTimeout(() => {
                        props.getAllCategories(location.state.id)
                      }, 1000);
                      setState({...state, name:"", loading:false, valid_name:"is-valid", nameMsg: "hide", catSave:1, modalBG: "hide"});
                      setValues({...values, name: ""});
                    }, 1000);
                } else {
                    setTimeout(() => {
                        props.updateCategory(Number(state.catID), state.name, Number(state.assID), Number(state.bsID))
                        swal({
                            title: "Category Update",
                            text: state.name+" updated successfully!",
                            icon: "success",
                            button: null,
                            timer: 1500
                        });
						setTimeout(() => {
							props.getAllCategories(location.state.id)
						}, 1000);
                      setState({...state, name:"", loading:false, valid_name:"valid", nameMsg: "hide", catSave:1, modalBG: "hide"});
                      setValues({...values, name: ""});
                    }, 1000);
                }
                
            }
			setState({...state, loading:true, valid_name:"valid", nameMsg: "hide"});
	    },
	});

	const updateCatName =(e)=> {
		var id = e.target.dataset.id;
		var name = e.target.dataset.name;
		setState({...state, name: name, assID: id, modalBG: "show", catSave:2});
		setValues({...values, name: name});
	}

	const removeCat = (e) => {
		var id = e.target.dataset.id;
		var name = e.target.dataset.name;
		setState({...state, modalBG2: "show", remId: id, remName: name});
		console.log(id, name)
	}

	const confirmDelete =(e)=> {
		if(state.catname == "") {
		  setState({...state, valid_name2: "is-invalid", nameMsg2: "show"});
		  return false;
		} else if(state.catname !== state.remName) {
		  setState({...state, valid_name2: "is-invalid", nameMsg2: "show"});
		  return false;
		} else {
		  	props.deleteCategory(Number(state.remId));
			  swal({
				title: "Category Deleted",
				text: state.name+" deleted successfully!",
				icon: "success",
				button: null,
				timer: 1500
			});
			setTimeout(() => {
				props.getAllCategories(location.state.id)
			}, 1000);
			setState({...state, valid_name2: "valid", nameMsg2: "hide", modalBG2: "hide", catname :""});
			setValues({...values, catname: ""});
		}
	}

	function getPrice(arraydata) {
		var variants = JSON.parse(arraydata);
		var min = Math.min.apply(null, variants.map(function(item) {
			return item?.price;
		}));
		var max = Math.max.apply(null, variants.map(function(item) {
			return item?.price;
		}));
		//console.log(min, max);
		return min;
	}

	const deleteItem =(e)=> {
		var id = e.target.dataset.id;
		var name = e.target.dataset.name;
		//console.log(id , name)
		setState({...state, modalBG4: "show", remItemId: id,itemDName: name });
		//console.log(id, name)
	}

	const confirmItemDelete =()=> {
		if(state.itemname == "") {
			setState({...state, valid_name3: "is-invalid", nameMsg3: "show"});
			return false;
		  } else if(state.itemname !== state.itemDName) {
			setState({...state, valid_name3: "is-invalid", nameMsg3: "show"});
			return false;
		  } else {
				props.deleteItem(Number(state.remItemId));
				swal({
				  title: "Item Deleted",
				  text: state.itemname+" deleted successfully!",
				  icon: "success",
				  button: null,
				  timer: 1500
			  });
			  setTimeout(() => {
				  props.getAllCategories(location.state.id)
			  }, 1000);
			  setState({...state, valid_name3: "valid", nameMsg3: "hide", modalBG4: "hide", itemname :""});
			  setValues({...values, itemname: ""});
		  }
	}

	return (<>
		<div className="row mainmenu_page" id="menuPage">

			{
				state.pageLoader ? 
				<div className="loadings" id="loadings">
					<div className="loaders"></div>
				</div>
				:<></>
			}

			{
				state.loader == true?
				<BlockUI />
				: <></>
          	}

			<h1 className="catgr-title">
				{location.state.name}
				<span className="svg-icon menu-icon goBack" onClick={goBack}>
					<i className="fa fa-long-arrow-alt-left" onClick={goBack}></i>
				</span>
			</h1>

			<div className={`fade modal-backdrop `+state.modalBG}></div>
			<div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG}>
				<div role="document" className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
					<div className="modal-header">
						<div className="modal-title h4" id="contained-modal-title-vcenter"><FormattedMessage id="PAGE.ADDMENUCATALOG.WIDGET.TITLE" /></div>
					</div>
					<div className="modal-body">
						<form 
						autoComplete="off"
						onSubmit={formik.handleSubmit}
						className="form fv-plugins-bootstrap fv-plugins-framework"
						>
						{formik.status ? (
						<div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
							<div className="alert-text font-weight-bold">{formik.status}</div>
						</div>
						) : (
						<div>
						</div>
						)}

						<div className={`fieldBlk `+ state.valid_name}>
							<TextField
								name="name"
								type="text"
								label="Catalogue Name"
								variant="outlined"
								className={`form-control h-auto py-0 px-0  ${getInputClasses(
									"name"
								)}`}
								value={values.name}
								onChange={handleChange("name")}
							/>
							<span className={`fv-help-block `+ state.nameMsg}>Category Name Required</span>
							<p className="MuiFormHelperText-root MuiFormHelperText-contained">
								<FormattedMessage id="PAGE.ADDMENUCATALOG.NAME.HELP" />
							</p>
						</div>

						<div className="fieldBlk mt-0 class-submit">
							<Button 
								type="submit"
								//disabled={formik.isSubmitting}
								variant="contained" 
								color="primary" 
								className={classes.button}
							>
								<FormattedMessage id="SAVE" />
								{loading && <span className="ml-3 spinner spinner-white"></span>}
							</Button>
							<Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG: "hide", name: "" })}>
								<FormattedMessage id="CANCEL" />
							</Button>
						</div>
						</form>
					</div>
					</div>
				</div>
			</div>

			<div className={`fade modal-backdrop `+state.modalBG2}></div>
			<div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG2}>
				<div role="document" className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
					<div className="modal-body">
						<div className="modelheading">
						<h4>Are you sure?</h4>
						<p>Catalog may belong to categories. All categories and items will be lost!</p>
						</div>
						<div className={`fieldBlk mt-8 `+ state.valid_name2}>
							<TextField
								name="catname"
								type="text"
								label="Category Name"
								variant="outlined"
								className={`form-control h-auto py-0 px-0  ${getInputClasses(
									"catname"
								)}`}
								value={values.catname}
								onChange={handleChange("catname")}
							/>
							<span className={`fv-help-block `+ state.nameMsg2}>Catalogue Name missmatch!</span>
							<p className="MuiFormHelperText-root MuiFormHelperText-contained">
								Write your menu catalog name to confirm delete.
							</p>
						</div>
						<div className="fieldBlk mt-0 mb-0 class-submit">
							<Button 
								type="button"
								variant="contained" 
								color="primary" 
								className={classes.button}
								onClick={confirmDelete}
							>
								<FormattedMessage id="CONFIRM" />
							</Button>
							<Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG2: "hide" })}>
								<FormattedMessage id="CANCEL" />
							</Button>
						</div>
					</div>
					</div>
				</div>
			</div>

			<div className={`fade modal-backdrop `+state.modalBG4}></div>
			<div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG4}>
				<div role="document" className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
					<div className="modal-body">
						<div className="modelheading">
						<h4>Are you sure?</h4>
						<p>Are you sure to delete this item? If Yes then write item name and click confirm to delete item.</p>
						</div>
						<div className={`fieldBlk mt-8 `+ state.valid_name3}>
							<TextField
								name="itemname"
								type="text"
								label="Item Name"
								variant="outlined"
								className={`form-control h-auto py-0 px-0  ${getInputClasses(
									"itemname"
								)}`}
								value={values.itemname}
								onChange={handleChange("itemname")}
							/>
							<span className={`fv-help-block `+ state.nameMsg3}>Item name missmatch!</span>
							<p className="MuiFormHelperText-root MuiFormHelperText-contained">
								Write Item name to confirm delete.
							</p>
						</div>
						<div className="fieldBlk mt-0 mb-0 class-submit">
							<Button 
								type="button"
								variant="contained" 
								color="primary" 
								className={classes.button}
								onClick={confirmItemDelete}
							>
								<FormattedMessage id="CONFIRM" />
							</Button>
							<Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG4: "hide" })}>
								<FormattedMessage id="CANCEL" />
							</Button>
						</div>
					</div>
					</div>
				</div>
			</div>

			<div className="card card-custom gutter-b col-md-12 firstBlk">
		        <div className="card-body">
					
					<div className="toolbar hide">
		        		<div className="form-group text-right">
							<Button variant="contained" color="primary" className={classes.button} onClick={catalogs}>
								Catalogs
						    </Button>
							<Button variant="contained" color="primary" className={classes.button} onClick={category}>
						        Category
						    </Button>
							<Button variant="contained" color="primary" className={classes.button} onClick={addAddons}>
						        Addons
						    </Button>
				        	<Button variant="contained" color="secondary" className={classes.button} onClick={addProduct}>
						        Add new Product
						    </Button>
		        		</div>
		        	</div>

					<div className="toolbar">
		        		<div className="form-group text-right icon-action">
							{
								allcats.length > 0 ?
								<>
								<Button variant="contained" color="primary" className={classes.button}  onClick={addNewCat}>
									<i className="fa fa-plus"></i> Add New Category
						    	</Button>
								<Button variant="contained" color="secondary" className={classes.button} onClick={catalogs}>
									Catalogs
								</Button>
								</>
								:
								<>
								<Button variant="contained" color="primary" className={classes.button}  onClick={addNewCat} >
									<i className="fa fa-plus"></i> Add Your First Category
								</Button>
								<Button variant="contained" color="secondary" className={classes.button} onClick={catalogs}>
									Catalogs
								</Button>
								</>
							}
							
		        		</div>
		        	</div>

					{
						allcats.length > 0 ?
						<>
							{/* <div className="categoryBlk" id="categoryBlk1">
								<div className="catmenuRow">
									<div className="categName">
										Burger Deals One
									</div>
									<div className="catMenuRow">
										<Fab color="primary" aria-label="Add" className={classes.fab}>
											<AddIcon />
										</Fab>
										<Fab color="secondary" aria-label="Edit" className={classes.fab}>
											<Icon>edit_icon</Icon>
										</Fab>
										<Fab aria-label="Delete" className={classes.fab}>
											<DeleteIcon />
										</Fab>
									</div>
								</div>
								<div className="itemsMainBlk">
									<div className="itemListBlk" id="itemListBlk1">
										<div className="itemImgBlk">
											<div className="editActBlk" onClick={editItem} data-id="1">
												<div className="text-link fa fa-pencil-alt" onClick={editItem} data-id="1"></div>
											</div>
											<img src="/media/images/item1.png" className="image-responsive" alt="Item Image" />
										</div>
										<div className="itemInfoBlk">
											<div className="itemTitle" onClick={editItem} data-id="1">Burger Double Deal</div>
											<div className="itemDesc">Burger Deal with Large Fries &amp; Coke.</div>
											<div className="PriceTag">Rs: <span>899/-</span></div>
										</div>
									</div>

									<div className="itemListBlk" id="itemListBlk2">
										<div className="itemImgBlk">
											<div className="editActBlk" onClick={editItem} data-id="2">
												<div className="text-link fa fa-pencil-alt" onClick={editItem} data-id="2"></div>
											</div>
											<img src="/media/images/item2.png" className="image-responsive" alt="Item Image" />
										</div>
										<div className="itemInfoBlk">
											<div className="itemTitle" onClick={editItem} data-id="2">Burger Deal Midnight</div>
											<div className="itemDesc">Fresh Meat with Fresh Salad &amp; Coke.</div>
											<div className="PriceTag">Rs: <span>1799/-</span></div>
										</div>
									</div>
								</div>
							</div> */}

							{allcats.map((option, index) => (
								<div className="categoryBlk" id={"categoryBlk"+option.id} key={option.id}>
									<div className="catmenuRow">
										<div className="categName">
											{option.name}
										</div>
										<div className="catMenuRow">
											<button 
												className="btn btn-success btn-round"
												data-id={option.id}
												data-name={option.name}
												onClick={addItem}
											>
												<i 
													className="fa fa-plus"
													data-id={option.id}
													data-name={option.name}
													onClick={addItem}
												></i>
											</button>
											<Fab 
												color="secondary" 
												aria-label="Edit" 
												className={classes.fab}
												data-id={option.id}
												data-name={option.name}
												onClick={updateCatName}

											>
												<Icon
													data-id={option.id}
													data-name={option.name}
													onClick={updateCatName}
												>
													edit_icon
												</Icon>
											</Fab>
											<button 
												className="btn btn-danger btn-round"
												data-id={option.id} 
												data-name={option.name}
												onClick={removeCat}
											>
												<i 
													className="fa fa-trash"
													data-id={option.id} 
													data-name={option.name}
												></i>
											</button>
										</div>
									</div>
									<div className="itemsMainBlk">
										{
											allcats[index].items !== [] ?
											allcats[index].items.map((item, keys) => (
												<div className="itemListBlk" id={"itemListBlk1"+item.id} key={"items-"+item.id}>
													<div className="itemImgBlk">
														<div className="editActBlk" onClick={editItem} data-item={JSON.stringify(item.item)} data-id={item.item.id} data-adnset={item.addons_sets == [] ? 0 : item.addons_sets} data-attrset={item.attribute_sets == [] ? 0 : item.attribute_sets} data-name={item.item.title} data-ctgid={option.id} data-ctgname={option.name}>
															<div className="text-link fa fa-pencil-alt" data-item={JSON.stringify(item.item)} onClick={editItem} data-id={item.item.id} data-name={item.item.title} data-adnset={item.addons_sets == [] ? 0 : item.addons_sets} data-attrset={item.attribute_sets == [] ? 0 : item.attribute_sets} data-ctgid={option.id} data-ctgname={option.name}></div>
														</div>
														<img src={item.item.image !== undefined && item.item.image !== "" && item.item.image !== null ? item.item.image : "/media/images/item1.png"} className="image-responsive" alt={item.item.title} />
													</div>
													<div className="itemInfoBlk">
														<span className="delete-item text-link" data-id={item.item.id} data-name={item.item.title} onClick={deleteItem}>
															<i className="fa fa-trash text-danger" data-id={item.item.id} data-name={item.item.title}></i>
														</span>
														<div className="itemTitle" onClick={editItem} data-item={JSON.stringify(item.item)} data-id={item.item.id} data-name={item.item.title} data-adnset={item.addons_sets == [] ? 0 : item.addons_sets} data-attrset={item.attribute_sets == [] ? 0 : item.attribute_sets} data-ctgid={option.id} data-ctgname={option.name}>{item.item.title}</div>
														<div className="itemDesc">{item.item.description}</div>
														<div className="PriceTag">
															{ item.item.sale_price ? 
																<>Rs: <span>{item.item.sale_price}/-</span></>
																:<>Rs: <span>{getPrice(item?.item?.variants)}</span></>
															}
														</div>
													</div>
												</div>
											))
											:<div className="addNewCat" 
												data-id={option.id} 
												data-name={option.name}
												onClick={addItem}
											>
												<div className="iconBlk"
													data-id={option.id} 
													data-name={option.name}
													onClick={addItem}
												>
													<i className="fa fa-plus"
														data-id={option.id} 
														data-name={option.name}
														onClick={addItem}
													></i>
												</div>
												<div className="iconVal"
													data-id={option.id} 
													data-name={option.name}
													onClick={addItem}
												>Add Item</div>
											</div>
										} 
										<div className="addNewCat" 
											data-id={option.id} 
											data-name={option.name}
											onClick={addItem}
										>
											<div className="iconBlk"
												data-id={option.id} 
												data-name={option.name}
												onClick={addItem}
											>
												<i className="fa fa-plus"
													data-id={option.id} 
													data-name={option.name}
													onClick={addItem}
												></i>
											</div>
											<div className="iconVal"
												data-id={option.id} 
												data-name={option.name}
												onClick={addItem}
											>Add Item</div>
										</div>
									</div>
								</div>
                        	))}
						</>
						:
						<div className="emptyCatalog">
							<div className="addNewCat" onClick={addNewCat}>
								<div className="iconBlk">
									<i className="fa fa-plus"></i>
								</div>
								<div className="iconVal">Add Your First Category</div>
							</div>
						</div>
					}
				</div>
			</div>

		</div>
	</>);

}

export default connect(null, mredux.actions)(Menu);