import {
  //LOGIN_URL,
  ME_URL,
  //REGISTER_URL,
  REQUEST_PASSWORD_URL
} from "../_redux/authCrud";
import userTableMock from "./userTableMock";

export default function mockAuth(mock) {
  // mock.onPost(LOGIN_URL).reply(({ data }) => {
  //   const { email, password } = JSON.parse(data);

  //   if (email && password) {
  //     const user = userTableMock.find(
  //       x =>
  //         x.email.toLowerCase() === email.toLowerCase() &&
  //         x.password === password
  //         );

  //     if (user) {
  //       return [200, { ...user, password: undefined }];
  //     }
  //   }

  //   return [400];
  // });

  // mock.onPost(REGISTER_URL).reply(({ data }) => {
  //   const { email, fullname,  password } = JSON.parse(data);

  //   if (email && fullname && password) {
  //     const user = {
  //       id: generateUserId(),
  //       email,
  //       fullname,
  //       password,
  //       roles: [2], // Manager
  //       accessToken: "access-token-" + Math.random(),
  //       refreshToken: "access-token-" + Math.random(),
  //       pic: process.env.PUBLIC_URL + "/media/users/default.jpg"
  //     };

  //     userTableMock.push(user);

  //     return [200, { ...user, password: undefined }];
  //   }

  //   return [400];
  // });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        x => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const token =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (token) {
      const user = userTableMock.find(x => x.token === token);

      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [401];
  });
  
}
