import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest,call } from "redux-saga/effects";
import { 
  getAllCatelogs, 
  addCatalog, 
  updateCatalog, 
  deleteCatalog, 
  businessCatelog, 
  getBranchesCat,
  assignBranches,
  getAllCategories,
  addCategory,
  updateCategory,
  deleteCategory,
  getAllAddonsSet,
  addAddonsSet,
  updateAddonsSet,
  deleteAddonsSet,
  assignCatalogesAddons,
  getAllAddons,
  addAddons,
  updateAddons,
  deleteAddons,
  getAllVariations,
  addVariations,
  updateVariations,
  deleteVariations,
  assignCatalogVariations,
  getAllAttributesSet,
  addAttributesSet,
  updateAttributesSet,
  deleteAttributesSet,
  assignCatalogsAttributesSet,
  getAllAttributes,
  addAttribute,
  updateAttribute,
  deleteAttribute,
  getAllCatalogAssets,
  addItem,
  deleteItem,
  getItem,
  updateItem
} from "./mnetwork";

export const actionTypes = {
  GetAllCatelogs: "GET_CATELOGS",
  SetAllCatelogs: "SET_CATELOGS",
  AddCatalog: "ADD_CATALOG",
  UpdateCatalog: "UPDATE_CATALOG",
  UpdateCatalogName : "UPDATE_CATALOG_NAME",
  DeleteCatalog: "DELETE_CATALOG",
  UpdateDeleteCatalog: "UPDATE_DELETE_CATALOG",
  BusinessCatelog: "BUSINESS_CATALOG",
  SetBusinessCatalog: "SET_BUSINESS_CATALOG",
  GetBranchesCat: "GET_BRANCHES_CATALOG",
  SetBranchesCat: "GET_BRANCHES_CATALOG",
  AssignBranches: "ASSIGN_BRANCHES",
  GetAllCategories: "GET_ALL_CATEGORIES",
  SetAllCategories: "SET_ALL_CATEGORIES",
  AddCategory: "ADD_CATEGORY",
  UpdateCategory : "UPDATE_CATEGORY",
  DeleteCategory: "DELETE_CATEGORY",
  GetAllAddonsSet: "GET_ALL_ADDONS_SET",
  SetAllAddonsSet: "SET_ALL_ADDONS_SET",
  AddAddonsSet: "ADD_ADDON_SET",
  UpdateAddonsSet: "UPDATE_ADDON_SET",
  DeleteAddonsSet: "DELETE_ADDON_SET",
  AssignCatalogesAddons: "ASSIGN_CATALOGS_ADDONS",
  GetAllAddons: "GET_ALL_ADDONS",
  SetAllAddons: "SET_ALL_ADDONS",
  AddAddons: "ADD_ADDONS",
  UpdateAddons: "UPDATE_ADDONS",
  DeleteAddons: "DELETE_ADDONS",
  GetAllVariations: "GET_ALL_VARIATIONS",
  SetAllVariations: "SET_ALL_VARIATIONS",
  AddVariations: "ADD_VARIATIONS",
  UpdateVariations: "UPDATE_VARIATIONS",
  DeleteVariations: "DELETE_VARIATION",
  AssignCatalogVariations : "ASSIGN_CATALOGS_VARIANTS",
  GetAllAttributesSet: "GET_ALL_ATTRIBUTES_SET",
  SetAllAttributesSet: "SET_ALL_ATTRIBUTES_SET",
  AddAttributesSet : "ADD_ARRTIBUTE_SET",
  UpdateAttributesSet : "UPDATE_ARRTIBUTE_SET",
  DeleteAttributesSet: "DELETE_ATTRIBUTE_SET",
  AssignCatalogsAttributesSet: "ASSIGN_CATALOGS_ATTRIBUTES_SET",
  GetAllAttributes: "GET_ALL_ATTRIBUTES",
  SetAllAttributes: "SET_ALL_ATTRIBUTES",
  AddAttribute : "ADD_ATTRIBUTE",
  UpdateAttribute: "UPDATE_ATTRIBUTE",
  DeleteAttribute : "DELETE_ATTRIBUTE",
  GetAllCatalogAssets: "GET_ALL_CATALOG_ASSETS",
  SetAllCatalogAssets: "SET_ALL_CATALOG_ASSETS",
  AddItem: "ADD_ITEM",
  DeleteItem: "DELETE_ITEM",
  GetItem: "GET_ITEM",
  SetItem: "SET_ITEM",
  UpdateItem : "UPDATE_ITEM"
};

  
const initialState = {
    all_catelogs:[],
    cat_businesses: [],
    cat_branches: [],
    all_categories: [],
    all_addons_set:[],
    all_addons:[],
    all_variations:[],
    all_attributes_set: [],
    all_attributes:[],
    all_catalog_assets: [],
    get_item: []
  };


  export const mreducer = persistReducer(
    { storage, key: "QR-Buzz-[menu]", whitelist: ["user", "authToken"] },
    (state = initialState, action) => {
      switch (action.type) {

        case actionTypes.SetAllCatelogs: { 
          //console.log(action.payload);
          const catelogs = action.payload;
          return { ...state, all_catelogs:catelogs  };
        }

        case actionTypes.SetAllCategories: { 
          //console.log(action.payload);
          const categories = action.payload;
          return { ...state, all_categories:categories  };
        }

        case actionTypes.SetAllAddonsSet: { 
          //console.log(action.payload);
          const addons = action.payload;
          return { ...state, all_addons_set:addons  };
        }

        case actionTypes.SetAllAddons: { 
          //console.log(action.payload);
          const addons = action.payload;
          return { ...state, all_addons:addons  };
        }

        case actionTypes.SetAllVariations: { 
          //console.log(action.payload);
          const variations = action.payload;
          return { ...state, all_variations:variations  };
        }

        case actionTypes.SetAllAttributesSet: { 
          //console.log(action.payload);
          const attributes_set = action.payload;
          return { ...state, all_attributes_set:attributes_set  };
        }

        case actionTypes.SetAllAttributes: { 
          //console.log(action.payload);
          const attributes = action.payload;
          return { ...state, all_attributes:attributes  };
        }

        case actionTypes.SetBusinessCatalog: { 
          //console.log(action.payload);
          const { businesses } = action.payload;
          return { ...state, cat_businesses:businesses  };
        }

        case actionTypes.SetBranchesCat: {
          const { branches } = action.payload;
          //console.log(branches)
          return { ...state, cat_branches:branches  };
        }

        case actionTypes.SetAllCatalogAssets: {
          const  cat_assets  = action.payload;
          //console.log(cat_assets)
          return { ...state, all_catalog_assets:cat_assets  };
        }

        case actionTypes.SetItem: {
          const  item  = action.payload;
          //console.log(item)
          return { ...state, get_item:item  };
        }

        case actionTypes.UpdateCatalogName: {
          // console.log(action.payload)
          // const {action}  = action.payload;
          // var catelogs = [...state.all_catelogs];
          // console.log(action);
          // console.log(catelogs);
          //var index = catelogs.findIndex((item)=> item.id  === action.id);
          //console.log(index);
          //subscribers[index].name = action.name
          //return {...state, all_catelogs:subscribers}
        }

        case actionTypes.UpdateDeleteCatalog: {
          // console.log(action.payload)
          // const {action}  = action.payload;
          // var catelogs = [...state.all_catelogs];
          // console.log(action);
          // console.log(catelogs);
          //var index = catelogs.findIndex((item)=> item.id  === action.id);
          //console.log(index);
          //subscribers[index].name = action.name
          //return {...state, all_catelogs:subscribers}
        }

        default:
          return state;
      }
    }
  );
  


export const actions = {
    getAllCatelogs : (bid) => ({type: actionTypes.GetAllCatelogs, payload: bid}),
    setAllCatelogs : (categories) => ({type: actionTypes.SetAllCatelogs, payload: categories}),
    addCatalog: (user_id, name, description) => ({type: actionTypes.AddCatalog, payload:{user_id, name, description}}),
    updateCatalog: (user_id, id, name, description) => ({type: actionTypes.UpdateCatalog, payload:{user_id, id, name, description}}),
    updateCatalogName: (sub_id) => ({type: actionTypes.UpdateCatalogName, payload: {sub_id}}),
    deleteCatalog: (id) => ({type: actionTypes.DeleteCatalog, payload:{id}}),
    updateDeleteCatalog: (id) => ({type: actionTypes.UpdateDeleteCatalog, payload: id}),
    businessCatelog : (user_id) => ({type: actionTypes.BusinessCatelog, payload:user_id}),
    setBusinessCatalog : (businesses) => ({type: actionTypes.SetBusinessCatalog, payload: { businesses}}),
    GetBranchesCat : (bs_id) => ({type: actionTypes.GetBranchesCat, payload:bs_id}),
    setBranchesCat : (branches) => ({type: actionTypes.SetBranchesCat, payload: {branches}}),
    assignBranches : (id, business_id, branches) => ({type: actionTypes.AssignBranches, payload:{id, business_id, branches}}),
    getAllCategories : (id) => ({type: actionTypes.GetAllCategories, payload: id}),
    setAllCategories : (categories) => ({type: actionTypes.SetAllCategories, payload: categories}),
    addCategory: (catalog_id, name) => ({type: actionTypes.AddCategory, payload:{catalog_id, name}}),
    updateCategory: (catalog_id, name, id, business_id) => ({type: actionTypes.UpdateCategory, payload:{catalog_id, name, id, business_id}}),
    deleteCategory: (id) => ({type: actionTypes.DeleteCategory, payload:{id}}),
    getAllAddonsSet : (user_id) => ({type: actionTypes.GetAllAddonsSet, payload: user_id}),
    setAllAddonsSet : (addons) => ({type: actionTypes.SetAllAddonsSet, payload: addons}),
    getAllAddons : (id) => ({type: actionTypes.GetAllAddons, payload: id}),
    setAllAddons : (addons) => ({type: actionTypes.SetAllAddons, payload: addons}),
    addAddonsSet: (user_id, name, type) => ({type: actionTypes.AddAddonsSet, payload:{user_id, name, type}}),
    updateAddonsSet: (id, name, type) => ({type: actionTypes.UpdateAddonsSet, payload:{id, name, type}}),
    deleteAddonsSet: (id) => ({type: actionTypes.DeleteAddonsSet, payload:{id}}),
    assignCatalogesAddons : (id, catalogs) => ({type: actionTypes.AssignCatalogesAddons, payload:{id, catalogs}}),
    addAddons: (id, name, price, cost_price) => ({type: actionTypes.AddAddons, payload:{id, name, price, cost_price}}),
    updateAddons: (addon_set_id, id, name, price, cost_price) => ({type: actionTypes.UpdateAddons, payload:{addon_set_id, id, name, price, cost_price}}),
    deleteAddons: (id) => ({type: actionTypes.DeleteAddons, payload:{id}}),
    getAllVariations : (id) => ({type: actionTypes.GetAllVariations, payload: id}),
    setAllVariations : (variations) => ({type: actionTypes.SetAllVariations, payload: variations}),
    addVariations: (user_id, name, parameters) => ({type: actionTypes.AddVariations, payload:{user_id, name, parameters}}),
    updateVariations: (id, name, parameters) => ({type: actionTypes.UpdateVariations, payload:{id, name, parameters}}),
    deleteVariations: (id) => ({type: actionTypes.DeleteVariations, payload:{id}}),
    assignCatalogVariations : (id, catalogs) => ({type: actionTypes.AssignCatalogVariations, payload:{id, catalogs}}),
    getAllAttributesSet : (user_id) => ({type: actionTypes.GetAllAttributesSet, payload: user_id}),
    setAllAttributesSet : (attributes_set) => ({type: actionTypes.SetAllAttributesSet, payload: attributes_set}),
    addAttributesSet: (user_id, name, type, min, max) => ({type: actionTypes.AddAttributesSet, payload:{user_id, name, type, min, max}}),
    updateAttributesSet: (id, name, type, min, max) => ({type: actionTypes.UpdateAttributesSet, payload:{id, name, type, min, max}}),
    deleteAttributesSet: (id) => ({type: actionTypes.DeleteAttributesSet, payload:{id}}),
    assignCatalogsAttributesSet : (id, catalogs) => ({type: actionTypes.AssignCatalogsAttributesSet, payload:{id, catalogs}}),
    getAllAttributes : (id) => ({type: actionTypes.GetAllAttributes, payload: id}),
    setAllAttributes : (attributes) => ({type: actionTypes.SetAllAttributes, payload: attributes}),
    addAttribute : (id, name) => ({type: actionTypes.AddAttribute, payload:{id, name}}),
    updateAttribute : (aid, id, name) => ({type: actionTypes.UpdateAttribute, payload:{aid, id, name}}),
    deleteAttribute: (id) => ({type: actionTypes.DeleteAttribute, payload:{id}}),
    getAllCatalogAssets : (id) => ({type: actionTypes.GetAllCatalogAssets, payload: id}),
    setAllCatalogAssets : (cat_assets) => ({type: actionTypes.SetAllCatalogAssets, payload: cat_assets}),
    addItem: payload => ({ type: actionTypes.AddItem, payload:payload }),
    deleteItem: (id) => ({type: actionTypes.DeleteItem, payload:{id}}),
    getItem : (id) => ({type: actionTypes.GetItem, payload: id}),
    setItem : (item) => ({type: actionTypes.SetItem, payload: item}),
    updateItem: payload => ({ type: actionTypes.UpdateItem, payload:payload }),
}


export function* mSaga(){

    yield takeLatest(actionTypes.GetAllCatelogs,  function* getAllCatelogsSaga(action) {
      //console.log(action.payload)
      try {
          const catalogs = yield call(getAllCatelogs,action.payload)
          //console.log(catalogs)
          yield put(actions.setAllCatelogs(catalogs.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddCatalog,  function* addCatalogSaga(action) {
      //console.log(action.payload)
      try {
          const add_catalog = yield call(addCatalog,action.payload)
          //console.log(add_catalog)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateCatalog,  function* updateCatalogSaga(action) {
      //console.log(action.payload)
      try {
          const add_catalog = yield call(updateCatalog,action.payload)
          //console.log(add_catalog)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteCatalog,  function* deleteCatalogSaga(action) {
      //console.log(action.payload)
      try {
          const del_catalog = yield call(deleteCatalog,action.payload)
          //console.log(del_catalog)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.BusinessCatelog,  function* businessCatelogSaga(action) {
      //console.log(action.payload)
      try {
          const cat_businesses = yield call(businessCatelog,action.payload)
          //console.log(cat_businesses)
          yield put(actions.setBusinessCatalog(cat_businesses.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetBranchesCat,  function* getBranchesCatSaga(action) {
      //console.log(action.payload)
      try {
          const branches = yield call(getBranchesCat,action.payload)
          //console.log(branches.body)
          //yield put(actions.setBranchesCat(branches.body))
          if(branches.status == 200) {
            yield put(actions.setBranchesCat(branches.body))
          }
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AssignBranches,  function* assignBranchesSaga(action) {
      console.log(action.payload)
      try {
          const assign_branches = yield call(assignBranches,action.payload)
          console.log(assign_branches)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllCategories,  function* getAllCategoriesSaga(action) {
      //console.log(action.payload)
      try {
          const categories = yield call(getAllCategories,action.payload)
          //console.log(categories)
          yield put(actions.setAllCategories(categories.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddCategory,  function* addCategorySaga(action) {
      //console.log(action.payload)
      try {
          const add_catagory = yield call(addCategory,action.payload)
          //console.log(add_catagory)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateCategory,  function* updateCategorySaga(action) {
      //console.log(action.payload)
      try {
          const update_catagory = yield call(updateCategory,action.payload)
          //console.log(update_catagory)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteCategory,  function* deleteCategorySaga(action) {
      //console.log(action.payload)
      try {
          const del_category = yield call(deleteCategory,action.payload)
          //console.log(del_category)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllAddonsSet,  function* getAllAddonsSetSaga(action) {
      //console.log(action.payload)
      try {
          const addons = yield call(getAllAddonsSet,action.payload)
          //console.log(addons)
          yield put(actions.setAllAddonsSet(addons.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllAddons,  function* getAllAddonsSaga(action) {
      //console.log(action.payload)
      try {
          const addons = yield call(getAllAddons,action.payload)
          //console.log(addons)
          yield put(actions.setAllAddons(addons.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddAddonsSet,  function* addAddonsSetSaga(action) {
      //console.log(action.payload)
      try {
          const add_addons_set = yield call(addAddonsSet,action.payload)
          //console.log(add_addons_set)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateAddonsSet,  function* updateAddonsSetSaga(action) {
      //console.log(action.payload)
      try {
          const update_addons_set = yield call(updateAddonsSet,action.payload)
          //console.log(update_addons_set)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteAddonsSet,  function* deleteAddonsSetSaga(action) {
      //console.log(action.payload)
      try {
          const del_addons_set = yield call(deleteAddonsSet,action.payload)
          //console.log(del_addons_set)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AssignCatalogesAddons,  function* assignCatalogesAddonsSaga(action) {
      //console.log(action.payload)
      try {
          const assign_catalogs_addons = yield call(assignCatalogesAddons,action.payload)
          //console.log(assign_catalogs_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddAddons,  function* addAddonsSaga(action) {
      //console.log(action.payload)
      try {
          const add_addons = yield call(addAddons,action.payload)
          //console.log(add_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateAddons,  function* updateAddonsSaga(action) {
      //console.log(action.payload)
      try {
          const update_addons = yield call(updateAddons,action.payload)
          //console.log(update_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteAddons,  function* deleteAddonsSaga(action) {
      //console.log(action.payload)
      try {
          const del_addons = yield call(deleteAddons,action.payload)
          //console.log(del_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllVariations,  function* getAllVariationsSaga(action) {
      //console.log(action.payload)
      try {
          const variations = yield call(getAllVariations,action.payload)
          //console.log(variations)
          yield put(actions.setAllVariations(variations.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddVariations,  function* addVariationsSaga(action) {
      //console.log(action.payload)
      try {
          const add_variations = yield call(addVariations,action.payload)
          //console.log(add_variations)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateVariations,  function* updateVariationsSaga(action) {
      //console.log(action.payload)
      try {
          const update_variations = yield call(updateVariations,action.payload)
          //console.log(update_variations)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteVariations,  function* deleteVariationsSaga(action) {
      //console.log(action.payload)
      try {
          const del_addons = yield call(deleteVariations,action.payload)
          //console.log(del_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AssignCatalogVariations,  function* assignCatalogVariationsSaga(action) {
      //console.log(action.payload)
      try {
          const assign_catalogs_variants = yield call(assignCatalogVariations,action.payload)
          //console.log(assign_catalogs_variants)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllAttributesSet,  function* getAllAttributesSetSaga(action) {
      //console.log(action.payload)
      try {
          const attributes = yield call(getAllAttributesSet,action.payload)
          //console.log(attributes)
          yield put(actions.setAllAttributesSet(attributes.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddAttributesSet,  function* addAttributesSetSaga(action) {
      //console.log(action.payload)
      try {
          const add_attributes_set = yield call(addAttributesSet,action.payload)
         // console.log(add_attributes_set)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateAttributesSet,  function* updateAttributesSetSaga(action) {
      console.log(action.payload)
      try {
          const update_attributes_set = yield call(updateAttributesSet,action.payload)
          console.log(update_attributes_set)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteAttributesSet,  function* deleteAttributesSetSaga(action) {
      //console.log(action.payload)
      try {
          const del_addons = yield call(deleteAttributesSet,action.payload)
          //console.log(del_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AssignCatalogsAttributesSet,  function* assignCatalogsAttributesSetSaga(action) {
      //console.log(action.payload)
      try {
          const assign_catalogs_attributes = yield call(assignCatalogsAttributesSet,action.payload)
          //console.log(assign_catalogs_attributes)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllAttributes,  function* getAllAttributesSaga(action) {
      //console.log(action.payload)
      try {
          const catalogs = yield call(getAllAttributes,action.payload)
          //console.log(catalogs)
          yield put(actions.setAllAttributes(catalogs.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddAttribute,  function* addAttributeSaga(action) {
      //console.log(action.payload)
      try {
          const add_attributes = yield call(addAttribute,action.payload)
         // console.log(add_attributes)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.UpdateAttribute,  function* updateAttributeSaga(action) {
      //console.log(action.payload)
      try {
          const update_attributes = yield call(updateAttribute,action.payload)
         // console.log(add_attributes)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteAttribute,  function* deleteAttributeSaga(action) {
      //console.log(action.payload)
      try {
          const del_attribute = yield call(deleteAttribute,action.payload)
          //console.log(del_addons)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetAllCatalogAssets,  function* getAllCatalogAssetsSaga(action) {
      //console.log(action.payload)
      try {
          const cat_assets = yield call(getAllCatalogAssets,action.payload)
          //console.log(cat_assets)
          yield put(actions.setAllCatalogAssets(cat_assets.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.AddItem,  function* addItemSaga(action) {
      //console.log(action.payload)
      try {
          const add_item = yield call(addItem,action.payload)
          //console.log(add_item)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.DeleteItem,  function* deleteItemSaga(action) {
      //console.log(action.payload)
      try {
          const del_item = yield call(deleteItem,action.payload)
          //console.log(del_item)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });

    yield takeLatest(actionTypes.GetItem,  function* getItemSaga(action) {
      //console.log(action.payload)
      try {
          const item = yield call(getItem,action.payload)
          //console.log(item)
          yield put(actions.setItem(item.body))
      } catch (error) {
         //fire appropriate action in case of error 
      }
    });
    
    yield takeLatest(actionTypes.UpdateItem,  function* updateItemSaga(action) {
      console.log(action.payload)
      try {
          const update_item = yield call(updateItem,action.payload)
          console.log(update_item)
      } catch (error) {
          //fire appropriate action in case of error 
      }
    });
}