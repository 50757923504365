import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest,call } from "redux-saga/effects";
import { getSubBranches2, addStaff, getAllStaff, deleteStaff, checkStaff, updateStaffPass } from "./staff_network";
import 'react-toastify/dist/ReactToastify.css';

export const actionTypes = {
  GetSubBranches2: "GET_SUB_BRANCHES",
  SetSubBranches2: "SET_SUB_BRANCHES",
  AddStaff: "ADD_STAFF",
  GetAllStaff: "GET_ALL_STAFF",
  SetAllStaff: "SET_ALL_STAFF",
  DeleteStaff : "DELETE_STAFF",
  CheckStaff : "CHECK_STAFF",
  HasStaff: "HAS_STAFF",
  UpdateStaffPass : "UPDATE_STAFF_PASSWORD"
};

const initialState = {
  all_branches2:[],
  all_staff:[],
  staff_status: null,
  is_staff: null
};

export const staffreducer = persistReducer(
  { storage, key: "QR-Buzz-[Business]", whitelist: ["user", "authToken"] },
  (state = initialState, action) => {
    switch (action.type) {

      case actionTypes.SetSubBranches2: {
        const { branches } = action.payload;
        //console.log(branches)
        return { ...state, all_branches2:branches  };
      }

      case actionTypes.SetAllStaff: {
        const { staff } = action.payload;
        //console.log(staff)
        return { ...state, all_staff:staff  };
      }

      case actionTypes.HasStaff: {
        const { sstaff } = action.payload;
        //console.log(sstaff)
        return { ...state, is_staff:sstaff  };
      }
        
      case actionTypes.SetLoading:{ 
          return {...state, is_loading:!state.is_loading}
      }
      default:
        return state;
    }
  }
);

export const actions = {
  getSubBranches2: (user_id) => ({type: actionTypes.GetSubBranches2, payload:user_id}),
  setSubBranches2: (branches) => ({type: actionTypes.SetSubBranches2, payload:{branches}}),
  addStaff: (bid, mid, name, password) => ({type: actionTypes.AddStaff, payload:{bid, mid, name, password}}),
  getAllStaff: (user_id) => ({type: actionTypes.GetAllStaff, payload:user_id}),
  setAllStaff: (staff) => ({type: actionTypes.SetAllStaff, payload:{staff}}),
  deleteStaff: (id) => ({type: actionTypes.DeleteStaff, payload:id}),
  checkStaff: (username) => ({type: actionTypes.CheckStaff, payload:username}),
  hasStaff: (sstaff) => ({type: actionTypes.HasStaff, payload:{sstaff}}),
  updateStaffPass: (id, pass) => ({type: actionTypes.UpdateStaffPass, payload:{id, pass}}),
}

export function* stSaga(){

  yield takeLatest(actionTypes.GetSubBranches2,  function* getSubBranches2Saga(action) {
      //console.log(action.payload)
    try {
        const branches = yield call(getSubBranches2,action.payload)
        //console.log(branches.body)

        yield put(actions.setSubBranches2(branches.body))
    } catch (error) {
        //fire appropriate action in case of error 
    }
  });

  yield takeLatest(actionTypes.GetAllStaff,  function* getAllStaffSaga(action) {
    //console.log(action.payload)
  try {
      const staff = yield call(getAllStaff,action.payload)
      //console.log(staff)

      yield put(actions.setAllStaff(staff.body))
  } catch (error) {
      //fire appropriate action in case of error 
  }
});

  yield takeLatest(actionTypes.AddStaff,  function* addStaffSaga(action) {
    //console.log(action.payload)
    try {
        const add_staff = yield call(addStaff,action.payload)
        //console.log(add_staff)
    } catch (error) {
        //fire appropriate action in case of error 
    }
  });

  yield takeLatest(actionTypes.DeleteStaff,  function* deleteStaffSaga(action) {
    //console.log(action.payload)
    try {
        const del_staff = yield call(deleteStaff,action.payload)
        //console.log(del_staff)
    } catch (error) {
        //fire appropriate action in case of error 
    }
  });

  yield takeLatest(actionTypes.CheckStaff,  function* checkStaffSaga(action) {
    //console.log(action.payload)
    try {
        const check_staff = yield call(checkStaff,action.payload)
        //console.log(check_staff)
        yield put(actions.hasStaff(check_staff.body.status))
    } catch (error) {
        //fire appropriate action in case of error 
    }
  });

  yield takeLatest(actionTypes.UpdateStaffPass,  function* updateStaffPassSaga(action) {
    //console.log(action.payload)
    try {
        const update_staff = yield call(updateStaffPass,action.payload)
        //console.log(update_staff)
    } catch (error) {
        //fire appropriate action in case of error 
    }
  });

}