import React, {useMemo} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {connect,useSelector,useDispatch} from 'react-redux'

export function AsideMenu({disableScroll}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  const {user} = useSelector(state => state.auth);
  var moment = require('moment');

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} />
        <div className="heading">
				  <h3 className="uppercase">System Information</h3>
					<div className="license_attributes">
            <small>Registered To</small>
            <i className="fa fa-address-book"></i> 
            {user.name}
          </div>
					<div className="license_attributes">
            <small>Package</small>
            <i className="fa fa-layer-group"></i> 
            Package 1
          </div>
					<div className="license_attributes">
            <small>Registered</small>
            <i className="fa fa-calendar-alt"></i> 
           {moment.utc(user.created_at).format("LL")}
          </div>
					<div className="license_attributes">
            <small>Billing Cycle</small>
            <i className="fa fa-file-invoice-dollar"></i> 
            Free Account
          </div>
				</div>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
