import React, { Component, useState, useRef, useEffect } from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import BlockUI from '../Component/BlockUI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../_metronic/_assets/css/Builder.scss';
//import CustomVariables from '../Component/CustomVariables';
import {API_URL} from "../../Constants";
import {connect,useSelector,useDispatch} from 'react-redux';
import * as credux from './_redux/campRedux';
import TextField from '@material-ui/core/TextField';
var editor_ref;

function Builder(props) {

  const Cryptr = require('cryptr');
	const cryptr = new Cryptr('qrbuzz786');

  const history = useHistory();
  const location = useLocation();
  var empty = `<span></span>`
  var headStyle = `
  <style type="text/css">
  .ReadMsgBody { width: 100%; background-color: #ffffff; }
  .ExternalClass { width: 100%; background-color: #ffffff; }
  .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div { line-height: 100%; }
  html { width: 100%; }
  body { -webkit-text-size-adjust: none; -ms-text-size-adjust: none; margin: 0; padding: 0; font-family: 'Open Sans', Arial, Sans-serif !important; }
  table { border-spacing: 0; table-layout: fixed; margin: 0 auto; }
  table table table { table-layout: auto; }
  img { display: block !important; overflow: hidden !important; }
  .yshortcuts a { border-bottom: none !important; }
  img:hover { opacity: 0.9 !important; }
  a { color: #f95759; text-decoration: none; }
  .textbutton a { font-family: 'open sans', arial, sans-serif !important;}
  .btn-link a { color:#FFFFFF !important;}

  /*Responsive*/
  @media  (max-width: 640px) {
      body { margin: 0px; width: auto !important; font-family: 'Open Sans', Arial, Sans-serif !important;}
      .table-inner { width: 90% !important;  max-width: 90%!important;}
      .table-full { width: 100%!important; max-width: 100%!important; text-align: center !important;}
      .full-width { width: 100%!important; max-width: 100%!important;  display: block;}
      .full-width > .grid-item-image, .list-cell-left > img {
      width: 100%;
      }
  }

  @media  (max-width: 479px) {
      body { width: auto !important; font-family: 'Open Sans', Arial, Sans-serif !important;}
      .table-inner{ width: 90% !important; text-align: center !important;}
      .table-full { width: 100%!important; max-width: 100%!important; text-align: center !important;}
      /*gmail*/
      u + .body .full { width:100% !important; width:100vw !important;}
      .full-width { width: 100%!important; max-width: 100%!important;  display: block;}
      .full-width >.grid-item-image, .list-cell-left > img {
      width: 100%;
      }
  }

  ul.social-media-list img {
      padding: 5px;
      border-radius: 5px;
      background-color: lightblue;
      width: 36px;
      height: 36px;
  }
  ul.social-media-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 0px; /* eliminate space between li */
      display: inline-block;
  }
    ul.social-media-list li {
      display: inline-block;
  }
  .social-media-list a {
      display: inline-block;
      padding: 7px 7px;
  }
</style>  
  `;
  
  const globalState  = useSelector(state=>state.campaigns);
  const [camp_status, setCampStatus] = React.useState(null);
  const {user} = useSelector(state => state.auth);
  const [all_modules, setdata] = useState([]);


 

  function goBack() {
    history.push("/campaigns/new")
  }
  const [values, setValues] = React.useState({
      label: "",
      subject: ""
  });
const [b_files,setBFiles] = React.useState([])


  const [state, setState] = React.useState({
    label: "",
    subject: "",
    items:[],
    loader:true,
    time5: true
  });

  useEffect( ()=>{
    //console.log(globalState.template_modules)
    setdata(globalState.template_modules)
    setBFiles(globalState.builder_files)
  });
  
  useEffect(()=> {
    props.getTemplateModule(location.state.id)
    props.getBuilderFiles(user.id)

    setTimeout(function(){
    
      setState({...state, time5:false, loader:false});      
      
    },3000);
  },[])

  useEffect ( () => {
    if(b_files.length>0) {
      setBFiles(globalState.builder_files)
    }

    if(all_modules.length> 0){
      updateBlocks()

    }
  
  },[all_modules,b_files])





  const handleChange = name => event => {
      setState({ ...state, [name]: event.target.value, });
      setValues({ ...values, [name]: event.target.value });
  };

  const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    }
  }));
  const classes = useStyles();

  var updateBlocks = (editor,manager) =>  {

    //editor.DomComponents.clear();

    if(state.time5 == true) {
        //editor.setComponents(empty);
    }

    var editor =   grapesjs.init({
      container : '#apnagrape',
      fromElement: 1,
      noticeOnUnload: false,
      plugins: ['gjs-preset-newsletter'],
      assetManager: {
          assets: b_files,
          upload: API_URL + 'api/broadcasts/upload_files',
          uploadName: 'files', // The name used in POST to pass uploaded files
          uploadFile: function(e) {
            setState({...state, loader:true});
            var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
            // ...send somewhere
        
            var formData = new FormData();
            formData.append("user_id" , user.id);
          
            for(var i in files){
                formData.append('files', files[i])
            }

          
           fetch(API_URL + "api/broadcasts/upload_files", {
              method: "POST",
              contentType:false,
              crossDomain: true,
              mimeType: "multipart/form-data",
              processData:false,
              body: formData
            }).then(r =>
                r.json().then(data => {
                  console.log(data)
                  setState({...state, loader:false});
                  var images = data.data; // <- should be an array of uploaded images
                  editor.AssetManager.add(images);
                })
            ).catch(e => console.log(e));
          
            
          },
          params: {_token: '' },
          header: {},
          multiUpload: false,
          autoAdd: 0,
          modalTitle: 'Select Image4',
        }
        // storageManager: {
        //   id: 'gjs-',             // Prefix identifier that will be used inside storing and loading
        //   type: 'local',          // Type of the storage
        //   autosave: false,         // Store data automatically
        //   autoload: false,         // Autoload stored data on init
        //   stepsBeforeSave: 3,     // If autosave enabled, indicates how many changes are necessary before store method is triggered
        //   storeComponents: 0,  // Enable/Disable storing of components in JSON format
        //   storeStyles: 0,      // Enable/Disable storing of rules in JSON format
        //   storeHtml: 0,        // Enable/Disable storing of components as HTML string
        //   storeCss: 0,         // Enable/Disable storing of rules as CSS string,
        // }
   
  });

  editor_ref = editor



var blockManager  = editor.BlockManager;
    var blocksV = blockManager.getAll()

    //console.log(blocksV);

  const filtered = blocksV.filter(block => block.get('category') == '')
  var ids = filtered.map((item)=>item.attributes.id)

  // const bm = manager


  blockManager.render([
    ids.forEach(element => {
      blockManager.get(element).set("category", "Block")
    })
  ])
  
  blockManager.getCategories().each(ctg => console.log('here is the category context:  ',ctg))
  blockManager.getCategories().each(ctg => ctg.set('open', false))


    all_modules.map((option, index) => {
      
      blockManager.add(index, {
        label: `<label>${option.name}</label><img src="${option.thumbnail}"> `,
        content:option.html,
        category: { id:1,label:"Template",order:2,open: true},
        attributes: {
          title: option.name,
          class: "grapesjs-modules",
        }
      });
    });

    console.log(all_modules)


    var pnm = editor.Panels;
    editor.DomComponents.clear();
    pnm.addButton('options', [{
        id: 'undo',
        className: 'fa fa-undo',
        attributes: {title: 'Undo'},
        command: function(){ editor.runCommand('core:undo') }
      },{
        id: 'redo',
        className: 'fa fa-repeat',
        attributes: {title: 'Redo'},
        command: function(){ editor.runCommand('core:redo') }
      },{
        id: 'clear-all',
        className: 'fa fa-trash icon-blank',
        attributes: {title: 'Clear canvas'},
        command: {
          run: function(editor, sender) {
            sender && sender.set && sender.set('active', false)
              editor.DomComponents.clear();
              setTimeout(function(){
                localStorage.clear()
              },0)
          }
        }
      }

      ]);

  }

  
  const SaveEditor = () => {
    setState({...state, loader:true});

    var html = editor_ref.getHtml();
    var css = editor_ref.getCss();
    var b_html = "<html><head><title></title><style>"+`${css}`+"</style>"+`${headStyle}`+"</head><body>"+`${html}</body></html>`;
    var b_name = values.label;
    var b_subject = state.subject;
    var b_text = html.replace(/<[^>]+>/g, '');
    var user_id = user.id;
    //console.log(b_html , "----------------"+user_id,"----------------"+b_name, "----------------"+b_subject, "----------------"+b_text)

    if(b_name == "") {
      toast.error("Please Enter Campaign Name.", {position: "top-right",autoClose: 2000});
      setState({...state, loader:false});
    } else if (b_subject = "") {
      toast.error("Please Enter Email Subject.", {position: "top-right",autoClose: 2000});
      setState({...state, loader:false});
    } else if (html == "<span></span>") {
      toast.error("Please Add Template Block in Editor", {position: "top-right",autoClose: 2000});
      setState({...state, loader:false});
    } else {
      
      props.addCampaign({
        user_id:user.id,
        name: b_name,
        subject: state.subject,
        content_html: cryptr.encrypt(b_html),
        content_text: cryptr.encrypt(b_text),
        tid:location.state.id,
        is_campaign_builder:1
      });

      setTimeout(function(){
        setState({...state, loader:false});
        toast.success("Campaign has been saved", {position: "top-right",autoClose: 2000});
        setTimeout(function(){
          history.push("/campaigns/all");
        },2000);
      },2000);
    } 
  }
  
  return (<>
    <div className="row page-builder">

      <div className="col-md-12">
        <div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
          <div className="alert-text">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
            </span> 
            <span>
              <FormattedMessage id="PAGE.BUILDER.DESC" />
            </span>
            <span className="svg-icon menu-icon goBack" onClick={goBack}>
              <i className="fa fa-long-arrow-alt-left"></i>
            </span>
          </div>
        </div>
      </div>
          
      <div className="col-md-12">
        <div className="bldrButtonBlk">
          <Button variant="contained" className={classes.button} onClick={goBack}><FormattedMessage id="CANCEL" /></Button>
          <Button variant="contained" color="primary" className={classes.button} onClick={SaveEditor}><FormattedMessage id="SAVE" /></Button>
          {/* <CustomVariables /> */}
        </div>
        <div className="card card-custom gutter-b col-md-12 innerBlks">
          <div className="card-body">
            <div className="row ">
              <div className="col-md-6">
                <TextField
                    name="label"
                    type="text"
                    label="Broadcast Name"
                    variant="outlined"
                    className={`form-control h-auto py-0 px-0`}
                    onChange={handleChange("label")}
                    value={values.label}
                />
                <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                  <FormattedMessage id="PAGE.ADDBUILDER.NAME.HELP" />
                </p>
              </div>
              <div className="col-md-6">
                <TextField
                    name="subject"
                    type="text"
                    label="Email Subject"
                    variant="outlined"
                    className={`form-control h-auto py-0 px-0`}
                    onChange={handleChange("subject")}
                    value={values.subject}
                />
                <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                  <FormattedMessage id="PAGE.ADDBUILDER.SUBJECT.HELP" />
                </p>
              </div>
            </div>
          </div>
        </div>


        <div id="apnagrape"></div>
        <div className="bldrButtonBlk">
          <Button variant="contained" className={classes.button} onClick={goBack}><FormattedMessage id="CANCEL" /></Button>
          <Button variant="contained" color="primary" className={classes.button} onClick={SaveEditor}><FormattedMessage id="SAVE" /></Button>
        </div>
      </div>

      {
        state.loader == true?
        <BlockUI />
        : <div></div>
      } 
      <ToastContainer />
    </div>
  </>);

}

export default connect(null, credux.actions)(Builder);