import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";

import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TimeStamp } from "../../utility/TimeStamp";
import {connect,useSelector} from 'react-redux';
import * as stredux from './_redux/staffRedux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUI from '../Component/BlockUI';
import Button from '@material-ui/core/Button';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

function createData(bgcolor, name, email, location, branch, sub_created, action) {
  return { bgcolor, name, email, location, branch, sub_created, action };
}

const rows = [
  createData('bg-info', 'Wasif Ahmed', 'w*****d@g****l.com', 'Lahore', "Mall Road Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-success', 'Shahbaz Mughal', 's*****l@g****l.com', 'Lahore', "DHS Y Block Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-brand', 'Imjad Haider', 'i*****r@y****o.com', 'Islamabad', "Model Town Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-danger', 'M Naeem', 'n*****d@h****l.com', 'Karachi', "MM ALAM Road Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-warning', 'Usman Ali', 'u*****i@o****k.com', 'Faisalabad', "Johar Town Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-dark', 'Riaz Anwer', 'r*****@g****l.com', 'Lahore', "Wapda Town Lahore", "2020-12-08T14:20:49.000Z", "Action"),
   createData('bg-info', 'M Wakeel', 'm*****l@g****l.com', 'Lahore', "Mall Road Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-success', 'Sajid Sohail', 's*****l@g****l.com', 'Lahore', "DHS Y Block Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-brand', 'Maida Shahid', 'm*****s@y****o.com', 'Islamabad', "Model Town Lahore", "2020-12-08T14:20:49.000Z", "Action"),
  createData('bg-danger', 'M Aslam', 'm*****m@h****l.com', 'Karachi', "MM ALAM Road Lahore", "2020-12-08T14:20:49.000Z", "Action"),
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Staff Name' },
  { id: 'branch_name', numeric: false, disablePadding: true, label: 'Assigned Branch' },
  { id: 'sub_created', numeric: true, disablePadding: false, label: 'Created On' },
  { id: 'action', numeric: true, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Staff(props) {

  var moment = require('moment');

	const history = useHistory();
  function goBack() {
      history.goBack();
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [loading, setLoading] = useState(false);

	  const classes = useStyles();
	  const [order, setOrder] = React.useState('asc');
	  const [orderBy, setOrderBy] = React.useState('index');
	  const [selected, setSelected] = React.useState([]);
	  const [page, setPage] = React.useState(0);
	  const [rowsPerPage, setRowsPerPage] = React.useState(5);

	  function handleRequestSort(event, property) {
	    const isDesc = orderBy === property && order === 'desc';
	    setOrder(isDesc ? 'asc' : 'desc');
	    setOrderBy(property);
	  }

	  function handleSelectAllClick(event) {
	    if (event.target.checked) {
	      const newSelecteds = rows.map(n => n.name);
	      setSelected(newSelecteds);
	      return;
	    }
	    setSelected([]);
	  }

	  function handleChangePage(event, newPage) {
	    setPage(newPage);
	  }

	  function handleChangeRowsPerPage(event) {
	    setRowsPerPage(+event.target.value);
	  }

	  const isSelected = name => selected.indexOf(name) !== -1;

	  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [values, setValues] = React.useState({
    name:'',
    password: '',
    username: '',
    newpassword: ""
  });

  const [state, setState] = React.useState({
    name:'',
    password: '',
    newpassword: "",
    loader:true,
    modalBG:"hide",
    modalBG2:"hide",
    valid_name: "",
    valid_pass:"",
    valid_branch: "",
    nameMsg: "hide",
    passMsg: "hide",
    branchMsg: "hide",
    valid_pass2: "",
    passMsg2: "hide",
    checkstaff: null,
    editID: null,
    editName:null,
    newPass: null,
    catSave:1
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const handleChange = name => event => {
    //console.log(event.target.value)
    setState({ ...state, [name]: event.target.value, valid_name:"", nameMsg:"hide" });
    setValues({ ...values, [name]: event.target.value });
  };

  const {user} = useSelector(state => state.auth);
  const globalState  = useSelector(state=>state.staff)
  const [allbranches, setbranch] = useState([]);
  const [allstaff, setAllStaff] = useState([]);
  const [isStaff, setIsStaff] = useState([]);

  var user_id = user.id;

  useEffect( ()=>{
    setbranch(globalState.all_branches2)
    setAllStaff(globalState.all_staff)
    setIsStaff(globalState.is_staff)
  });

  useEffect( ()=>{
    props.getSubBranches2(user_id);
    props.getAllStaff(user_id);
    setTimeout(function(){
			setState({...state,loader:false});      
		},1000);
  } , []);

  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  const initialValues = {   
  };

  const StaffSchema = Yup.object().shape({
      // name: Yup.string()
      //   .min(4, "Minimum 4 characters require")
      //   .max(20, "Maximum 20 characters require")
      //   .required("Enter a username"),
      // password: Yup.string()
      //   .min(6, "Minimum 6 characters require")
      //   .max(12, "Maximum 12 characters require")
      //   .required("Enter a password"),
      // branch: Yup.string()
      //   .required("Select a branch"), 
  });

  const formik = useFormik({
    initialValues,
    validationSchema: StaffSchema,
    onSubmit: (values, { setSubmitting, setStatus }) => {
      //console.log( user.id, values.name, values.password, values.branch)
      console.log( user.id, state.name, state.password, state.branch)

      if(state.branch === "" || state.branch === undefined) {
          setState({...state,  valid_name:"is-valid", nameMsg: "hide", valid_branch:"is-invalid", branchMsg: "show"});
          return false;
      } else if(state.password === "") {
          setState({...state, valid_pass: "is-invalid", passMsg:"show", valid_name:"is-valid", nameMsg: "hide", valid_branch:"is-valid", branchMsg: "hide"});
      } else {
          setState({...state, valid_pass: "is-valid", passMsg:"hide", valid_name:"is-valid", nameMsg: "hide", valid_branch:"is-valid", branchMsg: "hide"});
          
          if(state.catSave == 1) {
            props.addStaff(Number(state.branch), Number(user_id), state.name, state.password)
            setTimeout(() => {
                swal({
                    title: "Staff Added",
                    text: values.name+" saved successfully!",
                    icon: "success",
                    button: null,
                    timer: 1500
                });
                setTimeout(() => {
                  props.getAllStaff(user_id);
                }, 1000);
                console.log("Staff Added")
                setState({...state, name:"", password:"", branch:"" , catSave:1, modalBG: "hide"});
                setValues({...values, name: "", password:"", branch:""});
            }, 1000);
          } else {
              setTimeout(() => {
                  
                  swal({
                      title: "Staff Update",
                      text: values.name+" updated successfully!",
                      icon: "success",
                      button: null,
                      timer: 1500
                  });
                setTimeout(() => {
                  props.getAllStaff(user_id);
                }, 1000);
                setState({...state, name:"", password:"", branch:"" , catSave:1, modalBG: "hide"});
                setValues({...values, name: "", password:"", branch:""});
              }, 1000);
          }

          setState({...state,loading:true, name:"", password:"", branch:"" , catSave:1, modalBG: "hide"});
          setValues({...values, name: "", password:"", branch:""});
      }
    },
  });

  const addStaff =()=> {

  }

  const keyPress =(e)=> {
		if(e.keyCode == 13){
			console.log('value', e.target.value);
		 }
	}

  const deleteStaff =(e)=> {
    var id = e.target.dataset.id;
    var name = e.target.dataset.name;
    swal({
			title: "Are you sure?",
			text: "Staff will be lost!",
			icon: "error",
			buttons: true,
			dangerMode: true,
		})
		.then((willDelete) => {
			if (willDelete) {
        props.deleteStaff(Number(id))
				swal(name+" has been deleted!", {
					icon: "success",
				});
				setTimeout(() => { 
          swal.close(); 
          props.getAllStaff(user_id);
        }, 2000);
			} 
		});
  }

  const checkusername =(e)=> {
    //console.log(values.name, state.name)
    if(values.name == "") {
      setState({...state, valid_name:" is-invalid",  nameMsg: " show"});
    } else {
      setState({...state, valid_name:" is-valid",  nameMsg: " hide"});
      props.checkStaff(state.name);
      setState({...state, loader:true});
      setTimeout(() => {
        setState({...state, loader:false, checkstaff:isStaff});
    }, 1500);
    }
  }

  const edituser =(e)=> {
    var id = e.target.dataset.id;
    var name = e.target.dataset.name;
    console.log(id, name)
    setState({...state, editID:id, editName:name, modalBG2:"show"});
    setValues({...values, username:name});
  }

  const updatePass =()=> {
    console.log(state.editID , state.newpassword)
    if(state.newpassword == "") {
      setState({...state, valid_pass2: "is-invalid", passMsg2: "show"});
    } else {
      setState({...state, valid_pass2: "is-valid", passMsg2: "hide", loader: true});
      props.updateStaffPass(Number(state.editID), values.newpassword)
      setTimeout(() => {
        setState({...state, newpassword: "", valid_pass2: "is-valid", passMsg2: "hide", modalBG2: "hide", loader: false});
        setValues({...values, newpassword: ""});
        swal({
          title: "Staff Password Update",
          text: values.name+" Staff password updated successfully!",
          icon: "success",
          button: null,
          timer: 1500
        });
      }, 1000);
    }
  }

	return (<>
		<div className="row">

    	<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
            	<SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
          	</span> 
  					<span>
  						<FormattedMessage id="PAGE.STAFF.DESC" />
  					</span>
            <span className="svg-icon menu-icon goBack hide" onClick={goBack}>
              <i className="fa fa-long-arrow-alt-left"></i>
            </span>
				</div>
			</div>

      {
				state.loader == true?
				<BlockUI />
				: <div> </div>
			} 

      <div className={`fade modal-backdrop `+state.modalBG}></div>
      <div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG}>
        <div role="document" className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4" id="contained-modal-title-vcenter"><FormattedMessage id="PAGE.STAFF.WIDGET.ADDTITLE" /></div>
            </div>
            <div className="modal-body">
              <form 
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                {formik.status ? (
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
                ) : (
                <div>
                </div>
                )}

                <div className={`fieldBlk `+ state.valid_branch}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} htmlFor="branch">
                      <FormattedMessage id="PAGE.ALLSUBSCRIBERS.FILTER.TITLE" />
                    </InputLabel>
                    <Select
                      value={values.branch || ""}
                      MenuProps={MenuProps}
                      input={<OutlinedInput labelWidth={labelWidth} name="branch" id="branch" />}
                      //{...formik.getFieldProps("branch")}
                      onChange={handleChange("branch")}
                    >
                      {allbranches.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span className={`fv-help-block `+ state.branchMsg}>Branch Name Required</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.STAFF.BRANCH.HELP" />
                    </p>
                </div>

                <div className={`fieldBlk `+ state.valid_name}>
                    <TextField
                        name="name"
                        type="text"
                        label="Username"
                        variant="outlined"
                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
                            "name"
                        )}`}
                        inputProps={{style: {textTransform: 'lowercase'}}}
                        onKeyDown={keyPress}
                        onKeyPress={(ev) => {
                          if (ev.which === 32) {
                            ev.preventDefault();
                          }
                        }}
                        value={values.name}
                        //{...formik.getFieldProps("name")}
                        onChange={handleChange("name")}
                    />
                    {
                      isStaff == true ? <span className={`fv-help-block text-success `+ state.userSuccessMsg}>Username Available!</span>
                      : isStaff == false ? <span className={`fv-help-block `+ state.userErrorMsg}>Username Already Exist. Choose another!</span>
                      :<span></span>
                    }
                    <span className={`fv-help-block `+ state.nameMsg}>Staff User Required</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.STAFF.USERNAME.HELP" />
                        <span className="pull-right text-link text-info username-label" onClick={checkusername}>Check Username</span>
                    </p>
                </div>
                <div className={`fieldBlk `+ state.valid_pass}>
                    <TextField
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
                            "password"
                        )}`}
                        value={values.password}
                        //{...formik.getFieldProps("password")}
                        onChange={handleChange("password")}
                    />
                      {formik.touched.password && formik.errors.password ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.password}</div>
							            </div>
							          ) : null}
                    <span className={`fv-help-block hide `+ state.passMsg}>Staff Password Required</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.STAFF.PASSWORD.HELP" />
                    </p>
                </div>

                <div className="fieldBlk mt-0 mb-0 class-submit">
                  {
                    isStaff == true ? 
                    <Button 
                        type="submit"
                        variant="contained" 
                        color="primary" 
                        className={classes.button}
                    >
                        <FormattedMessage id="SAVE" />
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </Button>
                    : <></>
                  }
                    
                    <Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG: "hide", name: "" })}>
                        <FormattedMessage id="CANCEL" />
                    </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`fade modal-backdrop `+state.modalBG2}></div>
      <div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG2}>
        <div role="document" className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4" id="contained-modal-title-vcenter"><FormattedMessage id="PAGE.STAFF.WIDGET.EDITTITLE" /></div>
            </div>
            <div className="modal-body">
              <form 
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
              >
                {formik.status ? (
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
                ) : (
                <div>
                </div>
                )}

                <div className={`fieldBlk readonly `+ state.valid_username}>
                    <TextField
                        name="username"
                        type="text"
                        label="Username"
                        variant="outlined"
                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
                            "username"
                        )}`}
                        disabled
                        inputProps={{style: {textTransform: 'lowercase'}}}
                        onKeyDown={keyPress}
                        onKeyPress={(ev) => {
                          if (ev.which === 32) {
                            ev.preventDefault();
                          }
                        }}
                        value={values.username}
                        onChange={handleChange("username")}
                    />
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.STAFF.USERNAME.HELP" />
                    </p>
                </div>
                <div className={`fieldBlk `+ state.valid_pass2}>
                    <TextField
                        name="newpassword"
                        type="password"
                        label="New Password"
                        variant="outlined"
                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
                            "newpassword"
                        )}`}
                        value={values.newpassword}
                        onChange={handleChange("newpassword")}
                    />
                    <span className={`fv-help-block hide `+ state.passMsg2}>Staff New Password Required</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.STAFF.PASSWORD.HELP" />
                    </p>
                </div>

                <div className="fieldBlk mt-0 mb-0 class-submit">
                    <Button 
                        type="submit"
                        variant="contained" 
                        color="primary" 
                        className={classes.button}
                        onClick={updatePass}
                    >
                        <FormattedMessage id="SAVE" />
                    </Button>
                    <Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG2: "hide", username: "", newpassword:"" })}>
                        <FormattedMessage id="CANCEL" />
                    </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
			<div className={`subscribers-table ` + classes.root}>
        <Paper className={classes.paper}>
          <Toolbar >
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                <FormattedMessage id="PAGE.STAFF.WIDGET.TITLE" />
              </Typography>
            </div>
            <div className="table-filter branches-heading">
              <Button variant="contained" color="secondary" className={`pull-right `+classes.button} onClick={() => setState({...state, modalBG: "show", name:"", password:""})}>
                  <span>Add a Staff</span>
              </Button>
            </div>
          </Toolbar>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={allstaff ? allstaff.length :0}
              />
              {
                allstaff == undefined || allstaff.length < 1 ?
                <TableBody>
                  <TableRow>
                    <TableCell className="empty-cell" colSpan="4" align="center"><FormattedMessage id="PAGE.STAFF.TABLE.NOSTAFF" /></TableCell>
                  </TableRow>
                </TableBody>
                :<TableBody>
                  {stableSort(allstaff, getSorting(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((row, index) => {
												const isItemSelected = isSelected(row.name);
												const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell align="left" component="th" id={labelId} scope="row" padding="none">
                            <span className="user-card-block">
                              <div className="kt-user-card-v2">
                                <div className="kt-user-card-v2__pic">
                                  <div id="kt-badge" className={row.bgcolor}>
                                    {/* <img src={row.image} /> */}
                                    {row.username.slice(0, 1)}
                                  </div>
                                </div>
                                <div className="kt-user-card-v2__details"> 
                                  <div className="kt-user-card-v2__name">{row.username}</div> 
                                </div>
                              </div>
                            </span>
                          </TableCell>
                          <TableCell align="left">{row.branch_name}</TableCell>
                          <TableCell align="left">{moment.utc(row.created_at).format("LLL")}</TableCell>
                          <TableCell align="left">
                              <button className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3" onClick={edituser} data-id={row.id} data-name={row.username}>
																<i className="fa fa-edit text-primary" data-id={row.id} data-name={row.username}></i>
															</button>
                              <button className="btn btn-icon btn-light btn-hover-danger btn-sm" data-id={row.id} data-name={row.username} onClick={deleteStaff}>
																<i className="fa fa-trash text-danger" data-id={row.id} data-name={row.username}></i>
															</button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              }
            </Table>
          </div>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allstaff ? allstaff.length: 0}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
              'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
              'aria-label': 'Next Page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <ToastContainer />
		</div>
	</>);

}

export default connect(null, stredux.actions)(Staff);