//using fixed url for now. you should make a separte file for urls and env for keys
import {API_URL} from "../../../Constants";

// var api= 'https://reqres.in/api/users?page=2'
var getAllCatalogsAPI= API_URL + 'api/catalogs'
var addCatalogAPI= API_URL + 'api/catalogs/addCatalog'
var updateCatalogAPI= API_URL + 'api/catalogs/updateCatalog'
var deleteCatalogAPI= API_URL + 'api/catalogs/deleteCatalog'
var businessCatApi= API_URL + 'api/businesses'
var getBranchesCatApi= API_URL + 'api/branches'
var assignBranchesApi= API_URL + 'api/catalogs/assignBranchesCatalog'
var getAllCategoriesApi= API_URL + 'api/categories'
var addCategoryApi= API_URL + 'api/categories/addCategory'
var updateCategoryApi= API_URL + 'api/categories/updateCategory'
var deleteCategoryApi= API_URL + 'api/categories/deleteCategory'
var addAddonsSetApi= API_URL + 'api/addons/addSet'
var updateAddonsSetApi= API_URL + 'api/addons/updateSet'
var getAllAddonsSetApi= API_URL + 'api/addons/sets'
var deleteAddonsSetApi= API_URL + 'api/addons/deleteSet'
var assignCatalogesAddonsSetApi= API_URL + 'api/addons/assignCatalogs'
var getAllAddonsApi= API_URL + 'api/addons'
var addAddonsApi= API_URL + 'api/addons/addAddon'
var updateAddonsApi= API_URL + 'api/addons/updateAddon'
var deleteAddonsApi= API_URL + 'api/addons/deleteAddon'
var getAllVariationsApi= API_URL + 'api/variants'
var addVariationsApi= API_URL + 'api/variants/addVariant'
var updateVariationsApi= API_URL + 'api/variants/updateVariant'
var deleteVariationsApi= API_URL + 'api/variants/deleteVariant'
var assignCatalogVariationsApi= API_URL + 'api/variants/assignCatalogs'
var getAllAttributesSetApi= API_URL + 'api/attributes/sets'
var addAttributesSetApi= API_URL + 'api/attributes/addSet'
var updateAttributesSetApi= API_URL + 'api/attributes/updateSet'
var deleteAttributesSetApi= API_URL + 'api/attributes/deleteSet'
var assignCatalogsAttributesSetApi= API_URL + 'api/attributes/assignCatalogs'
var getAllAttributesApi= API_URL + 'api/attributes'
var addAttributeApi= API_URL + 'api/attributes/addAttribute'
var updateAttributeApi= API_URL + 'api/attributes/updateAttribute'
var deleteAttributeApi= API_URL + 'api/attributes/deleteAttribute'
var getAllCatalogAssetsApi= API_URL + 'api/catalogs/getCatalogAssets'
var addItemApi= API_URL + 'api/items/addItem'
var deleteItemApi= API_URL + 'api/items/deleteItem'
var getItemApi= API_URL + 'api/items/getItem'
var updateItemApi= API_URL + 'api/items/updateItem'

export async function getAllCatelogs(user_id) {
  //console.log(user_id)
  try {
    const response = await fetch(getAllCatalogsAPI,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:user_id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.catalogs})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addCatalog(payload) {
  console.log(payload)
  try {
    const response = await fetch(addCatalogAPI,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:payload.user_id, name:payload.name, description: payload.description})
    });  
    const data = await response.json()
    console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateCatalog(payload) {
  console.log(payload)
  try {
    const response = await fetch(updateCatalogAPI,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:payload.user_id, id: payload.id, name:payload.name, description: payload.description})
    });  
    const data = await response.json()
    console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteCatalog(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteCatalogAPI,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function businessCatelog(user_id) {
  //console.log(user_id)
  try {
    const response = await fetch(businessCatApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:user_id})
    });  
    const data = await response.json()
    //console.log(data);
    return ({status: response.status, body:data.businesses})
   
  } catch (error) {
    console.log(error)
  }

}

export async function getBranchesCat(bs_id) {
  //console.log(bs_id)
  try {
    const response = await fetch(getBranchesCatApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({business_id:bs_id})
    });  
    const data = await response.json()
    //console.log(data);
    return ({status: response.status, body:data.branches})
   
  } catch (error) {
    console.log(error)
  }

}

export async function assignBranches(payload) {
  console.log(payload)
  try {
    const response = await fetch(assignBranchesApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:payload.id, business_id: payload.business_id, branches: payload.branches})
    });  
    const data = await response.json()
    console.log(data);
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }

}

export async function getAllCategories(id) {
  //console.log(id)
  try {
    const response = await fetch(getAllCategoriesApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({catalog_id:id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.categories})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addCategory(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addCategoryApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({catalog_id:payload.catalog_id, name:payload.name})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateCategory(payload) {
  //console.log(payload)
  try {
    const response = await fetch(updateCategoryApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({catalog_id:payload.catalog_id, name:payload.name, id: payload.id, business_id: payload.business_id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteCategory(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteCategoryApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function getAllAddonsSet(user_id) {
  //console.log(user_id)
  try {
    const response = await fetch(getAllAddonsSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:user_id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.addon_sets})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addAddonsSet(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addAddonsSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:payload.user_id, name:payload.name, type: payload.type})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateAddonsSet(payload) {
  //console.log(payload)
  try {
    const response = await fetch(updateAddonsSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:payload.id, name:payload.name, type: payload.type})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteAddonsSet(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteAddonsSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function assignCatalogesAddons(payload) {
  //console.log(payload)
  try {
    const response = await fetch(assignCatalogesAddonsSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:payload.id, catalogs : payload.catalogs })
    });  
    const data = await response.json()
    //console.log(data);
    return ({status: response.status, body:data})
  } catch (error) {
    console.log(error)
  }
}

export async function getAllAddons(id) {
  console.log(id)
  try {
    const response = await fetch(getAllAddonsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({addon_set_id:id})
    });  
    const data = await response.json()
    console.log(data)
    return ({status: response.status, body:data.addons})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addAddons(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addAddonsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({addon_set_id:payload.id, name:payload.name, price: payload.price, cost_price: payload.cost_price})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateAddons(payload) {
  //console.log(payload)
  try {
    const response = await fetch(updateAddonsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({addon_set_id:payload.addon_set_id, id:payload.id, name:payload.name, price: payload.price, cost_price: payload.cost_price})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteAddons(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteAddonsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function getAllVariations(user_id) {
  //console.log(user_id)
  try {
    const response = await fetch(getAllVariationsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:user_id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.variants})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addVariations(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addVariationsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:payload.user_id, name:payload.name, parameters: payload.parameters})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateVariations(payload) {
  //console.log(payload)
  try {
    const response = await fetch(updateVariationsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:payload.id, name:payload.name, parameters: payload.parameters})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteVariations(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteVariationsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function assignCatalogVariations(payload) {
  //console.log(payload)
  try {
    const response = await fetch(assignCatalogVariationsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id :payload.id, catalogs : payload.catalogs })
    });  
    const data = await response.json()
    //console.log(data);
    return ({status: response.status, body:data})
  } catch (error) {
    console.log(error)
  }
}

export async function getAllAttributesSet(user_id) {
  //console.log(user_id)
  try {
    const response = await fetch(getAllAttributesSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:user_id})
    });  
    const data = await response.json()
   // console.log(data)
    return ({status: response.status, body:data.attribute_sets})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addAttributesSet(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addAttributesSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:payload.user_id, name:payload.name, type: payload.type, min_selection: payload.min, max_selection: payload.max})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateAttributesSet(payload) {
  //console.log(payload)
  try {
    const response = await fetch(updateAttributesSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:payload.id, name:payload.name, type: payload.type, min_selection: payload.min, max_selection: payload.max})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteAttributesSet(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteAttributesSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function assignCatalogsAttributesSet(payload) {
  //console.log(payload)
  try {
    const response = await fetch(assignCatalogsAttributesSetApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id :payload.id, catalogs : payload.catalogs })
    });  
    const data = await response.json()
    //console.log(data);
    return ({status: response.status, body:data})
  } catch (error) {
    console.log(error)
  }
}

export async function getAllAttributes(id) {
  //console.log(user_id)
  try {
    const response = await fetch(getAllAttributesApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({attribute_set_id:id})
    });  
    const data = await response.json()
   // console.log(data)
    return ({status: response.status, body:data.attribute})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addAttribute(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addAttributeApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({attribute_set_id:payload.id, name:payload.name})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateAttribute(payload) {
  //console.log(payload)
  try {
    const response = await fetch(updateAttributeApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({attribute_set_id:payload.aid, id:payload.id, name:payload.name})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteAttribute(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteAttributeApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function getAllCatalogAssets(id) {
  //console.log(id)
  try {
    const response = await fetch(getAllCatalogAssetsApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({catalog_id:id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addItem(payload) {

  var formData = new FormData();
  formData.append('user_id', payload.user_id);
  formData.append('category_id', payload.cat_id);
  formData.append('title', payload.name);
  formData.append('description', payload.description);
  formData.append('sale_price', payload.price);
  formData.append('cost_price', payload.cprice);
  formData.append('variant_ids', payload.variant_ids);
  formData.append('addon_ids', payload.addon);
  formData.append('attributes', payload.attributeset);
  formData.append('variants', payload.variants);
  formData.append('recommended', payload.recommended);
  formData.append('popular', payload.popular);
  formData.append('new', payload.new);
  formData.append('veg', payload.veg);
  formData.append('image', payload.image.file,payload.image.name);

  //console.log(formData)
  //console.log(payload)

  try {
    const response = await fetch(addItemApi,{
      method:"POST",
      // headers: {
      //   "Content-Type":"multipart/form-data"
      // },
      body: formData
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateItem(payload) {

  var avatarChange = payload.avatarFile;

  if(avatarChange === "") {
    var formData = new FormData();
    formData.append('id', payload.id);
    formData.append('title', payload.name);
    formData.append('user_id', payload.user_id);
    formData.append('category_id', payload.cat_id);
    formData.append('description', payload.description);
    formData.append('cost_price', payload.cprice);
    formData.append('sale_price', payload.price);
    formData.append('addon_ids', payload.addon);
    formData.append('attributes', payload.attributeset);
    formData.append('variant_ids', payload.variant_ids);
    formData.append('variants', payload.variants);
    formData.append('recommended', payload.recommended);
    formData.append('popular', payload.popular);
    formData.append('new', payload.new);
    formData.append('veg', payload.veg);
    formData.append('image', "");
  } else {
    var formData = new FormData();
    formData.append('id', payload.id);
    formData.append('title', payload.name);
    formData.append('user_id', payload.user_id);
    formData.append('category_id', payload.cat_id);
    formData.append('description', payload.description);
    formData.append('cost_price', payload.cprice);
    formData.append('sale_price', payload.price);
    formData.append('addon_ids', payload.addon);
    formData.append('attributes', payload.attributeset);
    formData.append('variant_ids', payload.variant_ids);
    formData.append('variants', payload.variants);
    formData.append('recommended', payload.recommended);
    formData.append('popular', payload.popular);
    formData.append('new', payload.new);
    formData.append('veg', payload.veg);
    formData.append('image', payload.image.file,payload.image.name);
  }

  

  //console.log(formData)
  console.log(payload)

  try {
    const response = await fetch(updateItemApi,{
      method:"POST",
      // headers: {
      //   "Content-Type":"multipart/form-data"
      // },
      body: formData
    });  
    const data = await response.json()
    console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteItem(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteItemApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify(id)
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function getItem(id) {
  //console.log(id)
  try {
    const response = await fetch(getItemApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.item[0]})
   
  } catch (error) {
    console.log(error)
  }
}