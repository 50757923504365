import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { useHistory, useLocation  } from "react-router-dom";
import {connect,useSelector} from 'react-redux';
import * as bredux from './_redux/businessRedux';


function SaveBusiness(props) {

	const history = useHistory();
	const location = useLocation();
	//console.log(location.state)
	const globalState  = useSelector(state=>state.business);
	function verifyBusiness() {
		history.push({
			pathname: '/business/verify',
			state: {
				id:globalState.saveBusiness,
				operating: location.state.operating, 
			}
		});
	}
	function goBack() {
		history.push("/business/all");
	}
	function closePage() {
		history.push("/business/all");
	}

	const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

	const [values, setValues] = React.useState({
	    name: '',
	    user:'',
	    showPassword: false,
		zone:'',
		b_id:'',
	    status:['active', 'inactive', 'disable'],
	});

	const [registration, setRegistration] = React.useState({
		value: "hide"
	});
	const [business, setdata] = useState([]);

	useEffect( ()=>{
		setdata(globalState.current_business)
		//console.log(business)
	});

	React.useEffect(() =>  {
		console.log('This is new business id: ',globalState.saveBusiness.business_id);
		props.getBusiness(globalState.saveBusiness.business_id);
		if(globalState.saveBusiness.business_id === undefined) {
			history.push("/business/all");
		}
		const rgnum = business ? business.registration_number : "";
		if(rgnum !== ""){
			setRegistration({value: "show"})
		} else {
			setRegistration({value: "hide"})
		}
	}, []);


	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  	</span> 
					<span>
						<FormattedMessage id="PAGE.BUSINESSSAVE.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

			<div className="card card-custom gutter-b col-md-12 buss-details">
				<div className="card-header">
					<div className="card-title">
						<h3 className="card-label"><FormattedMessage id="PAGE.SAVEBUSINESS.WISGET_TITLE" /></h3>
					</div>
					<Button variant="contained" className="Business-edit" onClick={closePage} > Close </Button>
				</div>
		        <div className="card-body">
		        	<div className="row">
		        		<div className="col-md-12">
			        		<div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
			        			<div className="alert-text font-weight-bold"><FormattedMessage id="PAGE.SAVEBUSINESS.ALERT.MESSAGE" /></div>
			        		</div>

		                    <div className="symbol  save-verify">
		                        <span className="symbol-label bg-white" onClick={verifyBusiness}>
		                          <span className="svg-icon svg-icon-lg svg-icon-info">
		                            <SVG className="h-75 align-self-end" src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} ></SVG>
		                          </span>
		                        </span>
		                        <div className="text-info verify-text" onClick={verifyBusiness}><FormattedMessage id="PAGE.SAVEBUSINESS.VERIFY.BUSINESS" /></div>
		                    </div>
		        		</div>
		        	</div>
		        	<div className="row mt-5">
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.BUSINESS.NAME" /></label>
		        			<div className="detail">{business.name}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.OPERATING_AS" /></label>
		        			<div className="detail">{business.operating_as}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.REGISTRATION_TYPE" /></label>
		        			<div className="detail">{business.type}</div>
		        		</div>
		        		
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.PHONE" /></label>
		        			<div className="detail">{business.phone_number}</div>
		        		</div>
		        		<div className={`col-md-6 ` + registration["value"]}>
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.REGISTRATION_NUMBER" /></label>
		        			<div className="detail">{business.registration_number}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.STATUS" /></label>
		        			<div className="detail">{business.status === 0 ? "Inactive" : "Active"}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.REGISTRATION_ON" /></label>
		        			<div className="detail">{business.register_on}</div>
		        		</div>
		        		<div className="col-md-6">
		        			<label htmlFor="name"><FormattedMessage id="PAGE.SAVEBUSINESS.ADDRESS" /></label>
		        			<div className="detail">{business.office_address}, {business.area} {business.city} {business.country_id}</div>
		        		</div>
		        	</div>
		        </div>
		    </div>  

		</div>
	</>);

}

export default connect(null, bredux.actions)(SaveBusiness);