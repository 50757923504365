import React, { useState, useEffect } from "react";
import { FormattedMessage} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { lighten, makeStyles, useTheme  } from '@material-ui/core/styles';

import BlockUI from '../Component/BlockUI';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import {connect,useSelector} from 'react-redux';
import * as mredux from './_redux/menuRedux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import {API_URL} from "../../Constants";
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Hidden } from "@material-ui/core";

const initialValues = {   
};

const variationsMain = [
  {
    id:1,
    name:"Drinks"
  },
  {
    id:2,
    name:"Meal Type"
  },
  {
    id:3,
    name:"Meal Size"
  }
];

const drink = [
  {
    id:11,
    name:"Coke"
  },
  {
    id:12,
    name:"Pepsi"
  },
  {
    id:13,
    name:"Marinda"
  }
];

const mealtype = [
  {
    id:21,
    name:"Chicken"
  },
  {
    id:22,
    name:"Beef"
  },
  {
    id:23,
    name:"Mutton"
  }
];

const mealsize = [
  {
    id:31,
    name:"Large"
  },
  {
    id:32,
    name:"Regular"
  },
  {
    id:33,
    name:"Small"
  }
];

const allVariations = [...drink, ...mealtype, ...mealsize];

const variationsData = [
  {
    id: 1,
    name: "Coke",
  },
  {
    id: 2,
    name: "Pepsi",
  },
  {
    id: 3,
    name: "Marinda",
  },
  {
    id: 4,
    name: "Team",
  }
];

const addons = [
  {
    id:1,
    name:"Cheddar Cheese"
  },
  {
    id:2,
    name:"Mayonnaise"
  },
  {
    id:3,
    name:"Coke"
  },
  {
    id:4,
    name:"Pepsi"
  },
  {
    id:5,
    name:"UpSize"
  },
  {
    id:null,
    name:null
  }
];

const attributes = [
  {
    id:1,
    name:"Cheese"
  },
  {
    id:2,
    name:"Drinks"
  },
  {
    id:3,
    name:"Meal Type"
  },
  {
    id:4,
    name:"Dining"
  },
  {
    id:5,
    name:"TakeAway"
  }
];

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddItem(props) {

	document.body.className = 'quick-panel-right demo-panel-right offcanvas-right header-static subheader-enabled aside-enabled aside-fixed aside-minimize-hoverable brand-dark menucats';

  const history = useHistory();
  const location = useLocation();

  const cat_id = location.state.id;

    const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const [values, setValues] = React.useState({
        name: "",
        profile_avatar: "",
        avatarFile: ""
    });

    const [state, setState] = React.useState({
        name: "",
        description:"",
        profile_avatar: "",
        avatar: "/media/images/blank.jpg",
        loader:false,
        selectedFile:null,
        selectedFile2:null,
        started: false,
        starttxt: true,
        useVarations: false,
        noPrice: true,
        valid_variations: "",
        variationsMsg: "hide",
        recommended: true,
        popular: true,
        new:false,
        veg:false,
        addonset: "",
        attributeset: "",
        varation1: false,
        varation2: false,
        varation3: false,
        variations: [],
        varprice: "",
        varcprice:"",
        varParamName: "Variant",
        valid_name: "",
        nameMsg: "hide",
        valid_desc: "",
        descMsg: "hide",
        variant_ids: "",
        valid_price: "",
        priceMsg: "hide",
        valid_addons: "",
        addonsMsg: "hide",
        valid_attributes: "",
        attributesMsg: "hide",
        valid_variants: "",
        variantsMsg: "hide"
    });

    const handleChange = name => event => {
        //console.log([name, event.target.value])
	      setState({ ...state, [name]: event.target.value, valid_name:"", nameMsg:"hide", valid_name2: "", nameMsg2: "hide" });
        setValues({ ...values, [name]: event.target.value });
    };

  function handleChangeChk(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }

  const [avatarFile, setAvatarFile] = useState("");

  const setAvatar = event => {
    var m = new FileReader();
    m.onload = function(e){
      setState({...state,avatar: e.target.result})
    };
    m.readAsDataURL(event.target.files[0]);
    var file = event.target.files[0];
    setAvatarFile(file)
    
    const fd = new FormData();
    fd.append("user_id", user.id);
    fd.append("image", file);
  }  

  const getInputClasses = (fieldname) => {
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};

    const CategorySchema = Yup.object().shape({
    	//name: Yup.string()
	      //.required("Enter a Item name"),
	});

  const globalState  = useSelector(state=>state.menus)
  const {user} = useSelector(state => state.auth);
  const [allcatattrs, setCatAttrs] = useState([]);
  const [varParams, setVarParams] = useState([]);
  const [variationData, setVariationData] = useState([]);
	const user_id = user.id; 


  useEffect( ()=>{
    setCatAttrs(globalState.all_catalog_assets)
    //console.log(allcatattrs);
  });

  useEffect( ()=>{
    props.getAllCatalogAssets(location.state.catid)
  } , []); 

    const formik = useFormik({
    	initialValues,
    	validationSchema: CategorySchema,
    	onSubmit: (values, { setSubmitting, setStatus }) => {
        console.log(state.started)
        if(state.name === "") {
            setState({...state, valid_name:"is-invalid", nameMsg: "show"});
            swal({
              title: "Something Wrong",
              text: "Please Add Item Name",
              icon: "error",
              button: null,
              timer: 2000
            });
            return false;
        } else if (avatarFile == "") {
          swal({
            title: "Something Wrong",
            text: "Please Select Item Photo",
            icon: "error",
            button: null,
            timer: 2000
          });
          return false
        }
        else if (state.started == false && state.price == "" || state.started == false && state.price == undefined) {
          setState({...state,valid_price:"is-invalid", priceMsg: "show", valid_name:"is-valid", nameMsg: "hide", valid_desc:"is-valid", descMsg: "hide"});
          swal({
            title: "Something Wrong",
            text: "Please Add Item Price",
            icon: "error",
            button: null,
            timer: 2000
          });
          return false
        }
        else if (state.started == true && state.variant_ids == "" || state.started == true && state.variant_ids == undefined) {
          setState({...state,valid_variations: "is-invalid", variationsMsg: "show", valid_price:"is-valid", priceMsg: "hide", valid_name:"is-valid", nameMsg: "hide", valid_desc:"is-valid", descMsg: "hide"});
          swal({
            title: "Something Wrong",
            text: "Please Select Variant Type",
            icon: "error",
            button: null,
            timer: 2000
          });
          return false
        }
        else if (state.started == true && state.variations == "" || state.started == true && state.variations == undefined || state.started == true && state.variations == []) {
          setState({...state, valid_variants: "is-invalid", variantsMsg: "show", valid_variations: "is-valid", variationsMsg: "hide", valid_price:"is-valid", priceMsg: "hide", valid_name:"is-valid", nameMsg: "hide", valid_desc:"is-valid", descMsg: "hide"});
          swal({
            title: "Something Wrong",
            text: "Please Select Variants",
            icon: "error",
            button: null,
            timer: 2000
          });
          return false
        }
        
        // else if (state.addonset == "" || state.addonset == undefined) {
        //   setState({...state,valid_addons: "is-invalid",addonsMsg: "show",valid_price:"is-valid", priceMsg: "hide", valid_name:"is-valid", nameMsg: "hide", valid_desc:"is-valid", descMsg: "hide"});
        //   swal({
        //     title: "Something Wrong",
        //     text: "Please Select Addon Set",
        //     icon: "error",
        //     button: null,
        //     timer: 2000
        //   });
        //   return false
        // }
        // else if (state.attributeset == "is-invalid" || state.attributeset == undefined) {
        //   setState({...state,valid_attributes: "is-invalid", attributesMsg:"show", valid_addons: "is-valid",addonsMsg: "hide",valid_price:"is-valid", priceMsg: "hide", valid_name:"is-valid", nameMsg: "hide", valid_desc:"is-valid", descMsg: "hide"});
        //   swal({
        //     title: "Something Wrong",
        //     text: "Please Select Attributes Set",
        //     icon: "error",
        //     button: null,
        //     timer: 2000
        //   });
        //   return false
        // }
        else
        {
          // swal({
          //   title: "OK",
          //   text: "All Done",
          //   icon: "success",
          //   button: null,
          //   timer: 2000
          // });
          setState({...state,valid_variations: "is-valid", variationsMsg: "hide", valid_attributes: "is-valid", attributesMsg:"hide",valid_addons: "is-valid",addonsMsg: "hide",valid_price:"is-valid", priceMsg: "hide", valid_name:"valid", nameMsg: "hide", valid_type:"is-valid", typeMsg: "hide", valid_desc:"is-valid", descMsg: "hide", loader:true});
          if(state.started == false){
            props.addItem({ 
              user_id:user.id,
              cat_id :cat_id,
              name :state.name,
              description :state.description,
              price: state.price,
              cprice:state.cprice,
              variant_ids: state.variant_ids,
              variants: null,
              addon: state.addonset,
              attributeset: state.attributeset,
              recommended: state.recommended ? 1 :0,
              popular: state.popular ? 1 :0,
              new: state.new ? 1 :0,
              veg: state.veg ? 1 :0,
              image: {file: avatarFile, name: avatarFile.name}
            });
          } else {
            props.addItem({ 
              user_id:user.id,
              cat_id :cat_id,
              name :state.name,
              description :state.description,
              price: 0,
              cprice:0,
              variant_ids: state.variant_ids,
              variants: JSON.stringify(variationData),
              addon: state.addonset,
              attributeset: state.attributeset,
              recommended: state.recommended ? 1 :0,
              popular: state.popular ? 1 :0,
              new: state.new ? 1 :0,
              veg: state.veg ? 1 :0,
              image: {file: avatarFile, name: avatarFile.name}
            });
          }
          setTimeout(() => {
            swal({
                title: "Attribute Added",
                text: state.name+" saved successfully!",
                icon: "success",
                button: null,
                timer: 3000
            });
            setTimeout(() => {
              history.push({
                pathname: '/menu/all',
                state: {
                  id: location.state.catid,
                  name: location.state.catname
                }
              });
            }, 1500);
            setState({...state, loading:false,valid_attributes: "is-valid", attributesMsg:"hide",valid_addons: "is-valid",addonsMsg: "hide", valid_price:"is-valid", priceMsg: "hide", valid_name:"valid", nameMsg: "hide", valid_type:"is-valid", typeMsg: "hide", loader: false});
          }, 4000);
        }
	    },
	});

    function goBack() {
      history.goBack()
    }

    const handleVariations = () => {
      setPersonName3([]);
      var swStartOffer = state.started;
          //console.log(JSON.stringify(state.started))
      switch(state.started) {
        case true:
          return setState({ ...state, start_status:0,started:!swStartOffer,starttxt:true, useVarations:false, noPrice: true, varation1: false, varation2: false, varation3: false, variations: []});
        default:
          return setState({ ...state, start_status:1,started:!swStartOffer,starttxt:false, useVarations:true, noPrice: false, varation1: false, varation2: false, varation3: false, variations: []});
      }
    };  

    const [personName, setPersonName] = React.useState([]);
    function handleChangeM(event) {
      setPersonName(event.target.value);
      setState({...state, addonset: event.target.value});
      console.log("Addons:  "+event.target.value)
    }
    const [personName2, setPersonName2] = React.useState([]);
    function handleChangeM2(event) {
      setPersonName2(event.target.value);
      setState({...state, attributeset: event.target.value});
      console.log("Attributes:  "+event.target.value)
    }
    const [personName3, setPersonName3] = React.useState([]);
    function handleChangeM3(event) {
      setPersonName3(event.target.value);
      // var resultArr = [];
      // var vpp = [...variationData];
      // var indexArr = [...event.target.value];
      // for(var i = 0; i < indexArr.length; i++)
      //   resultArr.push(vpp[indexArr[i]]);
      // setVariationData(resultArr)
      // console.log(variationData)
      setState({...state, variations: event.target.value});
    }
  
    function handleChangeMultiple(event) {
      const { options } = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      setPersonName(value);
    }  

    const handleChangeS = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };

  const selectVariations =(e)=> {
    var id = e.target.value;
    setPersonName3([]);
    var cat_variants = allcatattrs.catalog_variants;
    var variant = cat_variants.filter((item)=>item.id == id)
    var params = variant[0].parameters.split(',').map(item=>item);
    if(params.length>0){
        if(params[0].id == undefined && params[0].id== null){
          params = params.map((item, index) => ({id: index, name: item, price:null, cprice:null})  )
        }
    }
    var paramName = variant[0].name;
    setVarParams(params);
    setState({...state, varParamName:paramName, variations: [], variant_ids: id});
  }  
  
  var handlePriceInput = (e) => {
    var id = e.target.id
    var value = e.target.value
    var name = e.target.name
    var variations = [...varParams];
    var  variations = variations.filter((item)=> item)
    variations.forEach((item,index)=>{
        if(item.id == id){
        item[name] = value
        }
    })
    var variations = variations.filter((item)=> item.price && item )
    setVariationData(variations)

  }

  const addAttributeSet =()=> {
    history.push("/menu/attribute/all");
  }

  const addAddonSet =()=> {
    history.push("/menu/addons/all");
  }

  const addVariantSet =()=> {
    history.push("/menu/variations/all");
  }

  function filterData(data){
    var data = data;
    return data.filter(obj=>!Object.values(obj).includes(null));
    //data = data.filter(data => data.id !== null);
    //return data;
  }

  function filterData2(data){
    var data = data;
    return data.filter(obj=>!Object.values(obj).includes(null));
  }

	return (<>
 
      <div className="row addonPage" id="menuPage">
          {
              state.loader == true?
              <BlockUI />
              : <></>
          }

        <h1 className="addonset-title">
          {location.state.name}
            <span className="svg-icon menu-icon goBack" onClick={goBack}>
                <i className="fa fa-long-arrow-alt-left" onClick={goBack}></i>
            </span>
        </h1>
        
        <div className="card card-custom gutter-b col-md-12 firstBlk">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label"><FormattedMessage id="PAGE.ADDITEM.WIDGET.TITLE" /></h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">

              <div className="col-md-6 ffield">
                <form 
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  className="form-add-item form fv-plugins-bootstrap fv-plugins-framework"
                >
                  {formik.status ? (
                    <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                      <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                  ) : (
                    <div>
                    </div>
                  )}
                  
                  <div className={`fieldBlk mb-10 `+ state.valid_name}>
                    <TextField
                      name="name"
                      variant="outlined"
                      label="Item Name"
                      className={`form-control h-auto py-0 px-0  ${getInputClasses("name")}`}
                      value={values.name}
                      onChange={handleChange("name")}
                    />
                    <span className={`fv-help-block `+ state.nameMsg}>Item Name Required</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                      <FormattedMessage id="PAGE.ADDITEM.NAME.HELP" />
                    </p>
                  </div>
                  
                  <div className={`fieldBlk mb-10 `+ state.valid_desc}>
                    <TextField
                      name="description"
                      variant="outlined"
                      label="Item Description"
                      rows="3"
						          multiline
                      className={`form-control h-auto py-0 px-0  ${getInputClasses("description")}`}
                      value={values.description}
                      onChange={handleChange("description")}
                    />
                    <span className={`fv-help-block `+ state.descMsg}>Item Description Required</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                      <FormattedMessage id="PAGE.ADDITEM.DESCRIPTION.HELP" />
                    </p>
                  </div>

                  <div className="fieldBlk mb-10">
                    <label className="image-label">Item Photo</label>
                    <div className="image-input image-input-outline" id="kt_profile_avatar">
                      <img src={state.avatar} className="image-input-wrapper2" />
                      <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="Change avatar" data-original-title="Change avatar">
                          <i className="fa fa-pen icon-sm text-muted"></i>
                          <input type="file" name="profile_avatar" value={values.profile_avatar} onChange={setAvatar} accept=".png, .jpg, .jpeg" />
                          <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span className="form-text text-muted">Allowed file types:  .png, .jpg, .jpeg.</span>
                    </div>
                  </div>

                  <hr className="hr2" />

                  <div className="fieldBlk mb-10">
                    <label htmlFor="startnow" className="attr-label2 text-dark-75 text-link font-weight-bold font-size-lg">
                        <FormattedMessage id="PAGE.ADDITEM.VARIATIONS.LABEL" />
                    </label>
                    <Switch
                        name="startnow"
                        id="startnow"
                        color="primary"
                        checked={state.started}
                        onChange={handleVariations}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {
                        state.starttxt?
                        <span className="usageLmtTxt">
                          {/* <FormattedMessage id="PAGE.ATTRIBUTES.LIMIT.HINT" /> */}
                        </span>
                        :<span></span>
                    }
                  </div>

                  {
                    state.noPrice ?
                    <div className="row w103">
                      <div className={`fieldBlk mb-10 col-md-6 `+ state.valid_price}>
                        <TextField
                          name="price"
                          variant="outlined"
                          label="Item Price"
                          type="number"
                          className={`form-control h-auto py-0 px-0  ${getInputClasses("price")}`}
                          value={values.price}
                          onChange={handleChange("price")}
                        />
                        {formik.touched.price && formik.errors.price ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.price}</div>
                          </div>
                        ) : null}
                        <span className={`fv-help-block `+ state.priceMsg}>Item Description Required</span>
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                          <FormattedMessage id="PAGE.ADDITEM.PRICE.HELP" />
                        </p>
                      </div>

                      <div className={`fieldBlk mb-10 col-md-6 `}>
                        <TextField
                          name="cprice"
                          variant="outlined"
                          label="Cost Price"
                          type="number"
                          className={`form-control h-auto py-0 px-0  ${getInputClasses("cprice")}`}
                          value={values.cprice}
                          onChange={handleChange("cprice")}
                      />
                        {formik.touched.cprice && formik.errors.cprice ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.cprice}</div>
                          </div>
                        ) : null}
                        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                          <FormattedMessage id="PAGE.ADDITEM.COSTPRICE.HELP" />
                        </p>
                      </div>
                    </div>
                    :<></>
                  }

                  {
                    state.useVarations &&
                    <div className={`fieldBlk mb-10 `} id="key-var" key="key-var">
                        <div className={`fieldBlk mb-10`+ state.valid_variations} key="key-var1">
                          {
                            allcatattrs.catalog_variants != undefined && allcatattrs.catalog_variants.length < 1 ? 
                              <span className="no-set">
                                No Variant Set Assigned.
                                <span className="text-link text-info add-set" onClick={addVariantSet}>Assign New Variant Set</span>
                              </span>
                            :<>
                            <TextField
                                select
                                id="allvatiations"
                                name="allvatiations"
                                className={`form-control h-auto py-0 px-0  ${getInputClasses("allvatiations")}`}
                                variant="outlined"
                                label="Select Variation Type"
                                margin="normal"
                                value={values.allvatiations}
                                onChange={selectVariations}
                            >
                                {allcatattrs.catalog_variants.map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                            <span className={`fv-help-block `+ state.variationsMsg}>Variation Type Required</span>
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                              <FormattedMessage id="PAGE.ADDITEM.ALLVARIATIONS.HELP" />
                            </p>
                            </> 
                          }  
                        </div>

                        {
                          allcatattrs.catalog_variants != undefined && allcatattrs.catalog_variants.length < 1 ? 
                          <></>
                          :<>

                          {
                          varParams == [] ?
                          <span className="slctoption">Select  Variants</span>
                          : <div className={`fieldBlk mb-10 multidropdown `+ state.valid_variants}  id="key-var2">
                              <FormControl variant="outlined" className={classes.textField} margin="normal">
                                <InputLabel htmlFor="variantsid1">
                                  Select {state.varParamName}
                                </InputLabel>
                                <Select
                                  multiple
                                  name="variantsid1"
                                  value={personName3 || "No Variation Set"}
                                  onChange={handleChangeM3}
                                  input={ <Input id="variantsid1" />}
                                  renderValue={selected => selected.join(', ')}
                                  MenuProps={MenuProps}
                                >
                                {varParams !== [] ? varParams.map((option, index) => (
                                  <MenuItem key={index} value={option.id}>
                                    <Checkbox checked={personName3.indexOf(index) > -1} />
                                    <ListItemText primary={option.name || "No Variation Set"} />
                                  </MenuItem>
                                )) : <></>}
                                </Select>
                              </FormControl>
                              <span className={`fv-help-block `+ state.variantsMsg}>Variants Required</span>
                              <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                                <FormattedMessage id="PAGE.ADDITEM.VARIATIONS.HELP" />
                              </p>
                            </div>
                          }
                        </>
                        }

                          {varParams.map((option, index) => (
                             <>{JSON.stringify(state.variations).includes(option.id) ? 
                              <div key={index} id={option.id} className="item-varientsBlk">
                                <label className="inputLabelBlk">{option.name}</label>
                                <div className="inputNlk variationsTxtBlk">
                                  <TextField
                                    name={'price'}
                                    variant="outlined"
                                    label={option.name +` Price`}
                                    type="number"
                                    className={`form-control h-auto py-0 px-0  ${getInputClasses("price")}`}
                                    onChange={handlePriceInput}
                                    index={option.id}
                                    id={option.id}
                                  />
                                </div>
                                <div className="inputNlk variationsTxtBlk">
                                  <TextField
                                    name={'cprice'}
                                    variant="outlined"
                                    label={option.name +` Cost Price`}
                                    type="number"
                                    className={`form-control h-auto py-0 px-0  ${getInputClasses("cprice")}`}
                                    onChange={handlePriceInput}
                                    index={option.id}
                                    id={option.id}
                                  />
                                </div>
                              </div>
                              : ""}</>
                          ))}
                    </div>
                  }

                  <hr className="hr2" />

                  <div className={`fieldBlk mb-10 multidropdown `+ state.valid_addons}>
                    {allcatattrs.catalog_addons != undefined && allcatattrs.catalog_addons.length < 1 ? 
                      <span className="no-set">
                        No Addon Set Assigned.
                        <span className="text-link text-info add-set" onClick={addAddonSet}>Assign New Addon Set</span>
                      </span>
                      :<>
                      <FormControl variant="outlined" className={classes.textField} margin="normal">
                        <InputLabel htmlFor="select-multiple-checkbox">
                          <FormattedMessage id="PAGE.ADDITEM.ADDONSET.LABEL" />
                        </InputLabel>
                        {allcatattrs.catalog_addons !== undefined && allcatattrs.catalog_addons.length > 0 ? 
                          <Select
                            multiple
                            name="addons"
                            value={personName || "No Addon Set"}
                            onChange={handleChangeM}
                            input={<Input id="select-multiple-checkbox" />}
                            renderValue={selected => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                          {allcatattrs.catalog_addons !== undefined ? filterData2(allcatattrs.catalog_addons).map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                                <Checkbox checked={personName.indexOf(option.id) > -1} />
                                <ListItemText primary={option.name} />
                            </MenuItem>
                          )) : <></>}
                          </Select>
                        :
                        <>No Addon Set Listed</>
                        }
                        
                      </FormControl>
                      <span className={`fv-help-block `+ state.addonsMsg}>Item Addons Required</span>
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.ADDITEM.ADDONSET.HELP" />
                      </p>
                    </>}
                  </div>

                  <hr className="hr2" />

                  <div className={`fieldBlk mb-10 multidropdown `+state.valid_attributes}>
                    {allcatattrs.catalog_attributes != undefined && allcatattrs.catalog_attributes.length < 1 ? 
                      <span className="no-set">
                        No Attribute Set Assigned.
                        <span className="text-link text-info add-set" onClick={addAttributeSet}>Assign New Attribute Set</span>
                      </span>
                    : <>
                      <FormControl variant="outlined" className={classes.textField} margin="normal">
                        <InputLabel htmlFor="select-multiple-checkbox2">
                          <FormattedMessage id="PAGE.ADDITEM.ATTRIBUTES.LABEL" />
                        </InputLabel>
                        <Select
                          multiple
                          name="attributes"
                          value={personName2 || "No Attribute Set"}
                          onChange={handleChangeM2}
                          input={<Input id="select-multiple-checkbox2" />}
                          renderValue={selected => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                        {allcatattrs.catalog_attributes !== undefined ? filterData(allcatattrs.catalog_attributes).map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            <Checkbox checked={personName2.indexOf(option.id) > -1} />
                            <ListItemText primary={option.name || "No Attribute Set"} />
                          </MenuItem>
                        )) : <></>}
                        </Select>
                      </FormControl>
                      <span className={`fv-help-block `+ state.attributesMsg}>Item attributes required</span>
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        <FormattedMessage id="PAGE.ADDITEM.ATTRIBUTES.HELP" />
                      </p>
                      </> 
                    }
                    
                  </div>

                  <hr className="hr2" />

                  <div className="fieldBlk mb-10">
                    <label htmlFor="recommended" className="attr-label2 text-dark-75 text-link font-weight-bold font-size-lg">
                        <FormattedMessage id="PAGE.ADDITEM.RECOMMENDED.LABEL" />
                    </label>
                    <Switch
                        name="recommended"
                        checked={state.recommended}
                        onChange={handleChangeS}
                        color="primary"
                        name="recommended"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
                  
                  <div className="fieldBlk mb-10">
                    <label htmlFor="recommended" className="attr-label2 text-dark-75 text-link font-weight-bold font-size-lg">
                        <FormattedMessage id="PAGE.ADDITEM.POPULAR.LABEL" />
                    </label>
                    <Switch
                        name="popular"
                        checked={state.popular}
                        onChange={handleChangeS}
                        color="primary"
                        name="popular"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>

                  <div className="fieldBlk mb-10">
                    <label htmlFor="recommended" className="attr-label2 text-dark-75 text-link font-weight-bold font-size-lg">
                        <FormattedMessage id="PAGE.ADDITEM.NEW.LABEL" />
                    </label>
                    <Switch
                        name="new"
                        checked={state.new}
                        onChange={handleChangeS}
                        color="primary"
                        name="new"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>

                  <div className="fieldBlk mb-10">
                    <label htmlFor="recommended" className="attr-label2 text-dark-75 text-link font-weight-bold font-size-lg">
                        <FormattedMessage id="PAGE.ADDITEM.VEG.LABEL" />
                    </label>
                    <Switch
                        name="veg"
                        checked={state.veg}
                        onChange={handleChangeS}
                        color="primary"
                        name="veg"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
                  <div className="fieldBlk mt-0 mb-0 class-submit">
                      <Button 
                          type="submit"
                          //disabled={formik.isSubmitting}
                          variant="contained" 
                          color="primary" 
                          className={classes.button}
                      >
                          <FormattedMessage id="SAVE" />
                          {loading && <span className="ml-3 spinner spinner-white"></span>}
                      </Button>
                      <Button variant="contained" className={classes.button} onClick={goBack}>
                          <FormattedMessage id="CANCEL" onClick={goBack} />
                      </Button>
                  </div>

                </form>
              </div>

              <div className="col-md-6 ffield">
                <img src="/media/images/additem.jpg" className="no-opacity" />
              </div>

            </div>
          </div>
        </div>

      </div>  
      <ToastContainer />
	</>);

}

export default connect(null, mredux.actions)(AddItem);