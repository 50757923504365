import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {useSelector} from "react-redux";
import { FormattedMessage} from "react-intl";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          Copyright <span className="text-muted">&copy;{" "}</span>
          <a
            href="https://www.mumara.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary font-weight-bold"
          >
            Mumara
          </a> <span>{today.toString()}</span> 
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <a
            href="https://school.mumara.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0 hide"
          >
            <FormattedMessage id="FOOTER.LINK.LABEL1" />
          </a>
          <a
            href="https://docs.mumara.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3 hide"
          >
            <FormattedMessage id="FOOTER.LINK.LABEL2" />
          </a>
          <a
            href="https://www.mumara.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            <FormattedMessage id="FOOTER.LINK.LABEL3" />
          </a>
        </div>
      </div>
    </div>
  );
}
