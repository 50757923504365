import React, {useState , useMemo , useEffect} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { useHistory, useLocation  } from "react-router-dom";
import {connect,useSelector,useDispatch} from 'react-redux';
import * as credux from './_redux/campRedux';
import {API_URL} from "../../Constants";
import BlockUI from '../Component/BlockUI';
import ReactHtmlParser from 'react-html-parser';

/**  Apex-Charts  **/
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {useHtmlClassService} from "../../../_metronic/layout";
import {KTUtil} from "../../../_metronic/_assets/js/components/util";

/**  AM-Charts  **/
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


function getChartOption(layoutProps, props) {
	var options = {
	  series: [
		{
		  name: "Audience Reached",
		  data: props.all_audience
		}
	  ],
	  chart: {
		type: "area",
		height: 150,
		toolbar: {
		  show: false
		},
		zoom: {
		  enabled: false
		},
		sparkline: {
		  enabled: true
		}
	  },
	  plotOptions: {},
	  legend: {
		show: false
	  },
	  dataLabels: {
		enabled: false
	  },
	  fill: {
		type: "solid",
		opacity: 1
	  },
	  stroke: {
		curve: "smooth",
		show: true,
		width: 3,
		colors: [layoutProps.colorsThemeBasePrimary]
	  },
	  xaxis: {
		categories: props.all_aud_time,
		axisBorder: {
		  show: false
		},
		axisTicks: {
		  show: true
		},
		labels: {
		  show: false,
		  style: {
			colors: layoutProps.colorsGrayGray500,
			fontSize: "12px",
			fontFamily: layoutProps.fontFamily
		  }
		},
		crosshairs: {
		  show: false,
		  position: "front",
		  stroke: {
			color: layoutProps.colorsGrayGray300,
			width: 1,
			dashArray: 3
		  }
		},
		tooltip: {
		  enabled: true,
		  formatter: undefined,
		  offsetY: 0,
		  style: {
			fontSize: "12px",
			fontFamily: layoutProps.fontFamily
		  }
		}
	  },
	  yaxis: {
		min: 0,
		max: props.aud_max+1,
		labels: {
		  show: false,
		  style: {
			colors: layoutProps.colorsGrayGray500,
			fontSize: "12px",
			fontFamily: layoutProps.fontFamily
		  }
		}
	  },
	  states: {
		normal: {
		  filter: {
			type: "none",
			value: 0
		  }
		},
		hover: {
		  filter: {
			type: "none",
			value: 0
		  }
		},
		active: {
		  allowMultipleDataPointsSelection: false,
		  filter: {
			type: "none",
			value: 0
		  }
		}
	  },
	  tooltip: {
		style: {
		  fontSize: "12px",
		  fontFamily: layoutProps.fontFamily
		},
		y: {
		  formatter: function(val) {
			return "" + val + "";
		  }
		}
	  },
	  colors: [layoutProps.colorsThemeLightPrimary],
	  markers: {
		colors: [layoutProps.colorsThemeLightPrimary],
		strokeColor: [layoutProps.colorsThemeBasePrimary],
		strokeWidth: 3
	  }
	};
	return options;
}
  
export function AudienceReached(props) {
	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
	  return {
		colorsGrayGray500: objectPath.get(
		  uiService.config,
		  "js.colors.gray.gray500"
		),
		colorsGrayGray200: objectPath.get(
		  uiService.config,
		  "js.colors.gray.gray200"
		),
		colorsGrayGray300: objectPath.get(
		  uiService.config,
		  "js.colors.gray.gray300"
		),
		colorsThemeBasePrimary: objectPath.get(
		  uiService.config,
		  "js.colors.theme.base.primary"
		),
		colorsThemeLightPrimary: objectPath.get(
		  uiService.config,
		  "js.colors.theme.light.primary"
		),
		fontFamily: objectPath.get(uiService.config, "js.fontFamily")
	  };
	}, [uiService]);
  
	useEffect(() => {
	  const element = document.getElementById("audience_chart");
	  if (!element) {
		return;
	  }
  
	  const options = getChartOption(layoutProps, props);
	  const chartnewUsers = new ApexCharts(element, options);
	  chartnewUsers.render();
	  return function cleanUp() {
		chartnewUsers.destroy();
	  };
	}, [layoutProps, props]);
  
	return (
	  <div className={`card card-custom audiance-chart mb-10`}>
		<div className="card-body d-flex flex-column p-0">
		  <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
			<span className="symbol symbol-circle symbol-50 symbol-light-primary mr-2">
			  <span className="symbol-label">
				<span className="svg-icon svg-icon-xl svg-icon-primary">
				  <SVG
					src={toAbsoluteUrl(
					  "/media/svg/icons/Communication/Group.svg"
					)}
				  ></SVG>
				</span>
			  </span>
			</span>
			<div className="d-flex flex-column text-right">
			  <span className="text-dark-75 font-weight-bolder font-size-h3">
				+{props.all_sent}
			  </span>
			  <span className="text-muted font-weight-bold mt-2"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.AUDIENCESTATES.LABEL" /></span>
			</div>
		  </div>
		  <div
			id="audience_chart"
			className="card-rounded-bottom"
			style={{ height: "150px" }}
		  ></div>
		</div>
	  </div>
	);
}

function getChartOptions(layoutProps, height, props) {
	const options = {
	  series: [props.values],
	  chart: {
		height: height,
		type: 'radialBar',
	  },
	  plotOptions: {
		radialBar: {
		  hollow: {
			margin: 0,
			size: "65%"
		  },
		  dataLabels: {
			showOn: "always",
			name: {
			  show: false,
			  fontWeight: "700",
			},
			value: {
			  color: layoutProps.colorsGrayGray700,
			  fontSize: "30px",
			  fontWeight: "700",
			  offsetY: 12,
			  show: true
			},
		  },
		  track: {
			background: layoutProps.colorsThemeLightSuccess,
			strokeWidth: '100%'
		  }
		}
	  },
	  colors: [layoutProps.colorsThemeBaseSuccess],
	  stroke: {
		lineCap: "round",
	  },
	  labels: ["Progress"]
	};
	return options;
  }
  
export function CTRData(props) {
	const uiService = useHtmlClassService();
  
	const [state , setState] = useState({
	  ctrcolor: 'ctr100'
	});
  
	const layoutProps = useMemo(() => {
		return {
		  colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
		  colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
		  colorsThemeBaseSuccess: objectPath.get(
			  uiService.config,
			  "js.colors.theme.base.success"
		  ),
		  colorsThemeLightSuccess: objectPath.get(
			  uiService.config,
			  "js.colors.theme.light.success"
		  ),
		  fontFamily: objectPath.get(uiService.config, "js.fontFamily")
		};
	}, [uiService]);
  
	useEffect(() => {
		const element = document.getElementById("kt_mixed_widget_14_chart");
		if (!element) {
		  return;
		}
  
		const height = parseInt(KTUtil.css(element, 'height'));
		const options = getChartOptions(layoutProps, height, props);
		const ctr = options.series;
		if(ctr > 90) {
		  setState({...state, ctrcolor: 'ctr90p'});
		} else if (ctr > 70) {
		  setState({...state, ctrcolor: 'ctr70p'});
		} else if (ctr > 50) {
		  setState({...state, ctrcolor: 'ctr50p'});
		} else if (ctr > 40) {
		  setState({...state, ctrcolor: 'ctr40p'});
		} else if (ctr > 25) {
		  setState({...state, ctrcolor: 'ctr25p'});
		} else {
		  setState({...state, ctrcolor: 'ctrlow'});
		}
  
		const chart = new ApexCharts(element, options);
		chart.render();
		return function cleanUp() {
		  chart.destroy();
		};
	}, [layoutProps, props]);
  
	return (
	  <div className={`card card-custom mb-10`}>
		{/* Header */}
		<div className="card-header border-0 pt-5">
		  <h3 className="card-title font-weight-bolder ">CTR (Click Through Rate)</h3>
		  <div className="card-toolbar"></div>
		</div>
		<div className="card-body">
		  <div className="flex-grow-1">
			<div id="kt_mixed_widget_14_chart" className={state.ctrcolor} style={{height: "200px"}}></div>
		  </div>
		</div>
	  </div>
	);
}

export function CitiesChart(props) {

	am4core.useTheme(am4themes_animated);
	let chart = am4core.create("chartdiv", am4charts.PieChart3D);
	chart.hiddenState.properties.opacity = 0;
	chart.responsive.enabled = true;

	chart.data = props.sub_cities;

	chart.innerRadius = am4core.percent(40);
	//console.log(props.sub_cities.length)
	if(props.sub_cities.length <2) {
		chart.depth = 30;
	} else if(props.sub_cities.length <3){
		chart.depth = 55;
	} else if(props.sub_cities.length <4){
		chart.depth = 75;
	} else {
		chart.depth = 120;
	}

	chart.legend = new am4charts.Legend();

	let series = chart.series.push(new am4charts.PieSeries3D());
	series.dataFields.value = "users";
	series.dataFields.depthValue = "users";
	series.dataFields.category = "city";
	series.slices.template.cornerRadius = 5;
	series.colors.step = 3;

	console.log(props.sub_cities !== undefined && props.sub_cities.length > 0 ? props.sub_cities  :[])

	return (
		<> 
			<div id="chartdiv" style={{ width: "100%", height: "400px", margin: "0px auto" }}></div>
		</>
	);
}

export function EngagesChart(props) {

	am4core.useTheme(am4themes_animated);

	let chart = am4core.create("engageschart", am4charts.PieChart3D);
	chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

	chart.legend = new am4charts.Legend();

	chart.data = [
	{
		engags: "Views",
		value: props.views
	},
	{
		engags: "Click",
		value: props.clicks
	},
	{
		engags: "Unsubscribes",
		value: props.unsubs
	}
	];

	chart.depth = 16;

	let series = chart.series.push(new am4charts.PieSeries3D());
	series.dataFields.value = "value";
	series.dataFields.category = "engags";

	return (
		<div className={`card card-custom mb-10 engages-chart`}>
			<div className="card-header border-0 pt-5">
				<h3 className="card-title font-weight-bolder "><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.ENGAGEMENTSSTATES.LABEL" /></h3>
				<div className="card-toolbar">
			<div className="d-flex flex-column text-right">
			  <span className="text-dark-75 font-weight-bolder total-engags">
				{props.engages}
			  </span>
			  <span className="text-muted font-weight-bold mt-2"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.ENGAGEMENTSSTATES.DESC" /></span>
			</div>
				</div>
			</div>
			<div className="card-body d-flex flex-column">
				{
					props.engages > 0 ? 
					<div id="engageschart" style={{ width: "100%", height: "300px", margin: "-10px auto 20px" }}></div>
					: <div className="states-nodata">
						<img src="/media/images/empty.png" width="102" height="102" />
						<span>No Engagement Found</span>
					  </div>
				}
			</div>
		</div>
	);
}


function ViewStatistics(props) {

	var moment = require('moment');

	const history = useHistory();
	const location = useLocation();

	const id = location.state.id;
	//console.log(id)

	const audience = () => {
		history.push('/business/branch/subscriber');
	}
	
	function numFormatter(num) {
		return Math.abs(num) > 999999 ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'M' : Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K' : Math.sign(num)*Math.abs(num)
	}

	const [state , setState] = useState({
		id: id == undefined ? 0 : id ,
		stats:[],
		name: '',
		campaign: '',
		broadcast:'',
		branches: '',
		total_scheduled:0,
		ctr:location.state.ctr,
		engage: 0,
		sent:0,
		open:0,
		clicks:0,
		unsub:0,
		openK:0,
		clicksK:0,
		unsubK:0,
		openU:0,
		clicksU:0,
		unsubU:0,
		openData:[],
		created_on: '',
		start_time:'',
		end_time: '',
		duration: '',
		status: false,
		status_class: '',
		status_label:'',
		ctrcolor: 'ctr100',
		cityData:0,
		loader:true,
		loc_status:location.state.status,
	});

	const globalState  = useSelector(state=>state.campaigns)
	const [statistics, setdata] = useState([]);
	const [statusClass, setStatusClass] = useState("");
	const [statusLabel, setStatusLabel] = useState("");

	// useEffect( ()=>{
	// 	//console.log(globalState.statistics_data)
	// 	//setdata(globalState.statistics_data)
	// });

	useEffect( ()=>{
		//props.getStatisticData(id)


		if(state.loc_status == "resumed" || state.loc_status == "scheduled") {
			setStatusClass("badge badge-secondary");
			setStatusLabel("Scheduled");
			//console.log("Campaign scheduled");
		}
		else if(state.loc_status == "processing") {
			setStatusClass("badge badge-success");
			setStatusLabel("Running");
			//console.log("Campaign processing");
		}
		else if(state.loc_status == "paused") {
			setStatusClass("badge badge-info");
			setStatusLabel("Paused");
			//console.log("Campaign paused");
		}
		else if(state.loc_status == "system-paused") {
			setStatusClass("badge badge-danger");
			setStatusLabel("System Paused");
			//console.log("Campaign system paused");
		}
		else {
			setStatusClass("badge badge-dark");
			setStatusLabel("Finished");
			//console.log("Campaign completed");
		}
		
		loadStatistics();
				
	} , []);

	function loadStatistics() {
		let mounted = true;
		fetch(API_URL + 'api/statistics/'+id , {
			method: "GET",
			headers:{
				"Content-Type":"application/json"
			},
		}).then(r => r.json().then(data => {
			//console.log(data)

			var tmp = [...state.stats]
			tmp.push(data)

			var tmp1 = tmp[0];
			//console.log(tmp1)

			//console.log(tmp)
			if(mounted){
				try {
					setState({...state,
						stats: tmp ? tmp : [], 
						name:tmp1.name ? tmp1.name : 0,
						broadcast:tmp1.broadcast_name ? tmp1.broadcast_name : "",
						branches:tmp1.branch_names ? tmp1.branch_names : "",
						ctr:tmp1.ctr ? tmp1.ctr : 0,
						total_scheduled: numFormatter(tmp1.total_scheduled) ? numFormatter(tmp1.total_scheduled) : 0,
						sent:numFormatter(tmp1.emails_sent) ? numFormatter(tmp1.emails_sent) : 0,
						open:tmp1.total_open ? tmp1.total_open : 0,
						clicks:tmp1.total_clicks ? tmp1.total_clicks : 0,
						unsub:tmp1.total_unsubscribed ? tmp1.total_unsubscribed : 0,
						openK:numFormatter(tmp1.total_open) ? numFormatter(tmp1.total_open) : 0,
						clicksK:numFormatter(tmp1.total_clicks) ? numFormatter(tmp1.total_clicks) : 0,
						unsubK:numFormatter(tmp1.total_unsubscribed),
						openU:tmp1.unique_open ? tmp1.unique_open : 0,
						clicksU:tmp1.unique_clicks ? tmp1.unique_clicks : 0,
						unsubU:tmp1.unique_unsubscribed ? tmp1.unique_unsubscribed : 0,
						engage:tmp1.total_clicks+tmp1.total_open+tmp1.total_unsubscribed,
						start_time: tmp1.start_datetime == null ? "" : tmp1.start_datetime,
						end_time: tmp1.end_datetime == null ? "" : tmp1.end_datetime,
						openData: tmp1.recent_stats ? tmp1.recent_stats : 0,
						cityData: tmp1.uniqueCities ? tmp1.uniqueCities : 0,
						loader:false
					})
				} catch (error) {
					
				}
			}
		} ))
		.catch(e => e);
		return () =>  mounted = false;
	}

	function goBack() {
		history.push("/campaigns/statistics")
	}

	const [refresh, setRefresh] = React.useState("");

	function reload() {
		loadStatistics();
		setRefresh("fa-spin")
		setTimeout(function() {
			setRefresh("")
		}, 1500)
		
	}

	const ctrVlaues = state.ctr ? Math.round(state.ctr) : 0;
	const viewsVlaue = state.open ? state.open : 0;
	const clicksVlaue = state.clicks ? state.clicks : 0;
	const unsubVlaue = state.unsub ? state.unsub : 0;
	const engageVlaue = state.engage ? state.engage : 0;

	const allSent = state.sent ? state.sent : 0;

	//console.log(allSent)

	var colors = ["text-warning","text-success","text-danger", "text-primary", "text-warning","text-success","text-danger", "text-primary", "text-warning","text-success","text-danger", "text-primary"];
	colors.forEach(function(color) {
		//console.log(color)
		return color;
	});

	if(state.openData && state.openData.length > 0) {
		const p = state.openData.reduce(function(result, time) {
			const day = moment(time['Time']).format("mm");
			if (!result[day]) {
				result[day] = 0;
			}
			result[day]++;
			return result;
		}, {});

		var keys=[];
		var values=[];
		for (var key in p) {
			if (p.hasOwnProperty(key)) {
				keys.push(key);
				values.push(p[key]);
			}
		}
	}

	const allAudTime = keys == undefined ? ["1AM"] : keys;
	const allAudience = values == undefined ? [0]: values;

	//console.log(allAudTime, allAudience)
	//const allAudTime = ["10AM", "11AM", "12PM", "01PM", "02PM", "03PM", "04PM"];
	//const allAudience = [1, 10, 4, 2, 1, 1, 1];
	const audMax = Math.max(...allAudience);
	const subscribersCityData = [
		{city: "Lahore",users: 125987},
		{city: "Islamabad",users: 97274},
		{city: "Faisalabad",users: 75987},
		{city: "Multan",users: 64876},
		{city: "Gujrat",users: 59876}
	];

	function durationAsString(start, end) {
		const duration = moment.duration(moment(end).diff(moment(start)));
	  
		//Get Days
		const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
		const daysFormatted = days ? `${days}Days ` : ''; // if no full days then do not display it at all
	  
		//Get Hours
		const hours = duration.hours();
		const hoursFormatted = `${hours}Hours `;
	  
		//Get Minutes
		const minutes = duration.minutes();
		const minutesFormatted = `${minutes}Minutes`;
	  
		return [daysFormatted, hoursFormatted, minutesFormatted].join('');
	}

	let obj= state.cityData;
	const user_cities =[];
	Object.keys(obj).forEach((key, i)=> user_cities.push({city: key, users: Object.values(obj)[i]}))
	//console.log(user_cities)

	return (<>
		<div className="row">

			{
				state.loader ? 
				<BlockUI />
				:<></>
			}

			<div className="col-md-12">
				<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b page-desc">
					<div className="alert-text">
						<span className="svg-icon menu-icon">
	                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}/>
	                  	</span> 
						<span>
							<FormattedMessage id="PAGE.VIEWSTATISTICS.DESC" />
						</span>
				        <span className="svg-icon menu-icon goBack hide" onClick={goBack}>
							<i className="fa fa-long-arrow-alt-left"></i>
				        </span>
				        <span className="svg-icon menu-icon reload " onClick={reload}>
							<i className={`fa fa-sync-alt ` +refresh}></i>
				        </span>
					</div>
				</div>
			</div>

			<div className="col-md-12">
				<div className="row stats-data">
					<div className="card w20 mb-5">
						<div className="card-body aud-size">
							<div className="symbol symbol-40 symbol-light-primary">
								<span className="symbol-label">
									<span className="stat-icon flaticon2-user"></span>
								</span>
							</div>
							<span className="count">{state.total_scheduled}</span>
							<span className="sdata-desc"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.AUDIENCE" /></span>
						</div>
					</div>
					<div className="card w20 mb-5">
						<div className="card-body reach">
							<div className="symbol symbol-40 symbol-light-success">
								<span className="symbol-label">
									<span className="stat-icon flaticon2-send"></span>
								</span>
							</div>
							<span className="count"><span className="count">{state.sent}</span></span>
							<span className="sdata-desc"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.REACH" /></span>
							{/* <div className="stats-ratio stats-up">
								<span className="caret"><i className="fas fa-caret-up"></i></span> 87%
							</div> */}
						</div>
					</div>
					<div className="card w20 mb-5">
						<div className="card-body views">
							<div className="symbol symbol-40 symbol-light-info">
								<span className="symbol-label">
									<span className="stat-icon flaticon-eye"></span>
								</span>
							</div>
							<span className="count"><span className="count">{state.openK}</span></span>
							<span className="sdata-desc"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.VIEWS" /></span>
							<span className="sdata-desc2"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.VIEWEDBY" />: {state.openU}</span>
							{/* <div className="stats-ratio stats-up">
								<span className="caret"><i className="fas fa-caret-up"></i></span> 65%
							</div> */}
						</div>
					</div>
					<div className="card w20 mb-5">
						<div className="card-body clicks">
							<div className="symbol symbol-40 symbol-light-warning">
								<span className="symbol-label">
									<span className="stat-icon flaticon2-paperplane"></span>
								</span>
							</div>
							<span className="count"><span className="count">{state.clicksK}</span></span>
							<span className="sdata-desc"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.CLICKS" /></span>
							<span className="sdata-desc2"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.CLICKEDBY" />: {state.clicksU}</span>
							{/* <div className="stats-ratio stats-up">
								<span className="caret"><i className="fas fa-caret-up"></i></span> 43%
							</div> */}
						</div>
					</div>
					<div className="card w20 mb-5">
						<div className="card-body unsub">
							<div className="symbol symbol-40 symbol-light-danger">
								<span className="symbol-label">
									<span className="stat-icon flaticon-close"></span>
								</span>
							</div>
							<span className="count"><span className="count">{state.unsubK}</span></span>
							<span className="sdata-desc"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.UNSUBSCRIBED" /></span>
							<span className="sdata-desc2"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.LABEL.UNSUBSCRIBEDBY" />: {state.unsubU}</span>
							{/* <div className="stats-ratio stats-down">
								<span className="caret"><i className="fas fa-caret-up"></i></span> 1.51%
							</div> */}
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-6">
				<div className="card mb-10">
					<div className="card-body">
						<div className="stDetailBlk" row-id={state.id}>
							<span className={statusClass}>{statusLabel}</span>
							<div className="row">
								<div className="col-md-12 mb-5">
									<div className="stat-campName font-weight-bolder text-dark">{state.name}</div>
									<span className="text-muted font-weight-bold d-block mb-4"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.DETAIL.LABEL.BROADCAST" />: {state.broadcast}</span>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 mb-5">
									<div className="font-weight-bolder text-primary d-block stats-audi-label">
										<FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.DETAIL.LABEL.AUDIENCE" />
									</div>
									<span className="text-muted font-weight-bold d-block mb-4">{state.branches}</span>
								</div>
							</div>
							<div className="row mb-4">
								<div className="col-md-4 stats-time-tracking mb-5">
									<i className="icon-3x text-info flaticon-clock-2 mr-4"></i>
									<span className="timeLabel">
										<label className="text-info"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.DETAIL.LABEL.START_TIME" /></label>
										<span className="text-muted font-weight-bold d-block">{moment(state.start_time).format('LLL')}</span>
									</span>
								</div>
								<div className="col-md-4 stats-time-tracking mb-5">
									<i className="icon-3x text-success flaticon2-crisp-icons-1 mr-4"></i>
									<span className="timeLabel">
										<label className="text-success"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.DETAIL.LABEL.END_TIME" /></label>
										<span className="text-muted font-weight-bold d-block">{moment(state.end_time).format('LLL')}</span>
									</span>
								</div>
								<div className="col-md-4 stats-time-tracking mb-5">
									<i className="icon-3x text-primary flaticon2-hourglass-1 mr-4"></i>
									<span className="timeLabel">
										<label className="text-primary"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.DETAIL.LABEL.DURATION" /></label>
										<span className="text-muted font-weight-bold d-block">{durationAsString(state.start_time, state.end_time)}</span>
										{/* <span className="text-muted font-weight-bold d-block">5 Hours 11 Minutes</span> */}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<CTRData values={ctrVlaues} />
				<div className={`card card-custom mb-5`}>
			        {/* Header */}
			        <div className="card-header align-items-center border-0 mt-4">
			          <h3 className="card-title align-items-start flex-column">
			            <span className="font-weight-bolder text-dark"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.ENGAGEMENTS.LABEL" /></span>
			            <span className="text-muted mt-3 font-weight-bold font-size-sm">
			              {state.engage} <FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.ENGAGEMENTS.DESC" />
			            </span>
			          </h3>
			        </div>
			        {/* Body */}
			        <div className="card-body pt-4 engagements-body">
			          	<div className="timeline timeline-6 mt-3">
								
						  		{/* {state.openData ? 
								state.openData.slice(0,8).map((option, index) => (
								<div key={index} className="col-md-12">
									{moment(option['Time']).format('hh:mm')} 
									<i className={`fa fa-genderless icon-xl ${colors[index]}`}></i> 
									{option.Message}
								</div>
								))
								:<></>} */}
							 
							{	state.openData && state.openData.length > 0  ? 
								state.openData.slice(0,11).map((option, index) => (
									<div key={index} className="timeline-item align-items-start">
										<div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
											{moment(option['Time']).format('hh:mm')}
										</div>

										<div className="timeline-badge">
											<i className={`fa fa-genderless icon-xl ${colors[index]}`}></i>
										</div>

										<div className="font-weight-mormal font-size-lg timeline-content pl-3">
											<span className="font-weight-mormal font-size-lg recent-engages-html text-muted">{ReactHtmlParser(option.Message)}</span>
										</div>
									</div>
								))
								:
									<div className="timeline-item align-items-start">
										<div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
											00:00
										</div>

										<div className="timeline-badge">
											<i className={`fa fa-genderless icon-xl`}></i>
										</div>

										<div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
											<span className="font-weight-bolder font-size-lg text-dark-75"><FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.ENGAGEMENTS.NODATA.PART1" /></span> <FormattedMessage id="PAGE.VIEWSTATISTICS.STATES.ENGAGEMENTS.NODATA.PART2" /> 
										</div>
									</div>
							}

			          	</div>
			        </div>
			    </div>
			</div>

			<div className="col-md-6">
				<AudienceReached all_sent={allSent} all_aud_time={allAudTime} all_audience={allAudience} aud_max={audMax} />
				<EngagesChart views={viewsVlaue} clicks={clicksVlaue} unsubs={unsubVlaue} engages={engageVlaue} />
				<CitiesChart sub_cities={user_cities} />
			</div>			

		</div>
	</>);

}


export default connect(null, credux.actions)(ViewStatistics);