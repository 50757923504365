//using fixed url for now. you should make a separte file for urls and env for keys
import {API_URL} from "../../../Constants";

var allBranches2Api= API_URL + 'api/branches/userBranches'
var allStaffApi= API_URL + 'api/staff'
var addStaffApi= API_URL + 'api/staff/addStaff'
var deleteStaffApi= API_URL + 'api/staff/deleteStaff'
var checkStaffApi= API_URL + 'api/staff/checkUsername'
var updateStaffPasswordApi= API_URL + 'api/staff/updatePassword'

export async function getSubBranches2(user_id) {
  try {
    const response = await fetch(allBranches2Api,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({user_id:user_id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.branches})
   
  } catch (error) {
    console.log(error)
  }
}

export async function getAllStaff(id) {
  //console.log(id)
  try {
    const response = await fetch(allStaffApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({merchant_id:id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data.staff_members})
   
  } catch (error) {
    console.log(error)
  }
}

export async function addStaff(payload) {
  //console.log(payload)
  try {
    const response = await fetch(addStaffApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({branch_id: payload.bid, merchant_id:payload.mid, username:payload.name, password : payload.password })
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function deleteStaff(id) {
  //console.log(id)
  try {
    const response = await fetch(deleteStaffApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id: id})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status,body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function checkStaff(username) {
  //console.log(username)
  try {
    const response = await fetch(checkStaffApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({username:username})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}

export async function updateStaffPass(payload) {
  //console.log(payload.id, payload.pass)
  try {
    const response = await fetch(updateStaffPasswordApi,{
      method:"POST",
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({id:payload.id, password:payload.pass})
    });  
    const data = await response.json()
    //console.log(data)
    return ({status: response.status, body:data})
   
  } catch (error) {
    console.log(error)
  }
}