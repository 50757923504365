import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {connect,useSelector,useDispatch} from 'react-redux'
import * as bredux from './_redux/businessRedux'

import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import BlockUI from '../Component/BlockUI';

import { useFormik } from "formik";
import * as Yup from "yup";

 

 function AddBusiness(props) {

	const [initialValues, setInitialValues] = useState({ 
		name: "",
		operating: "",
		username:"",
		rgnum: "",
		regeistered_on: "",
		phone_number:"",
		address: "",
		area: "",
		city: "",
		country: "Pakistan",
		file: "",
		rgtype: null
	});
	
	const globalState  = useSelector(state=>state.business)
	const [allcountries, setdata] = useState([]);

	useEffect( ()=>{
		setdata(globalState.all_countries)
		//console.log(globalState.bs_error_msg)
		//console.log(globalState.businessError)
		//setState({...state, err_msg: globalState.businessError})
	});
	useEffect( ()=>{
		props.getCountries();
		props.businessError(null);
		props.bErrorMessage('');

		if(globalState.businessError == true) {
			setState({...state,loader:false});
			console.log(globalState.businessError)
			console.log(globalState.bs_error_msg)
			swal({
				title: "Something is Wrong",
				text: globalState.bs_error_msg,
				icon: "error",
				button: null,
				timer: 3000
			});
		} else if(globalState.businessError == false) {
			console.log(globalState.businessError)
			console.log(globalState.bs_error_msg)
			swal({
				title: "Business Added",
				text: "Business saved successfully!",
				icon: "success",
				button: null,
				timer: 1500
			});
			history.push({
				pathname: '/business/save'
				// state: {
				// 	user_id:user.id,
				// 	name: state.name, 
				// 	operating: state.operating, 
				// 	regtype: state.regtype,
				// 	rgnum: state.rgnum,
				// 	regeistered_on: state.regeistered_on,
				// 	phone_number:state.phone_number,
				// 	address: state.address,
				// 	area: state.area,
				// 	city: state.city,
				// 	country: state.country
				// }
			});
			console.log(
				user.id+ 
				' '+state.name+ 
				" ::"+state.operating+ 
				" ::"+state.regtype+ 
				" ::"+state.phone_number+ 
				" ::"+state.rgnum+ 
				" ::"+state.regeistered_on+
				" ::"+state.address+
				" "+state.area+
				" "+state.city+
				" "+state.country
			);
		}
	} , [globalState.businessError, globalState.bs_error_msg]);
	

  	const [loading, setLoading] = useState(false);

  	const BusinessSchema = Yup.object().shape({
    	name: Yup.string()
	      	.required("Enter a business name"),
	    operating: Yup.string()
	      	.required("Enter Operating as"),
		username: Yup.string()
			.required("Enter Unique Username"),
	    regeistered_on: Yup.string()
	      	.required("Registration Date require"),
		phone_number: Yup.string()
	      	.required("Phone Number require"),
	    address: Yup.string()
	      	.required("Head office address require"),
	    city: Yup.string()
	      	.required("City require"),
	    country: Yup.string()
			.required("Country require"),
	});

	const enableLoading = () => {
    	setLoading(true);
	};

	  const disableLoading = () => {
	    setLoading(false);
	};

	const getInputClasses = (fieldname) => {
		
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};

	const history = useHistory();
	function viewBusiness() {
	    history.push("/business/all")
	}

	const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

	const classes = useStyles();
	const [values, setValues] = React.useState({
		name: '',
		image:'',
		username:''
	});

	const handleChange = name => event => {
	    setState({ ...state, [name]: event.target.value, });
		setValues({ ...values, [name]: event.target.value });
	};

  	const [state, setState] = React.useState({
  		value: "",
		checkedA: true,
		regtype:"",
		selectedFile:null,
		username:'',
		imgvalid:'',
		imgMsg: 'hide',
		loader: false
	});

	const {user} = useSelector(state => state.auth);

	const fileSelectedHandler = event => {
		setState({...state, selectedFile: event.target.files[0]})
		console.log(event.target.files[0])
	}

	const registertype = [
    {
        value: 'Corporate',
        label: 'Corporate',
      },
      {
        value: 'AOP',
        label: 'AOP',
      },
      {
        value: 'Sole Proprietorship',
        label: 'Sole Proprietorship',
      },
      {
        value: 'Individual',
        label: 'Individual',
      },
      {
        value: 'Not Registered',
        label: 'Not Registered',
      }
  	];


  	const divstatus = (e) => {
    	setState({...state, regtype: e.target.value, rgtype:e.target.value});
    	console.log(e.target.value, state.rgtype);
  	}
	function goBack() {
		history.push("/business/all")
	}

	const formik = useFormik({
    	initialValues,
    	validationSchema: BusinessSchema,
    	onSubmit: (values, { setStatus, setSubmitting }) => {
			props.businessError(null);
			props.bErrorMessage('');

			if(state.regtype == "") {
				swal({
					title: "Something Wrong",
					text: "Please Select Registration Type",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			}
			else if(state.selectedFile === null) {
				setState({...state, imgvalid: "is-invalid", imgMsg: "show"});
				console.log("Image is not selected")
				return false;
			} else {
				setState({...state, imgvalid: "is-valid", imgMsg: "hide"});
				console.log("Image selected!");
			}
			setState({...state,loader:true});

			const str = values.username;
			const uname = str.replace(/ /g, '')

			console.log("Usernamer: "+uname)

			props.addBusiness({ 
				user_id:user.id,
				name :values.name,
				operating_as :values.operating,
				username :uname,
				type :state.regtype,
				registration_number :values.rgnum,
				register_on : values.regeistered_on,
				phone_number: values.phone_number,
				office_address :values.address,
				area :values.area,
				city :values.city,
				country_id :values.country,
				image: {file: state.selectedFile, name: state.selectedFile.name}
			});

			// console.log(
			// 	user.id+ 
			// 	' '+values.name+ 
			// 	" ::"+values.operating+ 
			// 	" ::"+uname+ 
			// 	" ::"+state.regtype+ 
			// 	" ::"+values.phone_number+ 
			// 	" ::"+values.rgnum+ 
			// 	" ::"+values.regeistered_on+
			// 	" ::"+values.address+
			// 	" "+values.area+
			// 	" "+values.city+
			// 	" "+values.country
			// );
	    },
	});

	const keyPress =(e)=> {
		if(e.keyCode == 13){
			console.log('value', e.target.value);
		 }
	}

	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  	</span> 
					<span>
						<FormattedMessage id="PAGE.ADDBUSINESS.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

			{
				state.loader == true?
				<BlockUI />
				: <div> </div>
			}

			<div className="card card-custom gutter-b col-md-12 innerBlks">
				<div className="card-header">
					<div className="card-title">
						<h3 className="card-label"><FormattedMessage id="PAGE.ADDBUSINESS.WISGET_TITLE" /></h3>
					</div>
				</div>
		        <div className="card-body">

		        	<form 
		        		autoComplete="off"
				        onSubmit={formik.handleSubmit}
				        className="ffield form fv-plugins-bootstrap fv-plugins-framework"
		        	>
		        		{formik.status ? (
				          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
				            <div className="alert-text font-weight-bold">{formik.status}</div>
				          </div>
				        ) : (
				          <div>
				          </div>
				        )}
		        		<div className="row">
			                <div className="col-md-6">

			                	<div className="fieldBlk">
				                    <TextField
				                    	label="Official Business Name"
				                        name="name"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "name"
										)}`}
										onChange={handleChange("name")}
				                        value={values.name}
	        							{...formik.getFieldProps("name")}
				                    />
				                    {formik.touched.name && formik.errors.name ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.name}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.BUSINESS.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
			                    	<TextField
				                        id="operating"
				                        name="operating"
				                        label="Operating as"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "operating"
							            )}`}
										onChange={handleChange("operating")}
				                        value={values.operating}
	        							{...formik.getFieldProps("operating")}
				                    />
				                    {formik.touched.operating && formik.errors.operating ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.operating}</div>
							            </div>
							          ) : null}
							        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.OPERATING.HELP" />
				                    </p>
			                    </div>

								<div className="fieldBlk">
			                    	<TextField
				                        id="username"
				                        name="username"
				                        label="Username"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "username"
										)}`}
										inputProps={{style: {textTransform: 'lowercase'}}}
										onChange={handleChange("username")}
				                        value={values.username}
										{...formik.getFieldProps("username")}
										onKeyDown={keyPress}
										onKeyPress={(ev) => {
											if (ev.which === 32) {
											  ev.preventDefault();
											}
										}}
				                    />
				                    {formik.touched.username && formik.errors.username ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.username}</div>
							            </div>
							          ) : null}
							        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.USERNAME.HELP" />
				                    </p>
			                    </div>
								
			                    <div className="fieldBlk">
			                    	<TextField
				                        select
				                        id="regtype"
				                        name="regtype"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "regtype"
							            )}`}
				                        label="Registration Type"
				                        margin="normal"
	        							{...formik.getFieldProps("regtype")}
				                        value={values.regtype}
				                        onChange={divstatus}
				                    >
				                        {registertype.map(option => (
				                          <MenuItem key={option.value} value={option.value}>
				                            {option.label}
				                          </MenuItem>
				                        ))}
				                    </TextField>
							        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.REGISTRATION.HELP" />
				                    </p>
			                    </div>

			                    <div className={`fieldBlk regnumber `+ state.regtype}  style={{display: 'none'}}>
			                    	<TextField
				                        id="rgnum"
				                        name="rgnum"
				                        type="text"
				                        label="Registration Number"
				                        className={classes.textField}
				                        value={values.rgnum}
				                        margin="normal"
				                        variant="outlined"
			                        	value={values.rgnum}
										onChange={handleChange("rgnum")}
        								{...formik.getFieldProps("rgnum")}
				                    />
							        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.REGISTRATION_NUMBER.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
			                    	<TextField
	  							        label="Registered On"
	  							        name="regeistered_on"
	  							        type="date"
	  							        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "regeistered_on"
							            )}`}
	  							        margin="normal"
	  							        variant="outlined"
	  							        InputLabelProps={{
	  							          shrink: true,
	  							        }}
										onChange={handleChange("regeistered_on")}
			                        	value={values.regeistered_on}
	    								{...formik.getFieldProps("regeistered_on")}
	  							    />
	  							    {formik.touched.regeistered_on && formik.errors.regeistered_on ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.regeistered_on}</div>
							            </div>
							          ) : null}
							        <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.REGISTRATION_ON.HELP" />
				                    </p>
			                    </div>

								<div className="fieldBlk">
				                    <TextField
				                    	label="Phone No"
				                        name="phone_number"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "phone_number"
							            )}`}
				                        value={values.phone_number}
										onChange={handleChange("phone_number")}
	        							{...formik.getFieldProps("phone_number")}
				                    />
				                    {formik.touched.phone_number && formik.errors.phone_number ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.phone_number}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.PHONE.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
			                    	<TextField
	  							        label="Head Office Address"
	  							        name="address"
	  							        variant="outlined"
	  							        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "address"
							            )}`}
										onChange={handleChange("address")}
							            value={values.address}
	        							{...formik.getFieldProps("address")}
	  							    />
	  							    {formik.touched.address && formik.errors.address ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.address}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.ADDRESS.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
			                    	<TextField
	  							        label="Area"
	  							        name="area"
	  							        variant="outlined"
	  							        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "area"
							            )}`}
							            value={values.area}
										onChange={handleChange("area")}
	        							{...formik.getFieldProps("area")}
	  							    />
	  							    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.AREA.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
			                    	<TextField
	  							        id="city"
	  							        label="City"
	  							        name="city"
	        							className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "city"
							            )}`}
	  							        margin="normal"
	  							        variant="outlined"
							            value={values.city}
										onChange={handleChange("city")}
	        							{...formik.getFieldProps("city")}
	  							    />
	  							    {formik.touched.city && formik.errors.city ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.city}</div>
							            </div>
							          ) : null}
	  							    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.CITY.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
			                    	<TextField
				                        select
				                        id="country"
				                        name="country"
	        							className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "country"
							            )}`}
				                        variant="outlined"
				                        label="Select Country"
				                        margin="normal"
							            value={values.country}
										onChange={handleChange("country")}
	        							{...formik.getFieldProps("country")}
				                    >
				                        {allcountries.map(option => (
				                          <MenuItem key={option.country_code} value={option.country_name}>
				                            {option.country_name}
				                          </MenuItem>
				                        ))}
				                    </TextField>
	  							    {formik.touched.country && formik.errors.country ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.country}</div>
							            </div>
							          ) : null}
	  							    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.COUNTRY.HELP" />
				                    </p>
			                    </div>

			                    <div className={`fieldBlk imgBlock `+ state.imgvalid }>
			                    	<TextField
				                        id="image"
				                        name="image"
				                        type="file"
				                        placeholder="Upload Company Logo"
				                        className={classes.textField}
				                        margin="normal"
				                        variant="outlined"
							            value={values.file}
										onChange={fileSelectedHandler} 
				                    />
									<span className={`fv-help-block `+ state.imgMsg}>Business Logo Required</span>
	  							    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBUSINESS.LOGO.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk  class-submit">
			                    	<Button 
							            type="submit"
							            //disabled={formik.isSubmitting}
		  					    		variant="contained" 
		  					    		color="primary" 
		  					    		className={classes.button}
		  					    	>
			  					        <FormattedMessage id="SAVE" />
			  					        {loading && <span className="ml-3 spinner spinner-white"></span>}
			  					    </Button>
			  					    <Button variant="contained" className={classes.button} onClick={viewBusiness}>
			  					        <FormattedMessage id="CANCEL" />
			  					    </Button>
			                    </div>

			                </div>
			                <div className="col-md-6 text-center">
			                	<img src="/media/images/Addbusiness.jpg" />
			                </div>    
		                </div>						    
				          		
				    </form>
		        </div>
		    </div>   

		</div>
	</>);

}

export default connect(null, bredux.actions)(AddBusiness);