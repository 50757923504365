import React, { useState, useEffect } from "react";
import { FormattedMessage} from "react-intl";
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import { lighten, makeStyles, useTheme  } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import BlockUI from '../Component/BlockUI';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@material-ui/core/MenuItem';
import swal from 'sweetalert';
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';

import {connect,useSelector} from 'react-redux';
import * as mredux from './_redux/menuRedux';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const initialValues = {   
};


function createData(id, name, desc, branches, cats, items, action) {
    return { id, name, desc, cats, branches, items, action };
  }
  
  const rows = [
    createData(1,'Dining'),
    createData(2,'Take Away'),
  ];
  
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Catalogue Name' },
    { id: 'branches', numeric: false, disablePadding: true, label: 'Assign Branches' },
    { id: 'cats', numeric: false, disablePadding: true, label: 'Categories' },
    { id: 'items', numeric: false, disablePadding: true, label: 'Items' },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  const useToolbarStyles = makeStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },
  }));
  
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      display: 'flex',
      flexWrap: 'wrap',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: "100%",
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const allbranches = [{
    id: 1,
    name: "Oliver Hansen"
  }, {
    id: 2,
    name: "Van Henry"
  }, {
    id: 3,
    name: "April Tucker"
  }
  ];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


function MenuCatalogs(props) {

	document.body.className = 'quick-panel-right demo-panel-right offcanvas-right header-static subheader-enabled aside-enabled aside-fixed aside-minimize-hoverable brand-dark menucats';

  const history = useHistory();

  function cancelAdd() {
    history.push("/menu/all")
  }

    const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

    const classes = useStyles();
    const theme = useTheme();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
        const newSelecteds = rows.map(n => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
    }

    function handleChangeDense(event) {
        setDense(event.target.checked);
    }

    const isSelected = name => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = React.useState({
        name: "",
        description: "",
        catname:"",
        branch:"",
        business:""
    });

    const [state, setState] = React.useState({
        name: "",
        description:"",
        catname:"",
        business:"",
        branch:"",
        loader:false,
        valid_name: "",
        valid_name2: "",
        nameMsg:"hide",
        nameMsg2:"hide",
        updateID:"",
        catSave:1,
        modalShow:false,
        modalBG:"hide",
        modalBG2: "hide",
        modalBG3: "hide",
        remId: "",
        remName: "",
        assignId: "",
        assignName: "",
        chBsns: "hide",
        br_data: "",
        valid_business: "",
        BusinessMsg: "hide",
        valid_branches: "",
        branchesMsg: "hide"

    });

    const handleChange = name => event => {
        //console.log(event.target.value)
	    setState({ ...state, [name]: event.target.value, valid_name:"", nameMsg:"hide" });
        setValues({ ...values, [name]: event.target.value });
	  };

  function handleChangeMultiple(event) {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  }

  const getInputClasses = (fieldname) => {
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};

    const CategorySchema = Yup.object().shape({
    	// name: Yup.string()
	    //   	.required("Enter a broadcast name"),
	});

  const globalState  = useSelector(state=>state.menus)
  const {user} = useSelector(state => state.auth);
  const [catlg_business, setBusiness] = useState([]);
  const [catlg_branches, setbranches] = useState([]);
	const user_id = user.id; 
  const [allcats, setdata] = useState([]);

  useEffect( ()=>{
    setdata(globalState.all_catelogs);
    setBusiness(globalState.cat_businesses)
    setbranches(globalState.cat_branches)
    //console.log(globalState.cat_branches);
  });

  useEffect( ()=>{
    props.getAllCatelogs(user_id);
    props.businessCatelog(user_id)
  } , []); 

  const formik = useFormik({
    	initialValues,
    	validationSchema: CategorySchema,
    	onSubmit: (values, { setSubmitting, setStatus }) => {
            console.log( user.id, state.name, state.description)
            if(state.name === "") {
                setState({...state, valid_name:"is-invalid", nameMsg: "show"});
                return false;
            } 
            else
            {
                setState({...state, valid_name:"valid", nameMsg: "hide"});
                if(state.catSave == 1) {
                    props.addCatalog(Number(user_id), state.name, state.description)
                    setTimeout(() => {
                        swal({
                            title: "Catalogue Added",
                            text: state.name+" saved successfully!",
                            icon: "success",
                            button: null,
                            timer: 1500
                      });
                      setTimeout(() => {
                        props.getAllCatelogs(user_id)
                      }, 1000);
                      setState({...state, name:"", description:"", loading:false, valid_name:"valid", nameMsg: "hide", catSave:1, modalBG: "hide"});
                      setValues({...values, name: ""});
                    }, 1000);
                } else {
                    setTimeout(() => {
                        props.updateCatalog(Number(user_id), Number(state.updateID), state.name, state.description)
                        swal({
                            title: "Catalogue Update",
                            text: state.name+" updated successfully!",
                            icon: "success",
                            button: null,
                            timer: 1500
                        });
                      setTimeout(() => {
                        props.getAllCatelogs(user_id)
                      }, 1000);
                      setState({...state, name:"", description:"", loading:false, valid_name:"valid", nameMsg: "hide", catSave:1, modalBG: "hide"});
                      setValues({...values, name: ""});
                    }, 1000);
                }
                
            }
			setState({...state, loading:true, valid_name:"valid", nameMsg: "hide"});
	    },
	});

    const editCat =(e)=> {
      setValues({...values, name:"", description: ""});
        var id = e.target.dataset.id;
        var cat_name = e.target.dataset.name;
        var cat_desc = e.target.dataset.desc;
        setState({...state, loader:true})
        setTimeout(function() {
            setState({...state, loader:false, name: cat_name, description: cat_desc, updateID: id, catSave:2, modalBG: "show"})
            setValues({...values, name:cat_name, description: cat_desc});
        }, 1000);
        console.log(id, cat_name, cat_desc)
    } 

    const removeCat = (e) => {
      var id = e.target.dataset.id;
      var name = e.target.dataset.name;
      setState({...state, modalBG2: "show", remId: id, remName: name});
    }

    const confirmDelete =(e)=> {
      if(state.catname == "") {
        setState({...state, valid_name2: "is-invalid", nameMsg2: "show"});
        return false;
      } else if(state.catname !== state.remName) {
        setState({...state, valid_name2: "is-invalid", nameMsg2: "show"});
        return false;
      } else {
        setState({...state, valid_name2: "valid", nameMsg2: "hide", modalBG2: "hide", catname :""});
        setValues({...values, catname: ""});
        props.deleteCatalog(Number(state.remId));
        swal("Catalog has been deleted!", {
          icon: "success",
        });
        setTimeout(() => {
          props.getAllCatelogs(user_id)
        }, 1000);
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    }

  const addNewMenu =()=> {
    setValues({...values, name: "", description: ""});
    setState({ ...state, modalBG: "show", name:"",  description: "", catSave:1 })
  }

  const viewCat =(e)=> {
    var id = e.target.dataset.id;
    var name = e.target.dataset.name;
    var desc = e.target.dataset.desc;
    var bs_id = e.target.dataset.bs_id;
    //console.log(id, name, desc)
    history.push({
      pathname: '/menu/all',
      state: {
        id: id,
        name: name,
        desc:desc,
        bs_id: bs_id
      }
      });
  }

  const selectBranches = (e) => {
    setPersonName([]);
    setState({...state, loader:true, business: "", chBsns: "hide"})
    setValues({...values, business: ""});
    var id = e.target.dataset.id;
    var name = e.target.dataset.name;
    setTimeout(function() {
      setState({...state, assignId: id, assignName: name, modalBG3: "show", loader:false});
    }, 1000);
  }

  const assignBranches =()=> {
    setState({...state, loader:true})
    if(state.business == "") {
      setState({...state, valid_business: "is-invalid", BusinessMsg: "show"});
      return false
    } else if (state.branches == "") {
      setState({...state, valid_branches: "is-invalid", branchesMsg: "show"});
      return false
    } else {
      var id = state.assignId;
      var branches = state.br_data;
      var business_id = state.business;
      props.assignBranches(Number(id), Number(business_id), String(branches))
      setTimeout(function() {
        props.getAllCatelogs(user_id)
        setState({...state, loader:false, modalBG3: "hide"});
      }, 1000);
    }
    //console.log(id, branches)
  }

  const businessChange = (e) => {
    setPersonName([]);
    var bs_id = e.target.value;
    props.GetBranchesCat(bs_id)
    setState({...state, chBsns: "show", business: bs_id});
    setValues({...values, business: bs_id});
    console.log(bs_id)
  }
  
  const [personName, setPersonName] = React.useState([]);
  function handleChangeM(event) {
    setPersonName(event.target.value);
    setState({...state, br_data: event.target.value, valid_business: "is-valid", BusinessMsg: "hide"});
    console.log("Branches:  "+event.target.value, state.br_data)
  }

	return (<>
 
      <div className="row catalogPage" id="menuPage">
          {
              state.loader == true?
              <BlockUI />
              : <></>
          }

        <div className={`fade modal-backdrop `+state.modalBG}></div>
        <div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG}>
          <div role="document" className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4" id="contained-modal-title-vcenter"><FormattedMessage id="PAGE.ADDMENUCATALOG.WIDGET.TITLE" /></div>
              </div>
              <div className="modal-body">
                <form 
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                  {formik.status ? (
                  <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                      <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                  ) : (
                  <div>
                  </div>
                  )}

                  <div className={`fieldBlk `+ state.valid_name}>
                      <TextField
                          name="name"
                          type="text"
                          label="Catalogue Name"
                          variant="outlined"
                          className={`form-control h-auto py-0 px-0  ${getInputClasses(
                              "name"
                          )}`}
                          value={values.name}
                          onChange={handleChange("name")}
                      />
                      <span className={`fv-help-block `+ state.nameMsg}>Catalogue Name Required</span>
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                          <FormattedMessage id="PAGE.ADDMENUCATALOG.NAME.HELP" />
                      </p>
                  </div>
                  <div className={`fieldBlk `}>
                      <TextField
                          name="description"
                          type="text"
                          label="Catalogue Description"
                          variant="outlined"
                          className={`form-control h-auto py-0 px-0  ${getInputClasses(
                              "description"
                          )}`}
                          value={values.description}
                          onChange={handleChange("description")}
                      />
                      <span className={`fv-help-block hide `}>Catalogue Description Required</span>
                      <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                          <FormattedMessage id="PAGE.ADDMENUCATALOG.NAME.HELP" />
                      </p>
                  </div>

                  <div className="fieldBlk mt-0 mb-0 class-submit">
                      <Button 
                          type="submit"
                          //disabled={formik.isSubmitting}
                          variant="contained" 
                          color="primary" 
                          className={classes.button}
                      >
                          <FormattedMessage id="SAVE" />
                          {loading && <span className="ml-3 spinner spinner-white"></span>}
                      </Button>
                      <Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG: "hide", name: "" })}>
                          <FormattedMessage id="CANCEL" />
                      </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={`fade modal-backdrop `+state.modalBG2}></div>
        <div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG2}>
          <div role="document" className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modelheading">
                  <h4>Are you sure?</h4>
                  <p>Catalog may belong to categories. All categories and items will be lost!</p>
                </div>
                <div className={`fieldBlk mt-8 `+ state.valid_name2}>
                    <TextField
                        name="catname"
                        type="text"
                        label="Catalogue Name"
                        variant="outlined"
                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
                            "catname"
                        )}`}
                        value={values.catname}
                        onChange={handleChange("catname")}
                    />
                    <span className={`fv-help-block `+ state.nameMsg2}>Catalogue Name missmatch!</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                        Write your menu catalog name to confirm delete.
                    </p>
                </div>
                <div className="fieldBlk mt-0 mb-0 class-submit">
                    <Button 
                        type="button"
                        variant="contained" 
                        color="primary" 
                        className={classes.button}
                        onClick={confirmDelete}
                    >
                        <FormattedMessage id="SAVE" />
                    </Button>
                    <Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG2: "hide" })}>
                        <FormattedMessage id="CANCEL" />
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`fade modal-backdrop `+state.modalBG3}></div>
        <div role="dialog" aria-modal="true" aria-labelledby="contained-modal-title-vcenter" className={`fade modal `+state.modalBG3}>
          <div role="document" className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modelheading">
                  <h4>Assign Catalog to Branches</h4>
                  <p>Select Business and Branches to assign it with Selected Catalog</p>
                </div>
                <div className={`fieldBlk`}>
                    <div className="cat_assign_title_label">Calalogue Name:</div>
                    <h5>{state.assignName}</h5>
                </div>
                <div className={`fieldBlk `+state.valid_business}>
                    <TextField
                      select
                      id="business"
                      name="business"
                      className={`form-control h-auto py-0 px-0  ${getInputClasses(
                        "business"
                      )}`}
                      variant="outlined"
                      label="Select Business"
                      margin="normal"
                      value={values.business}
                      onChange={businessChange}
                    >
                        {catlg_business.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </TextField>
                    <span className={`fv-help-block `+ state.BusinessMsg}>Select Business</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                      <FormattedMessage id="PAGE.LINKS.ADDOFFER.BUSINESS.HELP" />
                    </p>
                </div>
                
                <div className={`fieldBlk `+ state.chBsns + " "+ state.valid_branches}>
                    <FormControl variant="outlined" className={classes.textField} margin="normal">
                      <InputLabel htmlFor="select-multiple-checkbox"><FormattedMessage id="PAGE.LINKS.ADDOFFER.BRANCHES.LABEL2" /></InputLabel>
                      <Select
                        multiple
                        name="branch"
                        value={personName || "No Branches"}
                        onChange={handleChangeM}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                      {catlg_branches !== undefined ? catlg_branches.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          <Checkbox checked={personName.indexOf(option.id) > -1} />
                          <ListItemText primary={option.name || "No Branch"} />
                        </MenuItem>
                      )) : <></>}
                      </Select>
                    </FormControl>
                    <span className={`fv-help-block `+ state.branchesMsg}>Select Branches</span>
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
                          Select branches to assign for catalog
                    </p>
                </div>
                <div className="fieldBlk mt-0 mb-0 class-submit">
                    <Button 
                        type="button"
                        variant="contained" 
                        color="primary" 
                        className={classes.button}
                        onClick={assignBranches}
                    >
                        <FormattedMessage id="SAVE" />
                    </Button>
                    <Button variant="contained" className={classes.button} onClick={() => setState({ ...state, modalBG3: "hide", chBsns:"hide", business: "" })}>
                        <FormattedMessage id="CANCEL" />
                    </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="card card-custom gutter-b col-md-12 firstBlk">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label"><FormattedMessage id="PAGE.ALLMENUCATALOG.WIDGET.TITLE" /></h3>
            </div>
            <div className="toolbar">
		        	<div className="text-right mt-10p">
                <Button variant="contained" color="primary" className={classes.button} onClick={addNewMenu}>
                  <FormattedMessage id="PAGE.ADDMENUCATALOG.WIDGET.TITLE" />
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {
              allcats.length == 0  ? 
                <div className="no-catalog-found" onClick={addNewMenu}>No Catalogue Added - Add New</div>
              : 
              <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                  <Table
                      className={`menu-cat-table `+ classes.table}
                      aria-labelledby="tableTitle"
                  >
                    <TableBody id="catgs">
                      {stableSort(allcats, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (<>
                          <TableRow
                                hover
                                onClick={event => handleClick(event, row.name)}
                                tabIndex={-1}
                                key={row.id}
                                id={`catg-`+index}
                          >
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                <span className="catlg-title text-left">Catalog</span>
                                <span 
                                  className="text-link text-primary font-16"
                                  data-id={row.id}
                                  data-name={row.name}
                                  data-desc={row.description}
                                  data-bs_id={row.business_id}
                                  onClick={viewCat}
                                >
                                  {row.name}
                                </span>
                                <span className="catlg-desc">July 17, 2021 10:30AM</span>
                            </TableCell>
                            <TableCell>
                                <span className="catlg-title">Assigned Business</span>
                                <span className="cat-branches">
                                  {row.business_name ? row.business_name : ""}
                                  <span className="branches-count no-br-addigned">{row.business_name ? "" : "No Branch Assigned"}</span>
                                  <span className="branches-count">
                                    {
                                      row.branchs_id !== null && row.branchs_id.split(',').length > 0 && row.branchs_id.split(',').length < 2 ?
                                      " ("+row.branchs_id.split(',').length+ " Branch)"
                                      : row.branchs_id !== null && row.branchs_id.split(',').length > 1 ? 
                                      " ("+row.branchs_id.split(',').length+ " Branches)"
                                      :""
                                    }
                                  </span>
                                </span>
                            </TableCell>
                            <TableCell>
                                <span className="catlg-title">Categories</span>
                                <span className="catsval">151</span>
                            </TableCell>
                            <TableCell>
                                <span className="catlg-title">Items</span>
                                <span className="itemsval">231</span>
                            </TableCell>
                            <TableCell align="right">
                                <button 
                                    className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                                    data-id={"1"}
                                    data-name={row.name}
                                    data-desc={row.description}
                                    data-bs_id={row.business_id}
                                    onClick={viewCat}
                                >
                                    <i className="fa fa-eye text-success" 
                                        data-id={row.id}
                                        data-name={row.name}
                                        data-desc={row.description}
                                        data-bs_id={row.business_id}
                                    ></i>
                                </button>
                                <button 
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    data-id={row.id}
                                    data-name={row.name}
                                    data-desc={row.description}
                                    onClick={editCat}
                                >
                                    <i className="fa fa-edit text-primary" 
                                        data-id={row.id}
                                        data-name={row.name}
                                        data-desc={row.description}
                                    ></i>
                                </button>
                                <button 
                                    className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3"
                                    data-id={row.id}
                                    data-name={row.name}
                                    onClick={selectBranches}
                                >
                                    <i className="fab fa-usb text-warning" 
                                        data-id={row.id}
                                        data-name={row.name}
                                    ></i>
                                </button>
                                <button className="btn btn-icon btn-light btn-hover-danger btn-sm" 
                                    data-id={row.id} 
                                    data-name={row.name}
                                    onClick={removeCat}
                                >
                                    <i className="fa fa-trash text-danger" 
                                        data-id={row.id} 
                                        data-name={row.name}
                                    ></i>
                                </button>
                            </TableCell>
                          </TableRow>
                      </>);
                      })}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allcats.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                      'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                      'aria-label': 'Next Page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            }
          </div>
        </div>

      </div>  
      <ToastContainer />
	</>);

}

export default connect(null, mredux.actions)(MenuCatalogs);