import React from "react";

export function HeaderMenuWrapper() {

    return <>
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            
        </div>
    </>
}
