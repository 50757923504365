import React from "react";

const Completed = () => (
  <>
    <i className="fa fa-check"></i>
    {/* <img
      src="/media/images/checks.png"
      className="dashboard_logo_icon"
    /> */}
  </>
);
export default Completed;


